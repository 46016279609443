import { authService } from './authorization';

export enum AuthStatus {
  NotAuthenticated = 'NotAuthenticated',
  Authenticated = 'Authenticated',
  Authenticating = 'Authenticating',
  PreconditionFailed = 'PreconditionFailed',
}

export enum HTTPStatus {
  NoContent = 204,
  Unauthorized = 401,
  Forbidden = 403,
  PreconditionFailed = 412,
  Conflict = 409,
  BadRequest = 400,
  NotFound = 404,
}

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface ValidationErrorsResponse {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: {
    [key:string]: string[];
  };
}

/* eslint-disable no-throw-literal */
export const apiFetch = async <T>(endpoint: string, body?: any, method: HTTPMethod = HTTPMethod.GET): Promise<T> => {
  const isAuthenticated = await authService.isAuthenticated();

  const url = `${process.env.PUBLIC_URL}/api${endpoint}`;
  let requestInit = {};
  let authorization = '';
  if (isAuthenticated) {
    const token = await authService.getAccessToken();
    authorization = `Bearer ${token}`;
    requestInit = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
      body: JSON.stringify(body),
    };
  } else {
    requestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

  }

  const apiResponse = await fetch(url, requestInit);

  if (apiResponse.status === HTTPStatus.Unauthorized) {
    authService.signOut();
    throw { status: AuthStatus.NotAuthenticated };
  } else if (apiResponse.status === HTTPStatus.Forbidden) {
    document.location.href = './';
    throw { status: AuthStatus.Authenticating };
  } else if (apiResponse.status === HTTPStatus.PreconditionFailed) {
    apiResponse.body
      ?.getReader()
      .read()
      .then(async (b) => {
        const tokenChallengeId = new TextDecoder().decode(b.value);

        const tokenChallengeResponse = prompt(`
          To proceed with this, you need to enter the code that has been sent to your email
        `);

        if (tokenChallengeResponse === null || tokenChallengeResponse === '') {
          throw { status: AuthStatus.NotAuthenticated };
        }

        const apiResponseWithToken = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
            tokenChallengeId: tokenChallengeId,
            tokenChallengeResponse: tokenChallengeResponse.valueOf().toString(),
          },
          body: JSON.stringify(body),
        });
        if (apiResponseWithToken.ok) {
          return apiResponseWithToken?.headers?.get('Content-Type')?.includes('application/json')
            ? apiResponseWithToken.json()
            : apiResponseWithToken;
        } else {
          throw new Error(`Something unexpected happened when trying to fetch ${url}`);

          // TODO: Add modal popup requesting one time token which has been sent to users email inbox instead of throwing exception
        }
      });
    throw { status: AuthStatus.NotAuthenticated };
  } else if (apiResponse.status === HTTPStatus.Conflict) {
    return apiResponse.text()
      .then(reasonForConflict => new Error(reasonForConflict))
      .then(error => Promise.reject(error));
  } else if (apiResponse.ok) {

    const contentType = apiResponse?.headers?.get('Content-Type');

    if (contentType?.includes('application/json')) {
      return apiResponse.json();
    } else if (contentType?.includes('text/plain')) {
      return apiResponse.text() as unknown as T;
    } else {
      return apiResponse as unknown as T;
    }

  } else if (apiResponse.status === HTTPStatus.BadRequest || apiResponse.status === HTTPStatus.NotFound) {
    if (apiResponse?.headers?.get('Content-Type')?.includes('application/problem+json')) {
      throw (await apiResponse.json()) as ValidationErrorsResponse;
    } else if (apiResponse.text) {
      const textResult = await apiResponse.text();
      if (textResult.includes('Cannot PUT' || 'Cannot GET' || 'Cannot POST' ))
      {
        throw new Error(`Could not fetch "${url}", please contact support, support@crossborderit.com.`);
      } else {
        throw new Error(textResult);
      }
    }
    throw apiResponse;
  } else if (apiResponse.text) {
    throw new Error(await apiResponse.text());
  } else {
    throw new Error(`Something unexpected happened when trying to fetch ${url}`);
  }
};

export const getFileName = (result: Response) => {
  const contentDisposition = result.headers.get('content-disposition');
  const match = contentDisposition?.match(/filename\s*=\s*(.+);/i);
  const fileName = match && match.length === 2 ? match[1] : null;
  return fileName;
};