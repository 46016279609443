export interface Country {
  alpha2Code: string;
  name: string;
  alpha3Code: string;
  currencyCode?: string;
}

export interface CountryData {
  id?: string;
  countryDataType: CountryDataType;
  text: string;
  value: string;
}

export enum CountryDataType {
  CustomsHsCodeUrl = 'CustomsHsCodeUrl',
}
