import { FunctionComponent } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/es/button';
import './Button.css';
import withConditionalRendering, {
  ConditionalRenderingProps,
} from '../hoc/withConditionalRendering';

const DefaultButton: FunctionComponent<ButtonProps> = ({
  className = '',
  ...props
}) => (
  <AntdButton
    {...props}
    type="default"
    className={`cbit-button cbit-button--default ${className}`}
  />
);

const PrimaryButton: FunctionComponent<ButtonProps> = ({
  className = '',
  ...props
}) => (
  <AntdButton
    {...props}
    type="primary"
    className={`cbit-button cbit-button--primary ${className}`}
  />
);

const BaseButton: FunctionComponent<ButtonProps> = ({
  type = 'default',
  ...props
}) => {
  if (type === 'default') return <DefaultButton {...props} />;
  if (type === 'primary') return <PrimaryButton {...props} />;
  return <AntdButton {...props} type={type} />;
};

export const Button = withConditionalRendering<
ButtonProps & ConditionalRenderingProps
>(BaseButton);
