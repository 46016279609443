import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeBy, replaceBy } from 'utility';
import { equals } from 'ramda';
import { createCrmAction, CrmAction, dismissCrmAction, finishCrmAction, getCrmActions, updateCrmAction } from '..';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import moment from 'moment';
import { deleteCrmAction } from '.';

export const crmActionsStorePath = ['crmActions'];

export const useCrmActions = () => {
  const dispatch = useDispatch();

  const {
    data: crmActions = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<CrmAction[]>([], crmActionsStorePath), equals);

  const loadCrmActions = useCallback(() =>
    getCrmActions()
      .then(allCrmActions =>
        dispatch(setAsyncResource(
          crmActionsStorePath,
          AsyncStatus.Success,
          allCrmActions
        )))
      .catch(() => dispatch(setAsyncResource(
        crmActionsStorePath,
        AsyncStatus.Error,
        []
      )))
  , [dispatch]);

  const addCrmAction = useCallback((crmAction: CrmAction) =>
    createCrmAction(crmAction)
      .then(createdCrmAction =>
        dispatch(setAsyncResource(
          crmActionsStorePath,
          AsyncStatus.Success,
          [...crmActions, createdCrmAction]
        )))
  , [dispatch, crmActions]);

  const editCrmAction = useCallback((crmActionId: string, crmAction: CrmAction) =>
    updateCrmAction(crmActionId, crmAction)
      .then(updatedCrmAction =>
        dispatch(setAsyncResource(
          crmActionsStorePath,
          AsyncStatus.Success,
          replaceBy('id', updatedCrmAction, crmActions)
        )))
  , [dispatch, crmActions]);

  const completeCrmAction = useCallback((crmActionId: string) =>
    finishCrmAction(crmActionId)
      .then(finishedCrmAction =>
        dispatch(setAsyncResource(
          crmActionsStorePath,
          AsyncStatus.Success,
          replaceBy('id', finishedCrmAction, crmActions)
        )))
  , [dispatch, crmActions]);

  const rejectCrmAction = useCallback((crmActionId: string) =>
    dismissCrmAction(crmActionId)
      .then(rejectedCrmAction =>
        dispatch(setAsyncResource(
          crmActionsStorePath,
          AsyncStatus.Success,
          [...replaceBy('id', rejectedCrmAction, crmActions)]
        )))
  , [dispatch, crmActions]);

  const removeCrmAction = useCallback((crmActionId: string) =>
    deleteCrmAction(crmActionId)
      .then(() =>
        dispatch(setAsyncResource(
          crmActionsStorePath,
          AsyncStatus.Success,
          removeBy('id', crmActionId, crmActions)
        )))
  , [dispatch, crmActions]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized) return;
    loadCrmActions();
  }, [status, loadCrmActions]);

  const activeCrmActions = useMemo(() =>
    crmActions.filter(action => moment(action.actionUtc).isSameOrBefore(moment(), 'day') && action.completedUtc === null && action.rejectedUtc === null)
  , [crmActions]);

  const upcomingCrmActions = useMemo(() =>
    crmActions.filter(action => moment(action.actionUtc).isAfter(moment()))
  , [crmActions]);

  const pastCrmActions = useMemo(() =>
    crmActions.filter(action => action.completedUtc !== null || action.rejectedUtc !== null)
  , [crmActions]);

  return {
    status,
    loadCrmActions,
    crmActions,
    activeCrmActions,
    upcomingCrmActions,
    pastCrmActions,
    addCrmAction,
    editCrmAction,
    completeCrmAction,
    rejectCrmAction,
    removeCrmAction,
  };
};
