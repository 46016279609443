import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styles';

export const enum ScreenSize{
  Desktop,
  DesktopSmall,
  Mobile,
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(ScreenSize.Desktop);
  const theme = useTheme();

  const getScreenSize = useCallback(() => {
    if (window.innerWidth > theme.breakpoints.desktop) {
      setScreenSize(ScreenSize.Desktop);
    }
    else if (window.innerWidth >= theme.breakpoints.mobile && window.innerWidth <= theme.breakpoints.desktop ) {
      setScreenSize(ScreenSize.DesktopSmall);
    }
    else {
      setScreenSize(ScreenSize.Mobile);
    }
  }, [theme]);

  useEffect(() => {
    getScreenSize();
    window.addEventListener('resize', getScreenSize);

    return () => {
      window.removeEventListener('resize', getScreenSize);
    };
  }, [getScreenSize]);

  return {screenSize};

};