import { FontIcon, Icon, Stack, Text } from '@fluentui/react';
import { Form, Input, message } from 'antd';
import { Button } from 'components/shared/button';
import { Onboarding, PostCustomerInitiatedOnboarding } from 'integrations/crossborderit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cbitSupportEmail } from 'config';
import { ContactPerson as ContactPersonInput } from 'components/shared/input';
import '../Onboarding.css';
import { Case, Switch } from 'components/shared/switch';

enum FormStatus {
  Idle = 'Idle',
  SubmittingForm = 'SubmittingForm',
}

enum PageState {
  Loading = 'Loading',
  Idle = 'Idle',
  Success = 'Success',
}

export const StartOnboardingPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formStatus, setFormStatus] = useState(FormStatus.Idle);
  const [pageState, setPageState] = useState(PageState.Idle);

  const onFormSubmit = (formValues: Onboarding) => {
    setFormStatus(FormStatus.SubmittingForm);
    PostCustomerInitiatedOnboarding(formValues)
      .then(() => {
        setPageState(PageState.Success);
      })
      .catch((error) => {
        message.error(t(error?.message || 'Could not send invitation'));
      }).finally(() => {
        setFormStatus(FormStatus.Idle);
      });
  };

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        className="onboarding dark-theme"
      >
        <Switch on={pageState}>
          <Case match={[PageState.Idle]}>
            <Stack horizontalAlign="center" verticalAlign="center" verticalFill style={{ width: '100%' }}>
              <div className="onboarding__stepContainer">
                <Stack className="mgb-xl" horizontalAlign="center">
                  <Icon iconName="cbitIcon" className="onboarding-cbit-icon" />
                  <Text block className="onboarding__step-title">{t('Get Invitation')}</Text>
                  <Text block className="onboarding__step-text">{t('Complete the form to get an invitation to CBIT')}</Text>
                </Stack>
                <Stack>
                  <Form
                    layout="vertical"
                    form={form}
                    preserve={false}
                    onFinish={onFormSubmit}
                  >
                    <Form.Item
                      name="companyName"
                      label={t('Company name')}
                      rules={[
                        {
                          required: true,
                          message: t('Please enter the company name'),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Text block className="mgb-m mgt-l bold-500 onboarding-text" variant="xLargePlus">
                      {t('Contact Person')}
                    </Text>
                    <ContactPersonInput />
                  </Form>
                  <Stack className="mgt-l">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="onboarding__next-btn btn-cbit--primary mgl-auto"
                      loading={formStatus === FormStatus.SubmittingForm}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      {t('Submit')}
                      <FontIcon className="mgl-l" aria-label="Forward" iconName="Forward" />
                    </Button>
                  </Stack>
                  <Stack className="mgt-l" horizontalAlign="center">
                    <Text className="onboarding-text">
                      {t('Need help? contact us at')}{' '}<a href={`mailto:${cbitSupportEmail}?subject=Crossborderit support`}>{cbitSupportEmail}</a>
                    </Text>
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </Case>
          <Case match={[PageState.Success]}>
            <Stack
              className={'onboarding-completed slideUp'}
              verticalAlign="center"
              verticalFill
              horizontalAlign="center"
            >
              <Stack horizontalAlign="center" className="onboarding-completed__inner">
                <Icon className="onboarding-success-icon" iconName="Rocket" />
                <Text block className="onboarding__step-title">{t('Invitation was sent!')}</Text>
                <Text block className="mgb-l mgt-m onboarding__step-text">{t('You will shortly receive an email with a link for completing the onboarding. If you don\'t find the email, please check your spam mail folder.')}</Text>
                <Text block className="onboarding-text">
                  {t('Need help? contact us at')}{' '}<a href={`mailto:${cbitSupportEmail}?subject=Crossborderit support`}>{cbitSupportEmail}</a>
                </Text>
              </Stack>
            </Stack>
          </Case>
        </Switch>
      </Stack>
    </div>
  );
};