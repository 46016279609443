export interface EntityNote {
  id: string,
  message: string,
  createdByFullName: string,
  createdUtc: Date,
  entityNoteType: SystemNoteType | UserNoteType,
  collectionInvoiceId?: string;
  collectionInvoiceNumber?: string;
  entityNoteUserTags: EntityNoteUserTag[];
  entityNoteCategory: NoteCategory
  companyId: string;
  entityNoteReference?: string;
}

export enum UserNoteType {
  Note = 'Note',
  Support = 'Support',
  Meeting = 'Meeting',
  Phonecall = 'Phonecall',
  Email = 'Email',
  Other = 'Other',
}

export enum SystemNoteType {
  Invite = 'Invite',
}

export interface EntityNoteUserTag {
  id: string;
  userId: string;
  fullName: string;
  entityNoteId: string;
  email: string;
}

export interface EntityNoteGroup {
  entityId: string;
  groupName: string;
  notes: EntityNote[];
}

export enum NoteCategory {
  Company = 'Company',
  Invoice = 'Invoice',
  Order = 'Order',
  Master= 'Master',
}