import { apiFetch, HTTPMethod } from '../fetch';
import { ProductImportPreviewResult, ProductImportPreviewDecision, ProductImportInfo } from './interfaces';
import { apiFetchFormData } from 'integrations/crossborderit/fetchFormData';
import { IossInvoiceJobStatus } from '../iossInvoiceJobs';

// uploadFile() uploads the file and does some basic verification
export const uploadFile = (companyId: string, file: any): Promise<ProductImportPreviewResult> => {
  const formData = new FormData();
  formData.append('file', file);
  return apiFetchFormData(`/companies/${companyId}/productimport`, formData, HTTPMethod.POST);
};

// importFile() is used after uploadFile() to confirm or abort the import
export const importFile = (
  companyId: string,
  importDecision: ProductImportPreviewDecision
) => apiFetch(
  `/companies/${companyId}/productimport`,
  importDecision,
  HTTPMethod.PUT
);

export const getImports = (companyId: string) =>
  apiFetch<ProductImportInfo[]>(`/companies/${companyId}/productimport`);

export const startDeleteProductImportFileJob = (companyId: string, importId: string) =>
  apiFetch(`/companies/${companyId}/productimport/${importId}`, undefined, HTTPMethod.DELETE);

export const getRemoveProductImportFileJobStatus = (companyId: string) =>
  apiFetch<IossInvoiceJobStatus>(`/companies/${companyId}/productimport/removeProductImportFileJobStatus`);