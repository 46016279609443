import { CrmCompany, CrmCompanyStatus, CrmCompanyType } from '.';
import { apiFetch, HTTPMethod } from '../fetch';

export const getCrmCompanies = (crmCompanyType?: CrmCompanyType, crmCompanyStatus?: CrmCompanyStatus): Promise<CrmCompany[]> =>
  apiFetch(`/CrmCompanies?type=${crmCompanyType || ''}&status=${crmCompanyStatus || ''}`, undefined, HTTPMethod.GET);

export const getCrmCompany = (crmCompanyId: string): Promise<CrmCompany> =>
  apiFetch(`/CrmCompanies/${crmCompanyId}`, undefined, HTTPMethod.GET);

export const createCrmCompany = (crmCompany: CrmCompany): Promise<CrmCompany> =>
  apiFetch('/CrmCompanies', crmCompany, HTTPMethod.POST);

export const updateCrmCompany = (crmCompanyId: string, updatedCrmCompany: CrmCompany): Promise<CrmCompany> =>
  apiFetch(`/CrmCompanies/${crmCompanyId}`, updatedCrmCompany, HTTPMethod.PUT);

export const revokeCrmCompany = (crmCompanyId:string): Promise<CrmCompany> =>
  apiFetch(`/CrmCompanies/${crmCompanyId}/revoke`, undefined, HTTPMethod.PUT);

export const unRevokeCrmCompany = (crmCompanyId:string): Promise<CrmCompany> =>
  apiFetch(`/CrmCompanies/${crmCompanyId}/unrevoke`, undefined, HTTPMethod.PUT);

export const syncCompaniesToCrm = () =>
  apiFetch('/CrmCompanies/syncCompanies', undefined, HTTPMethod.POST);