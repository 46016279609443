import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, setAsyncResource, selectAsyncResource} from 'store';
import { removeBy, replaceBy } from 'utility';
import { equals } from 'ramda';
import { CrmCompany, CrmContact, updateCrmCompany } from '..';
import { getCrmCompanies, createCrmCompany, revokeCrmCompany, unRevokeCrmCompany, CrmCompanyType } from '.';

export const crmCompaniesStorePath = ['crmCompanies'];

export const useCrmCompanies = () => {
  const dispatch = useDispatch();

  const {
    data: crmCompanies = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<CrmCompany[]>([], crmCompaniesStorePath), equals);

  const loadCrmCompanies = useCallback((crmCompanyType?: CrmCompanyType) => {
    //Reset current CrmCompanies and reload.
    dispatch(setAsyncResource(
      crmCompaniesStorePath,
      AsyncStatus.Loading,
      []
    ));

    return getCrmCompanies(crmCompanyType)
      .then(allCrmCompanies =>
        dispatch(setAsyncResource(
          crmCompaniesStorePath,
          AsyncStatus.Success,
          allCrmCompanies
        )))
      .catch(() => dispatch(setAsyncResource(
        crmCompaniesStorePath,
        AsyncStatus.Error,
        []
      )));
  }, [dispatch]);

  const addCrmCompany = useCallback((crmCompany: CrmCompany) =>
    createCrmCompany(crmCompany)
      .then(createdCrmCompany =>
        dispatch(setAsyncResource(
          crmCompaniesStorePath,
          AsyncStatus.Success,
          [...crmCompanies, createdCrmCompany]
        )))
  , [dispatch, crmCompanies]);

  const editCrmCompany = useCallback((crmCompanyId: string, crmCompany: CrmCompany) =>
    updateCrmCompany(crmCompanyId, crmCompany)
      .then(updatedCrmCompany =>
        dispatch(setAsyncResource(
          crmCompaniesStorePath,
          AsyncStatus.Success,
          crmCompanies.find(c => c.id === crmCompany.id)?.crmCompanyType === updatedCrmCompany.crmCompanyType ? replaceBy('id', updatedCrmCompany, crmCompanies) : removeBy('id', updatedCrmCompany.id, crmCompanies)
        )))
  , [dispatch, crmCompanies]);

  const inactivateCrmCompany = useCallback((crmCompanyId: string) =>
    revokeCrmCompany(crmCompanyId)
      .then(revokedCrmCompany =>
        dispatch(setAsyncResource(
          crmCompaniesStorePath,
          AsyncStatus.Success,
          replaceBy('id', revokedCrmCompany, crmCompanies)
        )))
  , [dispatch, crmCompanies]);

  const activateCrmCompany = useCallback((crmCompanyId: string) =>
    unRevokeCrmCompany(crmCompanyId)
      .then(unrevokedCrmCompany =>
        dispatch(setAsyncResource(
          crmCompaniesStorePath,
          AsyncStatus.Success,
          replaceBy('id', unrevokedCrmCompany, crmCompanies)
        )))
  , [dispatch, crmCompanies]);

  const addCrmCompanyContactState = useCallback((crmCompany: CrmCompany, createdCrmContact:CrmContact) => {
    const updatedCrmCompany = { ...crmCompany, crmContacts: [...crmCompany?.crmContacts || [], { ...createdCrmContact }] };
    return dispatch(setAsyncResource(
      crmCompaniesStorePath,
      AsyncStatus.Success,
      replaceBy('id', updatedCrmCompany, crmCompanies)
    ));
  }
  , [dispatch, crmCompanies]);

  const updateCrmCompanyContactState = useCallback((crmCompanyProp: CrmCompany, updatedCrmContact:CrmContact) => {
    const crmCompany: CrmCompany = crmCompanyProp;
    const updatedCrmContacts: CrmContact[] = replaceBy('id', updatedCrmContact, crmCompany.crmContacts!);
    crmCompany.crmContacts = updatedCrmContacts;
    return dispatch(setAsyncResource(
      crmCompaniesStorePath,
      AsyncStatus.Success,
      replaceBy('id', crmCompany, crmCompanies)
    ));
  }
  , [dispatch, crmCompanies]);

  const removeCrmCompanyContactState = useCallback((crmCompanyProp: CrmCompany, removedCrmContactId:string) => {
    const crmCompany: CrmCompany = crmCompanyProp;
    const updatedCrmContacts: CrmContact[] = removeBy('id', removedCrmContactId, crmCompany.crmContacts!);
    crmCompany.crmContacts = updatedCrmContacts;
    return dispatch(setAsyncResource(
      crmCompaniesStorePath,
      AsyncStatus.Success,
      replaceBy('id', crmCompany, crmCompanies)
    ));
  }
  , [dispatch, crmCompanies]);

  return {
    crmCompanies,
    inactivateCrmCompany,
    activateCrmCompany,
    status,
    addCrmCompany,
    editCrmCompany,
    loadCrmCompanies,
    addCrmCompanyContactState,
    updateCrmCompanyContactState,
    removeCrmCompanyContactState,
  };
};
