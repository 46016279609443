import { CrmAction } from '.';
import { apiFetch, HTTPMethod } from '../fetch';

export const getCrmActions = (companyId?: string) => {
  const url = companyId ? `/CrmActions?companyId=${companyId}` : '/CrmActions';
  return apiFetch<CrmAction[]>(url);
};

export const createCrmAction = (crmAction: CrmAction): Promise<CrmAction> =>
  apiFetch('/CrmActions', crmAction, HTTPMethod.POST);

export const updateCrmAction = (crmActionId: string, crmAction: CrmAction) : Promise<CrmAction> =>
  apiFetch(`/CrmActions/${crmActionId}`, crmAction, HTTPMethod.PUT);

export const finishCrmAction = (crmActionId: string) : Promise<CrmAction> =>
  apiFetch(`/CrmActions/${crmActionId}/complete`, undefined, HTTPMethod.PUT);

export const dismissCrmAction = (crmActionId: string) : Promise<CrmAction> =>
  apiFetch(`/CrmActions/${crmActionId}/reject`, undefined, HTTPMethod.PUT);

export const deleteCrmAction = (crmActionId: string) =>
  apiFetch(`/CrmActions/${crmActionId}`, undefined, HTTPMethod.DELETE);