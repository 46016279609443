import { useState, useEffect, useRef, FunctionComponent, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { cond, equals } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, setBreadcrumbs, AsyncStatus} from 'store';
import { Switch, Case } from 'components/shared/switch';
import { Customer, useCustomers } from 'integrations/crossborderit';
import { Text } from '@fluentui/react';
import './Breadcrumb.css';

export interface BreadcrumbItem {
  text: string;
  href?: string;
  links?: {
    text: string;
    href: string;
  }[];
}

export interface CustomersBreadcrumbItem extends BreadcrumbItem {
  text: '@customer';
  companyId: string;
  customerCompanyId: string;
}

const selectBreadcrumb = (state: AppState) =>
  (state?.breadcrumbs || []).filter((breadcrumb) => !!breadcrumb.text);

export const Breadcrumb: FunctionComponent<{ style?: CSSProperties }> = (
  props
) => {
  const breadcrumbs = useSelector(selectBreadcrumb);
  return (
    <ul className="cbit-breadcrumb" {...props}>
      {breadcrumbs.map(
        cond([
          [
            (item) => item.text === '@customer',
            (item) => (
              <Customers
                key={item.customerCompanyId}
                companyId={item.companyId}
                customerCompanyId={item.customerCompanyId}
              />
            ),
          ],
          [
            (item) => !!item.links,
            (item, i) => <DropdownItem key={item + i} links={item.links} />,
          ],
          [
            (item) => !!item.href,
            (item, i) => <LinkItem key={item + i} item={item} />,
          ],
          [
            () => true,
            (item, i) => (
              <li
                style={{ lineHeight: 'normal' }}
                className="cbit-breadcrumb__item"
                key={item.text + i}
              >
                {item.text}
              </li>
            ),
          ],
        ])
      )}
    </ul>
  );
};

const DropdownItem = ({
  links,
}: {
  links: { text: string; href: string }[];
}) => {
  const [head, ...rest] = links;

  const renderSingle = () => (
    <li className="cbit-breadcrumb__item" key={head.text}>
      <Text style={{ color: 'rgba(255, 255, 255, 0.65)' }}>{head.text}</Text>
    </li>
  );

  const renderWithDropdown = () => (
    <li className="cbit-breadcrumb__item" key={head.text}>
      <Dropdown
        overlay={
          <Menu theme="dark">
            {rest.map((link) => (
              <Menu.Item key={link.text}>
                <Link to={link.href}>{link.text}</Link>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Text style={{ color: 'rgba(255, 255, 255, 0.65)' }}>{head.text}</Text>
      </Dropdown>
    </li>
  );

  return rest.length === 0 ? renderSingle() : renderWithDropdown();
};

const LinkItem = ({ item }: { item: { text: string; href?: string } }) => (
  <li className="cbit-breadcrumb__item">
    <Link to={item.href || ''}>{item.text}</Link>
  </li>
);

interface CustomersProps {
  companyId: string;
  customerCompanyId: string;
}

const Customers = ({ companyId, customerCompanyId }: CustomersProps) => {
  const [customer, setCustomer] = useState<Customer>();
  const {activeCustomers, status} = useCustomers(companyId);

  const renderSingle = () => (
    <li className="cbit-breadcrumb__item">{customer?.companyName}</li>
  );

  const renderWithDropdown = () => {
    const menu = (
      <Menu theme="dark">
        {activeCustomers.map((customer) => (
          <Menu.Item key={customer.id}>
            <Link
              to={`/workspace/${companyId}/customers/${customer.companyId}`}
            >
              {customer.companyName}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <li className="cbit-breadcrumb__item cbit-breadcrumb__item--clickable">
        <Dropdown overlayStyle={{ top: '70px' }} overlay={menu}>
          <Text
            style={{
              display: 'inline-block',
              height: '100%',
              fontFamily: 'inherit',
              WebkitFontSmoothing: 'auto',
            }}
          >
            {customer?.companyName}
          </Text>
        </Dropdown>
      </li>
    );
  };

  useEffect(() => {
    setCustomer(activeCustomers.find(customer => customer.companyId === customerCompanyId));
  }, [customerCompanyId, activeCustomers]);

  return (
    <Switch on={status} >
      <Case match={[AsyncStatus.Loading]}>
        <span style={{width: '100px'}}></span>
      </Case>
      <Case match={[AsyncStatus.Success]}>
        {activeCustomers.length === 1
          ? renderSingle()
          : renderWithDropdown()
        }
      </Case>
    </Switch>
  );
};

export const useBreadcrumb = (
  breadcrumb: (BreadcrumbItem | CustomersBreadcrumbItem)[]
) => {
  const previousBreadcrumbRef = useRef<BreadcrumbItem[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    // equals function checks for deep comparison and not object reference
    if (!equals(previousBreadcrumbRef.current, breadcrumb)) {
      previousBreadcrumbRef.current = breadcrumb;
      dispatch(setBreadcrumbs(breadcrumb));
    }
  }, [breadcrumb, dispatch]);
};
