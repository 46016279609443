import {
  useState,
  useEffect,
  useRef,
  FunctionComponent,
  ChangeEvent,
  FocusEvent,
} from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/es/input';

export interface InputProps extends AntdInputProps {
  value?: string;
  mask?: (value: string) => string;
}

export const Input: FunctionComponent<InputProps> = ({
  value,
  mask = (x) => x,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  ...props
}) => {
  const inputRef = useRef<any>(null);
  const [internalValue, setInternalValue] = useState<string>('');
  const [displayedValue, setDisplayedValue] = useState<string>('');

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || '';
    setInternalValue(value);
    setDisplayedValue(value);
    onChange(event);
  };

  const _onFocus = (event: FocusEvent<HTMLInputElement>) => {
    setDisplayedValue(internalValue);
    onFocus(event);
  };

  const _onBlur = (event: FocusEvent<HTMLInputElement>) => {
    setDisplayedValue(mask(internalValue));
    onBlur(event);
  };

  useEffect(() => {
    setInternalValue(value || '');
    const isElementFocused = !!inputRef?.current?.state?.focused;
    const displayedValue = isElementFocused ? value || '' : mask(value || '');
    setDisplayedValue(displayedValue);
  }, [value, mask]);

  return (
    <AntdInput
      {...props}
      ref={inputRef}
      value={displayedValue}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
    />
  );
};
