import { apiFetch, HTTPMethod } from '../fetch';

export const sendPhoneNumberVerification = (phoneNumber: string, companyId: string): Promise<Response> =>
  apiFetch(`/Verification/${companyId}/sendPhoneNumberVerification`, phoneNumber, HTTPMethod.POST);

export const verifyCompanyPhoneNumber = (phoneNumber: string, verificationCode: string, companyId: string): Promise<string> =>
  apiFetch(`/Verification/${companyId}/verifyCompanyPhoneNumber?verificationCode=${verificationCode}`, phoneNumber, HTTPMethod.PUT);

export const sendCompanyEmailAddressVerification = (phoneNumber: string, companyId: string): Promise<Response> =>
  apiFetch(`/Verification/${companyId}/sendCompanyEmailAddressVerification`, phoneNumber, HTTPMethod.POST);

export const verifyCompanyEmailAddress = (emailAddress: string, verificationCode: string, companyId: string, contactId: string = ''): Promise<string> =>
  apiFetch(`/Verification/${companyId}/verifyCompanyEmailAddress?verificationCode=${verificationCode}&contactId=${contactId}`, emailAddress, HTTPMethod.PUT);

export const setCompanyEmailAddressAsVerified = (companyId: string, emailAddress: string): Promise<string> =>
  apiFetch(`/Verification/${companyId}/setCompanyEmailAddressAsVerified`, emailAddress, HTTPMethod.PUT);

export const getVerifiedCompanyPhoneNumbers = (companyId: string): Promise<string []> =>
  apiFetch(`/Verification/${companyId}/verifiedCompanyPhoneNumbers`);

export const setCompanyPhoneNumberAsVerified = (companyId: string, phoneNumber: string): Promise<string> =>
  apiFetch(`/Verification/${companyId}/setCompanyPhoneNumberAsVerified`, phoneNumber, HTTPMethod.PUT);

export const getVerifiedCompanyEmailAddresses = (companyId: string): Promise<string []> =>
  apiFetch(`/Verification/${companyId}/verifiedCompanyEmailAddresses`);