import { registerIcons } from '@fluentui/react/lib/Styling';
import plusiusLogo from './plusisus_logo.png';

export function initializeCustomIcons () {
  registerIcons({
    icons: {
      PlusiusLogo: (<img src={plusiusLogo} alt="P" width="16" />),
      cbitIcon: (
        <svg id="Lager_1" data-name="Lager 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.7944 92.7944">
          <g id="type">
            <polygon id="type_PathItem_" data-name="type &lt;PathItem&gt;" points="53.026 26.513 79.538 0 92.794 13.256 66.282 39.762 53.026 39.762 53.026 26.513" style={{fill: '#183cef'}}/>
            <polygon id="type_PathItem_2" data-name="type &lt;PathItem&gt;" points="66.282 53.026 92.794 79.538 79.538 92.794 53.032 66.282 53.032 53.026 66.282 53.026" style={{fill: '#ee1313'}}/>
            <polygon id="type_PathItem_3" data-name="type &lt;PathItem&gt;" points="39.769 26.513 13.256 0 0 13.256 26.512 39.762 39.769 39.762 39.769 26.513" style={{fill: '#009b3a'}}/>
            <polygon id="type_PathItem_4" data-name="type &lt;PathItem&gt;" points="26.513 53.026 0 79.538 13.256 92.794 39.762 66.282 39.762 53.026 26.513 53.026" style={{fill: '#fccc00'}}/>
          </g>
        </svg>
      ),
    },
  });
}