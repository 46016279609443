import { PartialTheme, useTheme as useFluentTheme } from '@fluentui/react';

export interface CbitTheme extends PartialTheme {
  breakpoints: {
    desktop: number;
    mobile: number;
  };
}

export const useTheme = () => useFluentTheme() as CbitTheme;
