import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrency } from 'store';
import { Switch, Case } from 'components/shared/switch';

interface Props {
  mode?: 'name' | 'alpha3Code' | 'both';
  currencyCode?: string;
}

export const Currency: FunctionComponent<Props> = ({
  mode = 'both',
  currencyCode,
}) => {
  const currency = useSelector(selectCurrency(currencyCode || ''));

  return !currencyCode || !currency ? (
    <></>
  ) : (
    <Switch on={mode}>
      <Case match={['name']} component={<>{currency?.name}</>} />
      <Case match={['alpha3Code']} component={<>{currency?.alpha3Code}</>} />
      <Case
        match={['both']}
        component={
          <>
            {currency?.name} ({currency?.alpha3Code})
          </>
        }
      />
    </Switch>
  );
};
