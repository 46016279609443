export interface StatusOfVatReturnJob {
  currentEntry: number
  message?: string
  state: VatReturnsJobState
  stateName: VatReturnsJobState
  totalNumberOfEntries: number
  totalNumberOfEntriesWithProblems: number
}

export interface VatReturnJob {
  documentUrl: string
  id: string
  jobEndedUtc: string
  jobStartedBy: string
  jobStartedByName: string
  jobStartedUtc: string
  month: number
  numberOfCorrections: number
  numberOfDuplicatesFiltered: number
  numberOfEmptyTaxReturnsProduced: number
  numberOfEntries: number
  numberOfIossNumbersProcessed: number
  totalCorrectionsSalesAmount: number
  totalCorrectionsVatAmount: number
  totalSalesAmount: number
  totalVatAmount: number
  year: number
}

export interface VatReturn {
  documentUrl: string
  id: string
  iossNumber: string
  month: number
  numberOfCorrectionsForPreviousPeriods: number
  numberOfDuplicatesFiltered: number
  numberOfEntriesForPeriod: number
  referenceNumberFromSkv: number
  totalCorrectionsSalesAmount: number
  totalCorrectionsVatAmount: number
  totalSalesAmount: number
  totalVatAmount: number
  year: number
}

export enum VatReturnsJobState {
  Idle,
  Processing,
  CompletedSuccessfully,
  CompletedWithErrors,
  None,
}