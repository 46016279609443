import { apiFetch, HTTPMethod } from '../fetch';
import { CollectionInvoice } from '../collectionInvoices';
import { DateRange } from '../analytics';

const getInvoicesByCompany = (companyId: string) =>
  apiFetch<CollectionInvoice[]>(`/financials/${companyId}/invoices`);

const syncInvoicesWithDynamics = (dateRange?: DateRange) => {
  let url = '/financials/dynamics/sync?';
  url += dateRange?.from ? `fromDate=${dateRange.from}` : '';
  url += dateRange?.from != null && dateRange?.to != null ? '&' : '';
  url += dateRange?.to ? `toDate=${dateRange.to}` : '';
  return apiFetch<CollectionInvoice[]>(url);
};

const deleteInvoiceEmailAddress = (companyId: string, billingInformationId: string, id: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/billinginformation/${billingInformationId}/invoiceEmail/${id}`, undefined, HTTPMethod.DELETE);

const putInvoiceEmailAddress = (companyId: string, billingInformationId: string, id: string, updatedInvoiceEmail: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/billinginformation/${billingInformationId}/invoiceEmail/${id}`, updatedInvoiceEmail, HTTPMethod.PUT);

const postInvoiceEmailAddress = (companyId: string, billingInformationId: string, newInvoiceEmail: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/billinginformation/${billingInformationId}/invoiceEmail`, newInvoiceEmail, HTTPMethod.POST);

export {
  getInvoicesByCompany,
  deleteInvoiceEmailAddress,
  postInvoiceEmailAddress,
  putInvoiceEmailAddress,
  syncInvoicesWithDynamics,
};