import { FunctionComponent, useState, useEffect } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Sidebar } from 'components/shared/sidebar';
import { Switch, Case } from 'components/shared/switch';
import { LayoutContent } from './LayoutContent';

export interface LayoutLoadingProps {
  // aware can be used when there is a sidebar higher up in the tree
  // and we want to avoid the loading indicator from changing position
  // when the sidebar renders
  sidebar?: 'none' | 'visible' | 'aware' | 'center';
  loadingLabel?: string;
  spinnerFullHeight?: boolean;
}

export const LayoutLoading: FunctionComponent<LayoutLoadingProps> = ({
  sidebar = 'none',
  loadingLabel,
  spinnerFullHeight = false,
}) => {
  const [windowWidth, setWindowWidth] = useState({
    width: 0,
  });

  const sidebarWidth = '249px';

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth({
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const LoadingSpinner = () => (
    <Spinner
      label={loadingLabel}
      size={SpinnerSize.large}
      style={{ height: spinnerFullHeight ? '100%' : '80%' }}
    />
  );

  return (
    <Switch on={sidebar}>
      <Case match={['none']}>
        <LayoutContent withSidebar={false}>
          <LoadingSpinner />
        </LayoutContent>
      </Case>

      <Case match={['visible']}>
        <div>
          <Sidebar loading />
        </div>
        <div
          style={{
            overflowY: 'auto',
            height: '100%',
            marginLeft: `-${sidebarWidth}`,
          }}
        >
          <LoadingSpinner />
        </div>
      </Case>

      <Case match={['aware']}>
        <div
          style={{
            overflowY: 'hidden',
            height: '100%',
            marginLeft: windowWidth.width > 1439 ? `-${sidebarWidth}` : '-0',
          }}
        >
          <LoadingSpinner />
        </div>
      </Case>

      <Case match={['center']}>
        <div
          style={{
            overflowY: 'hidden',
            height: '100%',
            width: '100%',
          }}
        >
          <LoadingSpinner />
        </div>
      </Case>

    </Switch>
  );
};
