import { apiFetch, HTTPMethod } from '../fetch';
import {
  ProductsSubmittedForExternalClassificationRequest,
  ClassificationExternalRequestStatus,
  ClassifiationExternalJobsQuery,
  ClassificationExternalSubmitOverview,
  ClassificationExternalProduct,
  ClassificationExternalStatus,
  ClassificationExternalResultSingleProduct,
  ExternalClassificationResponse,
} from './interfaces';
// TypeScript complains that there are no types for query-string if import keyword is used.
const queryString = require('query-string');

export const getListOfSubmittedExternalJobs = (
  companyId: string,
  query?: ClassifiationExternalJobsQuery
) => {
  const queryStringified = !!query ? `?${queryString.stringify(query)}` : '';
  return apiFetch<ClassificationExternalSubmitOverview[]>(
    `/companies/${companyId}/products/classifications/services${queryStringified}`
  );
};

export const getListOfProductsInSubmittedExternalJob = (
  companyId: string,
  submitId: string
) => apiFetch<ClassificationExternalProduct[]>(
  `/companies/${companyId}/products/classifications/services/${submitId}`
).then(x => x.map(transformToExternalClassificationProduct));

export const submitProductsForExternalClassification = (
  companyId: string,
  classificationRequest: ProductsSubmittedForExternalClassificationRequest
) => apiFetch<ClassificationExternalRequestStatus>(
  `/companies/${companyId}/products/classifications/services/`,
  classificationRequest,
  HTTPMethod.PUT
);

export const resubmitFailedExternalProducts = (
  companyId: string,
  submitId: string
) => apiFetch<ClassificationExternalRequestStatus>(
  `/companies/${companyId}/products/classifications/services/${submitId}/ResubmitFailedProducts`,
  undefined,
  HTTPMethod.PUT
);

export const sendResponseToClassifier = (
  companyId: string, externalClassificationResponse: ExternalClassificationResponse
) => apiFetch<void>(
  `/companies/${companyId}/products/classifications/services/sendResponseToEmail`, externalClassificationResponse, HTTPMethod.PUT);

const resubmitableClassificationStatuses = [
  ClassificationExternalStatus.FailedToClassify,
  ClassificationExternalStatus.SubmissionRejected,
  ClassificationExternalStatus.SubmissionFailed,
];

const transformToExternalClassificationProduct = (classificationResultSingleProduct: ClassificationExternalResultSingleProduct): ClassificationExternalProduct => ({
  ...classificationResultSingleProduct,
  isResubmitable: resubmitableClassificationStatuses
    .includes(classificationResultSingleProduct.classificationStatus),
});
