import download from 'downloadjs';
import { match, pipe, replace } from 'ramda';
import { apiFetch, HTTPMethod } from '../fetch';
import { Separator, NumberFormat } from './interfaces';

const FILENAME = /filename=.+(?=;)/;

export const findFilename = pipe(match(FILENAME), ([fileName]) => fileName, replace('filename=', ''));

export const exportFile = (companyId: string, importId: string): Promise<void> =>
  apiFetch<Response>(
    `/companies/${companyId}/products/export?importId=${importId}`,
    {
      separator: Separator.Semicolon,
      numberFormattingOption: NumberFormat.DecimalComma,
    },
    HTTPMethod.PUT
  )
    .then((response) => (response.ok ? response : Promise.reject()))
    .then((response) => response
      .blob()
      .then((blob) => download(blob, 'exportedProductList.csv'))
      .then());
