import { FunctionComponent, CSSProperties } from 'react';

export interface LayoutContentProps {
  verticalScroll?: boolean;
  withSidebar?: boolean;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: CSSProperties;
}

export const LayoutContent: FunctionComponent<LayoutContentProps> = ({
  verticalScroll = true,
  withSidebar = true,
  as: Element = 'div',
  style = {},
  ...props
}) => (
  <Element
    style={{
      ...style,
      gridArea: withSidebar
        ? 'content'
        : 'sidebar / sidebar / content / content',
      overflowY: verticalScroll ? 'auto' : 'visible',
      overflowX: 'hidden',
    }}
    {...props}
  />
);
