import { CompanyCategory } from '..';

export enum CmsContentType {
  NotSelected,
  All,
  NewFeatures,
  HowItWorks,
  SystemAlert,
}
export interface CmsContent {
  id: string;
  contentType: CmsContentType;
  title: string;
  content: string;
  active: boolean;
  addedUtc: Date;
  addedBy: string;
  visibleFromUtc?: Date;
  visibleToUtc?: Date;
  revokedUtc?: Date,
  revokedBy?: string;
  visibleFor?: CompanyCategory[];
}