import { FunctionComponent, ReactNode, CSSProperties } from 'react';
import { Result } from 'antd';
import { FontIcon, Text } from '@fluentui/react';

export interface SuccessResultProps {
  title?: ReactNode;
  subTitle?: ReactNode;
  extra?: ReactNode;
  style?: CSSProperties;
}

export const SuccessResult: FunctionComponent<SuccessResultProps> = ({
  title,
  subTitle,
  ...props
}) => (
  <Result
    icon={
      <FontIcon
        iconName="CheckMark"
        style={{
          color: 'rgb(16, 110, 190)',
          fontSize: '48px',
          width: '48px',
          height: '48px',
        }}
      />
    }
    title={<Text variant="xLarge">{title}</Text>}
    subTitle={<Text variant="mediumPlus">{subTitle}</Text>}
    {...props}
  />
);
