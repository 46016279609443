import { FunctionComponent } from 'react';
import { Text } from '@fluentui/react';
import { Address as IAddress } from 'integrations/crossborderit';

interface Props {
  address?: IAddress;
}

export const Address: FunctionComponent<Props> = ({ address }) =>
  !address ? (
    <></>
  ) : (
    <Text block>
      <Text block>{address.addressLine1}</Text>
      <Text block>{address.addressLine2}</Text>
      <Text block>
        {address.city} {address.state} {address.postalCode}
      </Text>
    </Text>
  );
