import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Persona,
  PersonaSize,
  DefaultEffects,
  Icon,
  Stack,
  Text,
} from '@fluentui/react';
import { Menu, Dropdown, Divider } from 'antd';
import { AppState } from 'store';
import { ApplicationPaths, User, AppVersion } from 'integrations/crossborderit';
import './UserMenu.css';

interface Props {
  user?: User;
  appVersion?: AppVersion;
}

const selectUserMenuProps = (state: AppState): Props => ({
  user: state.user,
  appVersion: state.appVersion,
});

export const UserMenu = () => {
  const { t } = useTranslation();
  const { user, appVersion } = useSelector(selectUserMenuProps);

  const UserMenu = () => (
    <div
      style={{
        minWidth: '300px',
        boxShadow: DefaultEffects.elevation16,
        backgroundColor: 'hsl(0, 0%, 100%)',
      }}
    >
      <div style={{ padding: '16px' }}>
        <Persona
          text={user?.name}
          secondaryText={user?.email}
          size={PersonaSize.size40}
        />
      </div>
      <Divider style={{ margin: '0' }} />
      <Menu>
        <Menu.Item key="Profile" className="user-menu-item">
          <Stack horizontal>
            <Icon
              style={{ marginRight: '1em', color: 'black' }}
              iconName="PlayerSettings"
            ></Icon>
            <Link to={ApplicationPaths.Profile}>{t('Manage account')}</Link>
          </Stack>
        </Menu.Item>
        <Menu.Item key="Logout" className="user-menu-item">
          <Stack horizontal>
            <Icon
              style={{ marginRight: '1em', color: 'black' }}
              iconName="Leave"
            ></Icon>
            <Link
              to={{
                pathname: `${ApplicationPaths.LogOut}`,
                state: { local: true },
              }}
            >
              {t('Logout')}
            </Link>
          </Stack>
        </Menu.Item>
      </Menu>
      <Divider style={{ margin: '0' }} />
      <div style={{ padding: '8px', textAlign: 'center' }}>
        <Text variant="medium" style={{ color: 'hsla(0, 0%, 0%, 0.45)' }}>
          {appVersion?.environment}: {appVersion?.version}
        </Text>
      </div>
    </div>
  );

  return (
    <Dropdown overlay={UserMenu} trigger={['click']}>
      <div
        style={{
          width: '32px',
          minWidth: '32px',
          height: '32px',
          backgroundColor: 'hsl(0, 0%, 70%)',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Icon
          iconName="Contact"
          style={{
            color: 'hsl(0, 0%, 100%)',
            userSelect: 'none',
          }}
        />
      </div>
    </Dropdown>
  );
};
