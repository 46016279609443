export enum UserRole {
  Employee = 'Employee',
  SuperAdmin = 'SuperAdmin',
}

export interface User {
  name: string;
  email: string;
  /* eslint-disable camelcase */
  family_name?: string;
  given_name?: string;
  /* eslint-enable camelcase */
  sub: string;
  role: UserRole[];
}
