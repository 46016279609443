import { FunctionComponent } from 'react';
import { Icon, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import './CompactResult.css';

export interface CompactResultProps {
  title?: string;
  subTitle?: string;
  icon?: string;
}

export const CompactResult: FunctionComponent<CompactResultProps> = ({
  title: _title,
  subTitle: _subTitle,
  icon: _icon,
}) => {
  const { t } = useTranslation();

  const title = _title || t('Something went wrong');
  const subTitle = _subTitle || t('Try again in a few minutes');
  const icon = _icon || 'Info';

  return (
    <Stack className="compact-result" horizontalAlign="start" verticalAlign="center" horizontal>
      <Stack.Item>
        <Icon className="compact-result-icon" iconName={icon}/>
      </Stack.Item>
      <Stack.Item>
        <Text variant="large" className="title">{title}</Text>
        <Text variant="medium">{subTitle}</Text>
      </Stack.Item>
    </Stack>
  );
};
