export enum AsyncStatus {
  NotInitialized = 'NotInitialized',
  NotAuthenticated = 'NotAuthenticated',
  Loading = 'Loading',
  Error = 'Error',
  Success = 'Success',
}

export interface AsyncResource<T> {
  status?: AsyncStatus;
  data?: T;
}
