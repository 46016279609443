import { FunctionComponent } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { CompanyCategory } from 'integrations/crossborderit';

const CAPITAL_LETTER = /(?=[A-Z])/;

interface Props extends SelectProps<string | string[]> {
  companyCategories?: CompanyCategory[];
}

export const CompanyCategorySelect: FunctionComponent<Props> = ({
  companyCategories,
  ...props
}) => (
  <Select {...props}>
    {(companyCategories || Object.values(CompanyCategory)).map((category) => (
      <Select.Option key={category} value={category}>
        {category.split(CAPITAL_LETTER).join(' ')}
      </Select.Option>
    ))}
  </Select>
);
