export interface CalculationInput {
  originCountryCode: string;
  destinationCountryCode: string;
  destinationStateOrProvince: string; // Mandatory for Canada
  currencyCode: string;
  freightCharges: number; //  Decimal
  insuranceCharges: number; // Decimal
  brokerageCharges: number; // Decimal
  transportationType: string;
  commodities: CalculationCommodityEntry[];
}

export interface CalculationCommodityEntry {
  hsCode: string;
  customsValue: string; // Decimal
  measureAmount: string; // Decimal, Number of items, How many Kgs etc
  measurementUnit: CalculationMeasurementUnit;
  countryOfManufactureId: string;
}

export interface CalculationResult {
  currencyCode: string;
  calculationResultStatus: string;
  extraStatusMessage: string;
  calculationCommodityResults: CalculationCommodityResult[];
}

export interface CalculationCommodityResult {
  hsCode: string;
  status: CalculationResultStatus;
  errorMessages: string[];
  taxData: CalculationResultTaxData[];
}

export interface CalculationResultTaxData {
  taxAndFeeType: CalculationTaxAndFeeType;
  name: string;
  currencyCode: string;
  effectiveDate: string;
  taxableValue: number; // Decimal
  description: string;
  formula: string;
  amount: number; // Decimal
}

export enum CalculationMeasurementUnit {
  ItemCount = 'ItemCount',
  Kilogram = 'Kilogram',
  Liter = 'Liter',
  Meter = 'Meter',
  SquareMeter = 'SquareMeter',
}

export enum CalculationTransportationType {
  Air = 'Air',
  Ground = 'Ground',
  Ocean = 'Ocean',
  Rail = 'Rail',
}

export enum CalculationResultStatus {
  None = 'None',
  Success = 'Success',
  Failure = 'Failure',
  Error = 'Error',
  Warning = 'Warning',
  CommunicationError = 'Communication Error',
  NoResponseReceivedForEntry = 'No Response Received For Entry',
}

export enum CalculationTaxAndFeeType {
  Duty = 'Duty',
  CustomsSurcharges = 'Customs Surcharges',
  GeneralSalesTax = 'General Sales Tax',
  AdditionalTaxes = 'Additional Taxes',
}
