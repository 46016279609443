import { useCallback, useMemo } from 'react';
import { EntityNote, EntityNoteUserTag } from './interfaces';

export const useNoteMentions = () => {
  const mentionRegex = useMemo(() => /@\[.+?\]\(.+?\)/gm, []);

  const getMentionsFromNote = useCallback((note: string) => {
    const displayRegex = /@\[.+?\]/g;
    const idRegex = /\(.+?\)/g;
    const matches = note.match(mentionRegex);
    const mentions: EntityNoteUserTag[] = [];
    matches && matches.forEach((m: any) => {
      const id = m
        .match(idRegex)[0]
        .replace('(', '')
        .replace(')', '');
      const display: string = m
        .match(displayRegex)[0]
        .replace('@[', '')
        .replace(']', '');
      mentions.push({ userId: id, fullName: display } as EntityNoteUserTag);
    });
    return mentions;
  }, [mentionRegex]);

  const getNoteOutput = useCallback((note: EntityNote) => {
    if (note.message && note.message !== '') {
      const mentions = getMentionsFromNote(note.message);
      const users = note.entityNoteUserTags;
      const newNote = note.message.split(mentionRegex);
      let output = '';
      for (let i = 0; i < newNote.length; i += 1) {
        const n = newNote[i];
        if (i === newNote.length - 1) output += n;
        else {
          const user = users.find(_ => _.userId === mentions[i].userId);
          output += `${n}<span key="${user?.id}" title="${user?.email || 'Email was not found.'}" class="entity-notes_mention">${user?.fullName}</span>`;
        }
      }
      return output.replace(/\n\r?/g, '<br />');
    }
  }, [getMentionsFromNote, mentionRegex]);

  return {
    getMentionsFromNote,
    getNoteOutput,
  };
};