import { AdvancedFee, CompanyAdvancedFee } from './interfaces';
import { apiFetch, HTTPMethod } from '../fetch';

export const getAdvancedFees = (): Promise<AdvancedFee[]> =>
  apiFetch('/advancedfees');

export const postAdvancedFee = (fee: AdvancedFee): Promise<AdvancedFee> =>
  apiFetch('/advancedfees', fee, HTTPMethod.POST);

export const putAdvancedFee = (fee: AdvancedFee): Promise<AdvancedFee> =>
  apiFetch(`/advancedfees/${fee.id}`, fee, HTTPMethod.PUT);

export const deleteAdvancedFee = (id: string): Promise<AdvancedFee> =>
  apiFetch(`/advancedfees/${id}`, undefined, HTTPMethod.DELETE);

export const getAdvancedFeeRelated = (fee: AdvancedFee): Promise<string[]> =>
  apiFetch(`/advancedfees/${fee.id}/related`);

export const updateCompanyAdvancedFees = (companyId: string, advancedFeesIds: string[]) =>
  apiFetch<CompanyAdvancedFee[]>(`/advancedfees/company/${companyId}/companyAdvancedFees`, advancedFeesIds, HTTPMethod.POST);