export interface Customer {
  id: string;
  companyId: string;
  companyName: string;
  companyIsActive: boolean;
  companyCompanyRoles: string[];
  companyCategories: string[];
  iossNumber: string;
  iossEndDate?: string;
}

export enum ActiveStatus {
  Active = 1,
  Inactive = 2,
  All = 3,
}