import { CrmContact } from '.';
import { apiFetch, HTTPMethod } from '../fetch';

export const getCrmCompanyContacts = (crmCompanyId: string): Promise<CrmContact[]> =>
  apiFetch(`/CrmContacts/${crmCompanyId}`, undefined, HTTPMethod.GET);

export const getCrmContact = (crmCompanyId: string, crmContactId: string): Promise<CrmContact> =>
  apiFetch(`/CrmContacts/${crmCompanyId}/${crmContactId}`, undefined, HTTPMethod.GET);

export const createCrmContact = (crmCompanyId: string, crmContact: CrmContact): Promise<CrmContact> =>
  apiFetch(`/CrmContacts/${crmCompanyId}`, crmContact, HTTPMethod.POST);

export const updateCrmContact = (crmCompanyId: string, crmContactId: string, updatedCrmContact: CrmContact): Promise<CrmContact> =>
  apiFetch(`/CrmContacts/${crmCompanyId}/${crmContactId}`, updatedCrmContact, HTTPMethod.PUT);

export const revokeCrmContact = (crmCompanyId: string, crmContactId:string): Promise<CrmContact> =>
  apiFetch(`/CrmContacts/${crmCompanyId}/${crmContactId}/revoke`, undefined, HTTPMethod.PUT);

export const unRevokeCrmContact = (crmCompanyId: string, crmContactId:string): Promise<CrmContact> =>
  apiFetch(`/CrmContacts/${crmCompanyId}/${crmContactId}/unrevoke`, undefined, HTTPMethod.PUT);

export const deleteCrmContact = (crmCompanyId: string, crmContactId:string) =>
  apiFetch(`/CrmContacts/${crmCompanyId}/${crmContactId}`, undefined, HTTPMethod.DELETE);