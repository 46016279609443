import { useEffect, useState } from 'react';
import { Col, Form, Input, message, Row } from 'antd';
import { Button } from 'components/shared/button';
import { ContactPerson as ContactPersonInput } from 'components/shared/input';
import { Stack, Text, FontIcon, TooltipHost, ITooltipHostStyles, DirectionalHint, Link } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Header } from 'components/header';
import { CompanyCategory, usePageOptions, WebShopTypeDataOption } from 'integrations/crossborderit';
import {
  Onboarding,
  postMerchantOnboarding,
  OnboardingOptions,
} from '../../integrations/crossborderit/onboardings';
import { Switch, Case } from 'components/shared/switch';
import { Redirect } from 'react-router';
import { cbitSupportEmail } from 'config';
import { Layout } from 'components/shared/layout';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { AsyncStatus } from 'store';

enum PageState {
  Idle = 'Idle',
  Loading = 'Loading',
  RegistrationComplete = 'RegistrationComplete',
  Submitting = 'Submitting',
}

export const MerchantOnboarding = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [webShopTypeDataOption, setWebShopTypeDataOption] = useState<WebShopTypeDataOption>();

  const webShopReferrer = (new URLSearchParams(window.location.search)).get('referrer');
  const { pageOptions } = usePageOptions();
  const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0',
  });
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      display: 'inline-block',
      marginRight: '10px',
    },
  };

  const calloutProps = { gapSpace: 0 };

  const onFormSubmit = (formValues: Onboarding) => {
    setPageState(PageState.Submitting);

    const urlParams = new URLSearchParams(window.location.search);

    const onboarding = {
      ...formValues,
      onboardingOptions: [OnboardingOptions.AddIoss],
      companyCategories: [CompanyCategory.Merchant],
    };

    if (!webShopReferrer) {
      onboarding.referrer = urlParams.get('referrer');
    }

    postMerchantOnboarding(onboarding)
      .then(() => {
        message.success(t('Invitation has been sent'));
        setPageState(PageState.RegistrationComplete);
      })
      .catch((error) => {
        message.error(t(error?.message || 'Could not send invitation'));
        setPageState(PageState.Idle);
      });
  };

  useEffect(() => {
    if (pageOptions.status === AsyncStatus.Success) {

      switch (pageState) {
        case PageState.Loading:
          const webShopTypeOption = pageOptions.data.webShopType.options.filter((webshop) => webshop.id === webShopReferrer && (webshop.downloadUrl !== undefined || webshop.marketPlaceUrl !== undefined))[0];
          if (webShopTypeOption === undefined) {
            window.location.href = 'https://www.crossborderit.com/ioss';
          }
          setWebShopTypeDataOption(webShopTypeOption);
          setPageState(PageState.Idle);
          break;

        case PageState.Idle:
          break;
      }
    }
  }, [pageOptions, pageState, webShopReferrer, webShopTypeDataOption]);

  return (
    <div style={{ height: '100%' }}>
      <Header showRightSideMenu={false} />
      <Switch on={pageState}>
        <Case match={[PageState.Loading]}>
          <Layout style={{ height: '100%' }}>
            <Layout.Loading loadingLabel={t('Loading onboarding...')} />
          </Layout>
        </Case>
        <Case match={[PageState.Idle, PageState.Submitting]}>
          <Stack>
            <Text
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '24px',
              }}
              variant="xxLarge"
            >
              {webShopReferrer
                ? t(`Merchant account registration with ${webShopReferrer}`)
                : t('Merchant account registration')}
            </Text>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <Form
                  layout="vertical"
                  form={form}
                  preserve={false}
                  onFinish={onFormSubmit}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="companyName"
                        label={t('Company name')}
                        rules={[
                          {
                            required: true,
                            message: t('Please enter the company name'),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={t('Company Category')}
                        name="companyCategories"
                        hidden
                        initialValue={CompanyCategory.Merchant}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Text style={{ fontWeight: 'bold' }} variant="mediumPlus">
                    {t('Contact Person')}
                  </Text>
                  <ContactPersonInput />
                </Form>
                <Row>

                  <Col style={{
                    width: '80%',
                  }}>
                    {(webShopTypeDataOption?.marketPlaceUrl
                      && <Link href={webShopTypeDataOption.marketPlaceUrl} target="_blank">
                        <TooltipHost content="Install from marketplace"
                          calloutProps={calloutProps}
                          styles={hostStyles}
                          directionalHint={DirectionalHint.bottomCenter}
                        >
                          <FontIcon aria-label="Marketplace" iconName="StoreLogoMed20" className={iconClass} />
                        </TooltipHost>
                      </Link>
                    )}
                    {(webShopTypeDataOption?.downloadUrl
                      && <Link href={webShopTypeDataOption.downloadUrl} target="_blank">
                        <TooltipHost content="Download plugin"
                          calloutProps={calloutProps}
                          styles={hostStyles}
                          directionalHint={DirectionalHint.bottomCenter}
                        >
                          <FontIcon aria-label="Direct download" iconName="Download" className={iconClass} />
                        </TooltipHost>
                      </Link>
                    )}
                  </Col>
                  <Col style={{
                    width: '20%',
                    textAlign: 'right',
                  }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        form.submit();
                      }}
                      loading={pageState === PageState.Submitting}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <Stack
              horizontalAlign="center"
              style={{
                margin: '96px auto auto auto',
                left: '0',
                right: '0',
              }}
            >
              <Text>
                Need help? contact us:{' '}
                <a
                  href={`mailto:${cbitSupportEmail}?subject=Crossborderit support`}
                >
                  {cbitSupportEmail}
                </a>
              </Text>
            </Stack>
          </Stack>
        </Case>
        <Case match={[PageState.RegistrationComplete]}>
          <Redirect to="/merchant-onboarding-success"></Redirect>
        </Case>
      </Switch>
    </div>
  );
};
