import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer, createInitialState } from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [thunk];

export const setupStore = () => createStore(
  rootReducer,
  createInitialState(),
  composeWithDevTools(applyMiddleware(...middleware))
);
