import { FunctionComponent, CSSProperties } from 'react';
import moment from 'moment';

interface Props {
  timestamp: string;
  style: CSSProperties;
}

export const TimeAgo: FunctionComponent<Props> = ({
  timestamp,
  style,
}: any) => {
  const getDate = () => moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss').fromNow();

  return <div style={style}>{!!timestamp && getDate()}</div>;
};
