import { FunctionComponent, ReactNode, CSSProperties } from 'react';
import { Result } from 'antd';
import { FontIcon, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

export interface ErrorResultProps {
  title?: ReactNode;
  subTitle?: string;
  extra?: ReactNode;
  style?: CSSProperties;
}

export const ErrorResult: FunctionComponent<ErrorResultProps> = ({
  title: _title,
  subTitle: _subTitle,
  ...props
}) => {
  const { t } = useTranslation();

  const title = _title || t('Something went wrong');
  const subTitle = (_subTitle && _subTitle?.length < 200) ? _subTitle : t('Try again in a few minutes. If the error persists, please contact our support, support@crossborderit.com.');

  return (
    <Result
      icon={
        <FontIcon
          iconName="Error"
          style={{
            color: 'rgb(16, 110, 190)',
            fontSize: '48px',
            width: '48px',
            height: '48px',
          }}
        />
      }
      title={<Text variant="xLarge">{title}</Text>}
      subTitle={<Text variant="mediumPlus">{subTitle}</Text>}
      {...props}
    />
  );
};
