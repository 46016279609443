import { Agreement } from '../agreements';
import { apiFetch, HTTPMethod } from '../fetch';
import { CompanySubscription, Subscription, SubscriptionInitialFee, SubscriptionGroup } from './interfaces';

// Subscription endpoints
export const getAllSubscriptions = () => apiFetch<Subscription[]>('/subscriptions/');

export const getSingleSubscription = (subscriptionId: string) =>
  apiFetch<Subscription>(`/subscriptions/${subscriptionId}`);

export const postSubscription = (subscription:Subscription): Promise<Subscription> =>
  apiFetch<Subscription>('/subscriptions/', subscription, HTTPMethod.POST);

export const putSubscription = (subscription:Subscription): Promise<Subscription> =>
  apiFetch<Subscription>(`/subscriptions/${subscription.id}`, subscription, HTTPMethod.PUT);

// CompanySubscriptions endpoints
export const getAllCompanySubscriptions = (companyId: string) => apiFetch<CompanySubscription []>(`/companies/${companyId}/subscriptions/`);

export const getAllSubscriptionsCompanyCanAccess = (companyId: string) => apiFetch<Subscription []>(`/companies/${companyId}/subscriptions/allSubscriptionsCompanyCanAccess`);

export const getAgreementsForSubscriptions = (companyId: string, subscriptionIds: string[]) => {
  const queryString = subscriptionIds?.map(item => `subscriptionIds=${item}`).join('&');
  return apiFetch<Agreement[]>(`/companies/${companyId}/subscriptions/agreements?${queryString}`);
};

export const checkCompanySubscriptionState = (companyId: string, subscriptionIds: string[]) =>
  apiFetch<CompanySubscription[]>(`/companies/${companyId}/subscriptions/CheckSubscriptionStates`, subscriptionIds, HTTPMethod.PUT);

export const getUnsignedAgreementsForCompanySubscription = (companyId: string, companySubscriptionId: string) =>
  apiFetch<Agreement[]>(`/companies/${companyId}/subscriptions/${companySubscriptionId}/unsignedAgreements`);

export const signCompanySubscriptionAgreements = (companyId: string, companySubscriptionId: string) =>
  apiFetch(`/companies/${companyId}/subscriptions/${companySubscriptionId}/signAgreements`, undefined, HTTPMethod.PUT);

export const signMultipleCompanySubscriptionAgreements = (companyId: string, companySubscriptionIds: string[]) =>
  apiFetch(`/companies/${companyId}/subscriptions/signMultipleCompanySubscriptionAgreements`, companySubscriptionIds, HTTPMethod.PUT);

// ADMIN CompanySubscriptions endpoints
export const adminAddCompanySubscription = (companyId: string, companySubscription: CompanySubscription) =>
  apiFetch<CompanySubscription>(`/companies/${companyId}/subscriptions/adminAdd`, companySubscription, HTTPMethod.POST);

export const adminUpdateCompanySubscription = (companyId: string, companySubscription: CompanySubscription) =>
  apiFetch<CompanySubscription>(`/companies/${companyId}/subscriptions/adminUpdate`, companySubscription, HTTPMethod.PUT);

// Subscription groups endpoints
export const getSubscriptionGroups = () =>
  apiFetch<SubscriptionGroup[]>('/subscriptionGroup');

export const getSubscriptionGroup = (subscriptionGroupId: string) =>
  apiFetch<SubscriptionGroup>(`/subscriptionGroup/${subscriptionGroupId}`);

export const addSubscriptionGroup = (subscriptionGroup: SubscriptionGroup) =>
  apiFetch<SubscriptionGroup>('/subscriptionGroup', subscriptionGroup, HTTPMethod.POST);

export const updateSubscriptionGroup = (subscriptionGroup: SubscriptionGroup) =>
  apiFetch<SubscriptionGroup>(`/subscriptionGroup/${subscriptionGroup.id}`, subscriptionGroup, HTTPMethod.PUT);

export const removeSubscriptionGroup = (subscriptionGroupId: string) =>
  apiFetch(`/subscriptionGroup/${subscriptionGroupId}`, undefined, HTTPMethod.DELETE);

// Subscription initialFees endpoints
export const getSubscriptionInitialFees = () =>
  apiFetch<SubscriptionInitialFee[]>('/subscriptionInitialFees');

export const addSubscriptionInitialFee = (subscriptionInitialFee: SubscriptionInitialFee) =>
  apiFetch<SubscriptionInitialFee>('/subscriptionInitialFees', subscriptionInitialFee, HTTPMethod.POST);

export const updateSubscriptionInitialFee = (subscriptionInitialFee: SubscriptionInitialFee) =>
  apiFetch<SubscriptionInitialFee>(`/subscriptionInitialFees/${subscriptionInitialFee.id}`, subscriptionInitialFee, HTTPMethod.PUT);

export const deleteSubscriptionInitialFee = (subscriptionInitialFeeId: string) =>
  apiFetch(`/subscriptionInitialFees/${subscriptionInitialFeeId}`, undefined, HTTPMethod.DELETE);