import { AsyncStatus } from '../interfaces';
import { createAction } from './createAction';

export const setAsyncResource = createAction(
  'SET_ASYNC_RESOURCE',
  (
    storePath: (string | number)[],
    status: AsyncStatus,
    data: any
  ) => ({
    storePath,
    status,
    data,
  })
);
