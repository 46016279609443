import { PaymentServiceProviderEnum } from '../collectionInvoices';
import { apiFetch, HTTPMethod, HTTPStatus } from '../fetch';
import { CompanyPaymentSolution, ZuluCard } from './interfaces';

export const getActiveCompanyPaymentSolution = (companyId: string, paymentServiceProvider: PaymentServiceProviderEnum) =>
  apiFetch<CompanyPaymentSolution | { status: number }>(`/companyPaymentSolutions/${companyId}/active?paymentServiceProviderEnum=${paymentServiceProvider}`)
    .then((response) => {
      if ('status' in response && response.status === HTTPStatus.NoContent) {
        return undefined as unknown as CompanyPaymentSolution;
      } else {
        return response as CompanyPaymentSolution;
      }
    });

export const updateAutoDebitForCompanyPaymentSolution = (companyId: string, companyPaymentSolutionId: string, isAutoDebit: boolean) =>
  apiFetch<CompanyPaymentSolution>(`/companyPaymentSolutions/${companyId}/${companyPaymentSolutionId}/IsAutoDebit`, isAutoDebit, HTTPMethod.PUT);

export const getZuluMaskedCardForCompany = (companyId: string): Promise<ZuluCard> =>
  apiFetch(`/companyPaymentSolutions/${companyId}/zuluMaskedCard`, undefined, HTTPMethod.GET);

export const syncCompanyPaymentSolutionsWithPlusius = () =>
  apiFetch('/companyPaymentSolutions/syncWithPlusius', undefined, HTTPMethod.PUT);
