import { useState, useEffect } from 'react';
import { Dropin, create as createDropinInstance } from 'braintree-web-drop-in';
import {
  getPayPalClientToken,
  putPayPalPaymentNonce,
  createPayPalPayment,
} from 'integrations/crossborderit';

export enum BraintreeDropinStatus {
  Error = 'Error',
  Loading = 'Loading',
  Idle = 'Idle',
}
export enum TypeOfCharge {
  TaxAmount = 1,
  TaxExempty = 2,
}

export const useBraintree = (companyId: string, containerSelector: string) => {
  const [status, setStatus] = useState(BraintreeDropinStatus.Loading);
  const [braintreeInstance, setBraintreeInstance] = useState<Dropin>();
  const [isBillingMethodValid, setIsBillingMethodValid] = useState(false);
  const [threeDSecureParameters] = useState(Object);
  const [nonce, setNonce] = useState<string>();

  const updateBillingMethod = () => !braintreeInstance
    ? Promise.reject(new Error('Braintree instance not initialized'))
    : braintreeInstance.requestPaymentMethod()
      .then(payload => putPayPalPaymentNonce(companyId, payload.nonce).then(() => { setNonce(payload.nonce); }));

  const createPayment = (invoiceId: string) => !braintreeInstance
    ? Promise.reject(new Error('Braintree instance not initialized'))
    : braintreeInstance.requestPaymentMethod()
      .then(payload => createPayPalPayment(companyId, payload.nonce, invoiceId).then(() => setNonce(payload.nonce)));

  useEffect(() => {
    let didCancel = false;
    setStatus(BraintreeDropinStatus.Loading);
    const braintreeConfiguration = {
      container: containerSelector,
      //threeDSecure: true,
      // card: false,
      paypal: {
        flow: 'vault' as 'vault', // I don't understand it either
        currency: 'EUR',
      },

    };

    getPayPalClientToken(companyId)
      .then(payPalClientToken => {
        if (didCancel) return;
        setStatus(BraintreeDropinStatus.Idle);
        return createDropinInstance({
          ...braintreeConfiguration,
          authorization: payPalClientToken,
        });
      })
      .then(braintreeInstance => setBraintreeInstance(braintreeInstance))
      .catch(() => {
        if (didCancel) return;
        setStatus(BraintreeDropinStatus.Error);

      });

    return () => {
      didCancel = true;
    };
  }, [containerSelector, companyId, nonce]);

  useEffect(() => {
    if (!braintreeInstance) return;

    if (braintreeInstance?.isPaymentMethodRequestable()) {
      setIsBillingMethodValid(true);
    } else {
      setIsBillingMethodValid(false);
    }
    const enableNextButton = () => setIsBillingMethodValid(true);
    const disableNextButton = () => setIsBillingMethodValid(false);
    braintreeInstance.on('paymentMethodRequestable', enableNextButton);
    braintreeInstance.on('noPaymentMethodRequestable', disableNextButton);

    const unsubscribe = () => {
      if (!braintreeInstance) return;
      braintreeInstance.off('paymentMethodRequestable', enableNextButton);
      braintreeInstance.off('noPaymentMethodRequestable', disableNextButton);
    };

    return unsubscribe;
  }, [braintreeInstance]);

  return {
    status,
    isBillingMethodValid,
    instance: braintreeInstance,
    updateBillingMethod,
    createPayment,
    threeDSecureParameters,
  };

};
