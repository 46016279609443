import { DocumentType } from '.';
import { apiFetch, HTTPMethod } from '../fetch';
import { apiFetchFormData } from '../fetchFormData';
import { Document } from './interface';

export const getDocuments = (companyId?: string) => {
  const url = companyId ? `/documents?companyId=${companyId}` : '/documents';
  return apiFetch<Document[]>(url);
};

export const getDocument = (documentId: string) =>
  apiFetch<Document>(`/documents/${documentId}`);

export const updateDocument = (document: Document) =>
  apiFetch<Document>('/documents', document, HTTPMethod.PUT);

export const uploadDocument = (document: Document, documentType: DocumentType = DocumentType.SalesDocument) => {
  const formData = new FormData();
  formData.append('file', document.upload.file.originFileObj);
  formData.append('name', document.name || '');
  formData.append('description', document.description || '');
  formData.append('documentType', documentType);
  if (document.companyId) formData.append('companyId', document.companyId);
  return apiFetchFormData<Document>('/documents', formData, HTTPMethod.POST);
};

export const deleteDocument = (documentId: string) =>
  apiFetch(`/documents/${documentId}`, undefined, HTTPMethod.DELETE);
