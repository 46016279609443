import {
  putCmsContent,
  postCmsContent,
  CmsContent,
  CmsContentType,
  revokeCmsContent,
  getCmsContentWithTypeForCompany,
  getCmsContentWithTypeForAdmin,
  getCmsContentForAdmin,
  getCmsContentForCompany,
} from 'integrations/crossborderit';
import { useCallback } from 'react';

export const useCms = () => {

  const getCmsContentWithIdForAdmin = useCallback((contentId: string) =>
    getCmsContentForAdmin(contentId), []);

  const getAllCmsContentWithTypeForAdmin = useCallback((contentType: CmsContentType, onlyVisible?: boolean) =>
    getCmsContentWithTypeForAdmin(contentType, onlyVisible), []);

  const getCmsContentWithIdForCompany = useCallback((companyId: string, contentId: string) =>
    getCmsContentForCompany(companyId, contentId), []);

  const getAllCmsContentWithTypeForCompany = useCallback((companyId: string, contentType: CmsContentType) =>
    getCmsContentWithTypeForCompany(companyId, contentType), []);

  const updateCmsContent = (updatedCmsContent: CmsContent) =>
    putCmsContent(updatedCmsContent);

  const createCmsContent = (newCmsContent: CmsContent) =>
    postCmsContent(newCmsContent);

  const inactivateCmsContent = (contentId: string) =>
    revokeCmsContent(contentId);

  const fromUrlToCmsContentType = useCallback((urlContentTypeParam: string) => {
    if (urlContentTypeParam === 'new-features') {
      return CmsContentType.NewFeatures;
    } else if (urlContentTypeParam === 'how-it-works') {
      return CmsContentType.HowItWorks;
    } else if (urlContentTypeParam === 'system-alert') {
      return CmsContentType.SystemAlert;
    }
    else {
      return CmsContentType.NotSelected;
    }
  }, []);

  return {
    getCmsContentWithIdForAdmin,
    getAllCmsContentWithTypeForAdmin,
    getCmsContentWithIdForCompany,
    getAllCmsContentWithTypeForCompany,
    createCmsContent,
    updateCmsContent,
    inactivateCmsContent,
    fromUrlToCmsContentType,
  };
};
