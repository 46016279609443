import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { replaceBy } from 'utility';
import { CollectionInvoiceType } from '.';
import { createInvoiceInERP, getCollectionInvoices, clearCollectionInvoiceErrors } from './api';
import { CollectionInvoice } from './interface';

const collectionInvoicesSelector = ['collectionInvoices'];

export const useCollectionInvoices = () => {

  const {
    data: collectionInvoices = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<CollectionInvoice[]>([], collectionInvoicesSelector));
  const dispatch = useDispatch();

  const loadCollectionInvoices = useCallback((fromDate: string, toDate: string) => {
    dispatch(setAsyncResource(collectionInvoicesSelector, AsyncStatus.Loading, []));
    return getCollectionInvoices(CollectionInvoiceType.Invoice, fromDate, toDate).then(s => {
      dispatch(setAsyncResource(collectionInvoicesSelector, AsyncStatus.Success, s));
    }).catch(() => {
      dispatch(setAsyncResource(collectionInvoicesSelector, AsyncStatus.Error, []));
    });
  }, [dispatch]);

  const sendToErp = (collectionInvoiceId: string) =>
    createInvoiceInERP(collectionInvoiceId).then(ci => {
      const updateCis = replaceBy('id', ci, collectionInvoices);
      dispatch(setAsyncResource(collectionInvoicesSelector, AsyncStatus.Success, updateCis));
      return ci;
    });

  const updateCollectionInvoicesState = (updatedCollectionInvoice: CollectionInvoice) => {
    dispatch(setAsyncResource(collectionInvoicesSelector, AsyncStatus.Success, replaceBy('id', updatedCollectionInvoice, collectionInvoices)));
  };

  return {
    status,
    collectionInvoices,
    loadCollectionInvoices,
    sendToErp,
    clearCollectionInvoiceErrors,
    updateCollectionInvoicesState,
  };
};