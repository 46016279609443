export interface AdvancedFee {
  id: string;
  name: string;
  feeCategory: FeeCategory;
  feeFixedAmount: number;
  feePercentAmount: number;
  minimumAmount: number;
  maximumAmount: number;
  isActive: boolean;
  isDefaultFeeForCategory: boolean;
  createdUtc: string;
  updatedUtc: string;
  createdBy: User;
  updatedBy: User;
}

interface User {
  firstName: string;
  lastName: string;
  fullName: string;
}

export enum FeeCategory {
  None = 0,
  IossOrderEntry = 1,
  ClassificationWISE = 2,
  GoogleTranslation = 3,
  DigitalCustom = 4,
  AiDockClassification = 5,
  ManualClassification = 6,
  InvoiceFee = 7,
}

export interface CompanyAdvancedFee {
  id: string;
  advancedFeeId: string;
  companyId: string;
  advancedFee: AdvancedFee;
}