import { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ApplicationPaths,
  QueryParameterNames,
  CompanyCategory,
  UserRole,
  CompanyUserRole,
  SubscriptionModule,
} from 'integrations/crossborderit';
import { Switch, Case } from 'components/shared/switch';
import { Layout } from 'components/shared/layout';
import {
  Authentication,
  useAuthenticationStatus,
} from './useAuthenticationStatus';
import {
  Authorization,
  useAuthorizationStatus,
} from './useAuthorizationStatus';

interface AuthorizeRouteProps {
  path: string;
  exact?: boolean;
  component: FunctionComponent;
  companyCategories?: CompanyCategory[];
  companyUserRoles?: CompanyUserRole[];
  userRoles?: UserRole[];
  companySubcriptionModules?: SubscriptionModule [],
}

export const AuthorizeRoute = ({
  path,
  userRoles: requiredUserRoles,
  companyUserRoles: requiredCompanyUserRoles,
  companyCategories: requiredCompanyCategories,
  companySubcriptionModules: requiredActiveCompanySubscriptionModules,
  component: Component,
  ...props
}: AuthorizeRouteProps) => {
  const { t } = useTranslation();
  const authenticationStatus = useAuthenticationStatus();

  const authorizationStatus = useAuthorizationStatus({
    requiredUserRoles,
    requiredCompanyUserRoles,
    requiredCompanyCategories,
    requiredActiveCompanySubscriptionModules,
  });

  // TODO: returnUrl came with dotnet when generating project.
  // not sure it actually works as they intended.
  // We might be bale to remove the returnUrl;
  const link = document.createElement('a');
  link.href = path;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const loginUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;

  return (
    <Switch on={authenticationStatus}>
      <Case match={[Authentication.Authenticating]}>
        <Layout>
          <Layout.Header style={{ height: '64px' }} />
          <Layout.Loading loadingLabel={t('Authenticating...')} />
        </Layout>
      </Case>

      <Case match={[Authentication.NotAuthenticated]}>
        <Layout>
          <Layout.Header style={{ height: '64px' }} />
          <Layout.Loading loadingLabel={t('Authenticating...')} />
          <Redirect to={loginUrl} />
        </Layout>
      </Case>

      <Case match={[Authentication.Authenticated]}>
        <Switch on={authorizationStatus}>
          <Case match={[Authorization.Authorizing]}>
            <Layout>
              <Layout.Header style={{ height: '64px' }} />
              <Layout.Loading loadingLabel={t('Authorizing...')} />
            </Layout>
          </Case>

          <Case match={[Authorization.NotAuthorized]}>
            <Layout>
              <Layout.Header style={{ height: '64px' }} />
              <Layout.Loading loadingLabel={t('Authorizing...')} />
              <Redirect to="/" />
            </Layout>
          </Case>

          <Case match={[Authorization.Authorized]}>
            <Route {...props} component={Component} />
          </Case>
        </Switch>
      </Case>
    </Switch>
  );
};
