import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState, selectCompany, selectCompanyUsers } from 'store';
import {
  CompanyCategory,
  UserRole,
  CompanyUserRole,
  SubscriptionModule,
} from 'integrations/crossborderit';
import { isAuthorized } from './isAuthorized';

export enum Authorization {
  Authorizing = 'Authorizing',
  Authorized = 'Authorized',
  NotAuthorized = 'NotAuthorized',
}

export interface UseAuthorizationStatusProps {
  requiredUserRoles?: UserRole[];
  requiredCompanyUserRoles?: CompanyUserRole[];
  requiredCompanyCategories?: CompanyCategory[];
  requiredActiveCompanySubscriptionModules?: SubscriptionModule [];
}

export const useAuthorizationStatus = ({
  requiredUserRoles,
  requiredCompanyUserRoles,
  requiredCompanyCategories,
  requiredActiveCompanySubscriptionModules,
}: UseAuthorizationStatusProps) => {
  const [status, setStatus] = useState(Authorization.Authorizing);
  const { companyId } = useParams<{ companyId: string; }>();
  const { company } = useSelector(selectCompany(companyId));
  const user = useSelector((state: AppState) => state?.user);
  const { users: companyUsers } = useSelector(selectCompanyUsers(companyId));

  useEffect(() => {
    setStatus(Authorization.Authorizing);
    isAuthorized({
      user,
      company,
      companyUsers,
      requiredUserRoles,
      requiredCompanyUserRoles,
      requiredCompanyCategories,
      requiredActiveCompanySubscriptionModules,
    })
      ? setStatus(Authorization.Authorized)
      : setStatus(Authorization.NotAuthorized);
  }, [
    user,
    company,
    companyUsers,
    requiredUserRoles,
    requiredCompanyUserRoles,
    requiredCompanyCategories,
    requiredActiveCompanySubscriptionModules,
  ]);

  return status;
};
