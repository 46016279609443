import { useCallback, useEffect } from 'react';
import { useCrossborderitApi } from '../useCrossborderitApi';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';

const storePath = ['industryNews'];

export const useIndustryNews = () => {
  const { api } = useCrossborderitApi();
  const dispatch = useDispatch();

  const {
    status = AsyncStatus.NotInitialized,
    data: industryNews = [],
  } = useSelector(selectAsyncResource([], storePath));

  const loadIndustryNews = useCallback(() => {
    api('/industry-news')
      .then(industryNews => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        industryNews
      )))
      .catch(() => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Error,
        []
      )));
  }, [api, dispatch]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized) return;
    loadIndustryNews();
  }, [status, loadIndustryNews]);

  return {
    status,
    industryNews,
    loadIndustryNews,
  };

};
