import { CompanyContact, PartialCompany } from '../companies';
import { DynamicsInvoiceType } from '../dynamics';

export enum CollectionInvoiceStatus {
  Draft = 1,
  QuoteCreated = 2,
  PdfCreated = 3,
  SentToCustomer = 4,
  PaidByCustomer = 5,
  Cancelled = 6,
  ProcessingPayment = 7,
  ProcessingRefund = 8,
  ERPCreated = 9,
  CreditCardPaymentStarted = 10,
  PartiallyPaid = 11,
  VATCredit = 12,
  TaxPaymentStarted = 13,
  PaymentCompleted = 14,
  Credit = 15,
}
export enum CollectionInvoiceStatusUserView {
  Pending = 'Pending',
  QuoteCreated = 'Quote Created',
  PdfCreated = 'Not Sent',
  SentToCustomer = 'Unpaid',
  PaidByCustomer = 'Paid',
  Cancelled = 'Cancelled',
  ProcessingPayment = 'Processing Payment',
  ProcessingRefund = 'Processing Refund',
  ERPCreated = 'Not Sent',
  CreditCardPaymentStarted = 'Credit Card Payment Started',
  PartiallyPaid = 'Partially Paid',
  VATCredit = 'VAT Credit Request',
  TaxPaymentStarted = 'Paid',
  PaymentCompleted = 'Payment Completed',
  Credit = 'Credit',
}

export const collectionInvoiceStatusOrder = [
  1,
  12,
  2,
  3,
  9,
  4,
  11,
  10,
  7,
  5,
  8,
  6,
];

export enum CollectionInvoiceType {
  Statement = 1,
  Invoice = 2,
}
export enum PaymentTransactionStatus {
  Submitted = 0,
  Completed = 1,
  Rejected = 2,
  Failed = 3,
  Created = 4,
  Cancelled = 6,
}
enum PaymentAccountType{
  Main = 1,
  Escrow = 2,
}
export enum PaymentServiceProviderEnum {
  None = 0,
  PayPal = 1,
  Plusius = 2,
}
export interface PaymentTransaction {
  id: string;
  collectionInvoiceIds: string[];
  transactionTimestamp: string;
  amount: number;
  vat: number;
  knownFees: number;
  currencyCode: string;
  paymentServiceProvider: PaymentServiceProviderEnum;
  paymentTransactionStatus: PaymentTransactionStatus;
  externalTransactionId: string;
  externalQuoteId: string;
  externalPaymentOptionId: string;
  accountType: PaymentAccountType;
  transactionCompleted: string;
  statusInformation: string;
  comment: string;
  pendingToBeRemoved: boolean;
}

export enum ExternalPaymentTypeEnum {
  None = 0,
  VATAccount = 1,
  STDAccount = 2,
  Shopify = 3,
  Enion = 4,
  PostPlus = 5,
  Plusius = 6,
  Shyplite = 7,
  US = 8,
  Credit = 9,
  SKV = 10,
}

export interface UpdateStatementTransactions{
  collectionInvoiceId: string;
  status: CollectionInvoiceStatus;
  unprocessedTransactions: UnproccessedTransaction[];
  externalTransactions: ExternalPaymentTransaction[];
  transactionToRemove: PaymentTransaction[];
  existingTransactions: PaymentTransaction[];
}

export interface UnproccessedTransaction {
  id: string;
  companyId: string;
  companyName: string;
  amount: number;
  transactionDate: string;
  currency: string;
  platformPaymentReference: string;
  status: string,
  pendingToBeAdded: boolean
}
export interface ExternalPaymentTransaction {
  paymentId: number;
  companyId: string;
  transferRef: string;
  ocr: string;
  amount: number;
  amountCurrency: string;
  pspAmount: number;
  pspCurrency: string;
  paymentDate: string;
  registredDate: string;
  paymentType: ExternalPaymentTypeEnum;
  pendingToBeAdded: boolean;
}

export interface MigrateCollectionInvoice extends CollectionInvoice {
  externalTransactions: ExternalPaymentTransaction[];
  unprocessedTransactions: UnproccessedTransaction[];
  existingTransactionToAdd: PaymentTransaction[];
}

export interface CollectionInvoice {
  id: string;
  company: PartialCompany;
  companyName: string;
  companyContact: CompanyContact;
  settlementAmount: number;
  settlementCurrency: string;
  settlementVatAmount?: number;
  collectionAmount?: number;
  collectionCurrency?: string;
  dueDate: string;
  status: CollectionInvoiceStatus;
  externalPaymentReference: string;
  internalPaymentReference: string;
  createdUtc: string;
  sentUtc: string;
  sentTo: string;
  invoiceNumber: string;
  errors: ErrorEntry[];
  invoiceType: CollectionInvoiceType;
  pdfDocumentId?: string;
  paymentTransactions: PaymentTransaction[];
  hasCompanyNotes: boolean;
  taxAgencyPaymentDate: string;
  quoteId?: string;
  dynamicsInvoiceType?: DynamicsInvoiceType;
  companyIsActive?: boolean;
  reminderFeeInvoiceCreationCancelled: boolean;
  reminderFeeInvoiceCreated: boolean;
  invoiceRows?: CollectionInvoiceRow[];
}

export interface CollectionInvoiceRow {
  id: string,
  articleNumber: string,
  amount: number,
  text: string,
}

interface ErrorEntry {
  message: string,
  statusCode: number,
}

export interface DynamicsCreationInfo {
  count: number;
  doneProcessing: boolean;
}

export interface InitiatePaymentResult {
  redirectUrl: string;
  collectionAmount: number;
  collectionCurrency: string;
  feeInCollectionCurrency: number;
  externalPaymentReference?: string;
}

export interface InitiatePayoutResult {
  successCount: number;
  initiatePayoutRows: InitiatePayoutRow[];
}

export interface InitiatePayoutRow {
  collectionInvoiceId: string;
  internalPaymentReference: string;
  message: string;
}

export interface PaymentTransactionListItem extends PaymentTransaction {
  externalStatus: string;
  externalStatusMessage: string;
  plusiusStatusResponse?: PlusiusStatusResponse;
}

export interface PlusiusStatusResponse {
  quoteId: string;
  paymentOptionId: string;
  amount: number;
  currency: string;
  status: string;
  errors?: ErrorEntry[];
  errorDetail?: PlusiusErrorDetail;
}

export interface PlusiusErrorDetail {
  failedAt: string;
  name: string;
  description: string;
}
