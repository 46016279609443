export interface CrmAction {
  id: string,
  companyId: string,
  addedUtc: string,
  addedByFullName: string,
  actionType: CrmActionType,
  note: string,
  actionUtc: string,
  completedUtc?: string,
  rejectedUtc?: string,
  assignedToId: string,
  assignedToFullName: string,
  companyName: string,
  performedUtc?: string,
  completed?:boolean,
}

export enum CrmActionType {
  Meeting = 'Meeting',
  Phonecall = 'Phonecall',
  Email = 'Email',
  Other = 'Other',
}
