import { FunctionComponent, ReactNode, CSSProperties } from 'react';
import { Text } from '@fluentui/react';

export interface GuideProps {
  title: ReactNode;
  text?: ReactNode;
  extra?: ReactNode;
  style?: CSSProperties;
}

export const Guide: FunctionComponent<GuideProps> = ({
  title,
  text,
  extra,
  style = {},
  ...props
}) => (
  <div
    {...props}
    style={{
      maxWidth: '400px',
      textAlign: 'center',
      padding: '48px 0',
      ...style,
    }}
  >
    <Text variant="xLarge" style={{ marginBottom: '8px' }} block>
      {title}
    </Text>
    <Text block style={extra ? { marginBottom: '24px' } : {}}>
      {text}
    </Text>
    {extra}
  </div>
);
