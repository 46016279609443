export interface IossImportDataEntryModel {
  id: string;
  createdTimeStamp: string;
  rowIndex: number;
  trackingNumber: string;
  orderNumber: string;
  iossNumber: string;
  transactionDate: string;
  currency: string;
  saleValue: number;
  vatAmount: number;
  saleValueInEuro: number;
  vatAmountInEuro: number;
  vatRate: number;
  importType: string;
  sourceCountryCode: string;
  destinationCountryCode: string;
  goodsType: any;
  isCorrection: boolean;
  originalTransactionDate: string;
  hasRefund: boolean;
  refundEntryId?: string;
}

export interface ImportFileListCollection {
  fileId: string;
  uploadTimestamp: string;
  importedBy: string;
  rowCount: number;
  failedRowCount: number;
  documentUrl: string;
  cannotBeDeleted: boolean;
  iossFailedToImportItems: IossFailedToImportItem[];
  filename: string;
  uploadedWithApi: boolean;
  failedToImport: boolean;
  containsCorrectionEntries: boolean;
  containsRefundedEntries: boolean;
}

export interface IossFailedToImportItem {
  rowIndex: number;
  orderNumber: number;
  errorMessages: string[];
}

export interface IossValidationItem {
  orderNumber: string;
  errorCode: IossValidationErrorCode;
  severity: number;
}

export enum IossValidationErrorCode {
  IncorrectVat = 'INCORRECT_VAT_APPLIED',
}

export interface IossOrderRefund {
  iossOriginalOrderId: string;
  uploadedFile?: File;
  iossOrderRefundReason?: string;
}

export interface IossOrderRefundDetails {
  iossOrderRefundReason?: string;
  uploadedFileName?: File;
  documentUrl?: string;
}