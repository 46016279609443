import { apiFetch, HTTPMethod } from '../fetch';
import { Message } from './interfaces';

export const getAllMessagesForUser = (): Promise<Message[]> =>
  apiFetch<Message[]>('/user/messages');

export const putMarkMessageAsRead = (messageId: string) =>
  apiFetch(`/user/messages?messageId=${messageId}`, undefined, HTTPMethod.PUT);

export const getNumberOfUnreadMessages = (): Promise<number> =>
  apiFetch('/user/messages/count/');
