export interface Product {
  id: string;
  companyId: string;
  name: string; // MaxLength 255
  description: string; // MaxLength 1000
  articleNumber: string; // MaxLength 100, The identity used by the company that the product belongs to.
  productCondition: ProductCondition; // Will be set to "New" if it does not contain a legal value
  shipmentOriginCountryCode?: string; // If set to null, the property of the owning company will be used instead
  manufacturedInCountryCode?: string; // Needed for calculation. If set to null, the property of the owning company will be used instead
  currencyCode?: string; // If set to null, the property of the owning company will be used instead
  itemCost: number; // Decimal, The price of one item of this product.
  merchantName: string; // MaxLength 255
  commodityType: string; // MaxLength 255
  brandOrManufacturer: string; // MaxLength 255
  productUrl: string; // MaxLength 255
  imageUrl: string; // MaxLength 255
  countryOfOriginExportClassificationCode: string; // MaxLength 100
  gtin?: string; // GlobalTradeItemNumber
  sku?: string; // StockKeepingUnit
  ean?: string; // EuropeanArticleNumber, can also be called International Article Number
  upc?: string; // UniversalProductCode
  mpn?: string; // ManufacturerPartNumber
  asin?: string; // AmazonStandardIdentificationNumber
  apn?: string; // AustralianProductNumber
  isbn?: string; // InternationalStandardBookNumber
  readonly lastUpdated: string; // Will be updated by back end.
  classificationStatus?: ProductClassificationStatus;
  productClassifications?: Classification[];
  assignedToFullName?: string;
  assignedToId?: string;
  confidence?: number;
  hsCodeSuggestionByAI?: string;
  aiTranslated: boolean;
}

// PartialProduct is used in the product lists where we don't need all the information
export interface PartialProduct {
  id: string;
  companyId: string;
  articleNumber: string;
  name?: string;
  description?: string;
  commodityType?: string;
  confidence?: number;
  hsCode?: string;
  hsCodeValidationStatus?: HsCodeValidationStatus;
  hsCodeValidationStatusUtcDate?: string;
  hsCodeId?: string;
  hsCode2?: string;
  hsCode2ValidationStatus?: HsCodeValidationStatus;
  hsCode2ValidationStatusUtcDate?: string;
  hsCode2Id?: string;
  hsCode3?: string;
  hsCode3ValidationStatus?: HsCodeValidationStatus;
  hsCode3ValidationStatusUtcDate?: string;
  hsCode3Id?: string;
  hsCodeSuggestionByAI?: string;
  hsCodeTrade6?: string;
  imageUrl?: string;
  productUrl?: string;
  readonly lastUpdated: string;
  readonly updatedBy: string;
  shipmentOriginCountryCode?: string;
  classificationStatus?: ProductClassificationStatus;
  hsCodeTradeTypeExport?: boolean;
  hsCodeTradeTypeImport?: boolean;
  hsCode2TradeTypeExport?: boolean;
  hsCode2TradeTypeImport?: boolean;
  hsCode3TradeTypeExport?: boolean;
  hsCode3TradeTypeImport?: boolean;
  assignedToFullName?: string;
  assignedToId?: string;
  aiTranslated: boolean;
  trade6hsCode?: string;
  trade6ValidationStatus?: HsCodeValidationStatus;
  trade6ValidationStatusUtcDate?: string;
  trade6Id?: string;
  created: string;
  dutyRate?: number;
  taxRate?: number;
  netWeightInKg?: number;
  grossWeightInKg?: number;
  manufacturedInCountry?: string;
  brandOrManufacturer?: string;
  ean?: string;
}

export const countryCodesPossibleToValidate = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'NO',
];

export enum HsCodeValidationStatus {
  NotValidated = 0,
  Invalid = 1,
  Valid = 2,
  UnableToValidate = 3, //if the hs code data does not meet the pre requirements
  ValidationError = 4,
  Restricted = 5,
}

export enum ProductCondition {
  New = 'New',
  Used = 'Used',
  Refurbished = 'Refurbished',
}

export enum ProductQuerySearchMode {
  Equals = 'EQUAL',
  Contains = 'CONTAINS',
}

export interface ProductsQuery {
  importId?: string;
  country1?: string;
  country2?: string;
  country3?: string;
  filter?: ProductFilter;
  sortingColumn?: string;
  sortDescending?: boolean;
  pageIndex?: number;
  pageSize?: number;
  totalCount?: number;
  totalPages?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface Search {
  textToFind?: string;
  columnToSearch?: string;
  searchMode?: ProductQuerySearchMode;
}

export enum ProductFilter {
  All,
  Classified,
  NotClassified,
}

export interface ProductListContainerModel {
  productList: PartialProduct[];
  totalNumberOfMatchingEntriesInDb: number;
}

export interface Classification {
  id?: string;
  productId: string;
  countryCode: string;
  hsCode?: string;
  importProhibited?: boolean;
  importRestricted?: boolean;
  readonly classificationMode?: string; // information about how the classification was made.
  isTrade6HsCode?: boolean;
  dutyRate?: number;
  taxRate?: number;
}

export interface PartialClassification {
  id: string;
  countryCode: string;
  hsCode: string;
  dutyRate: number;
  taxRate: number;
}

export interface ProductBulkClassification {
  countryCode?: string;
  hsCode: string;
  productId: string;
  isTrade6HsCode?: boolean;
}

export interface ProductBulkClassificationResponse {
  productDetails: ProductBulkClassificationResponseEntry[];
  countryCode: string;
  isTrade6HsCode?: boolean;
}

export interface ProductBulkClassificationResponseEntry {
  productId: string;
  hsCode: string;
  lastUpdated: string;
  classificationStatus: ProductClassificationStatus;
  validationStatus: HsCodeValidationStatus;
  validationStatusDate: string;
  productClassificationId: string;
}

export enum ProductClassificationStatus {
  New = 0,
  ProductUpdateRequired = 1,
  Pending = 2,
  Approved = 3,
  AiClassified = 4,
}

export interface UpdateStatuses {
  UpdateParams: {
    Id: string;
    ClassificationStatus: ProductClassificationStatus;
  }[];
}

export interface UpdatedStatus {
  id: string;
  classificationStatus: ProductClassificationStatus;
  classificationStatusDate: string;
  lastUpdated: string;
  updatedBy: string;
}

export interface ValidateHsCodesAsyncResponse {
  countryCode: string;
  hsCode: string;
  productClassificationId: string;
  tradeTypeExport?: boolean;
  tradeTypeImport?: boolean;
  validationStatus: HsCodeValidationStatus;
  validationStatusDate: string;
  productId: string;
}

export interface ProductListInfoHsCodeItem {
  countryCode: string;
  hsCode: string;
  productClassificationId: string;
  isGlobalHsCode: boolean;
}

export interface SaveValidation {
  ProductClassificationId: string;
  HsCode: string;
  CountryCode: string;
  TradeTypeImport?: boolean;
  TradeTypeExport?: boolean;
  ValidationStatus: HsCodeValidationStatus;
  ValidationStatusDate: string;
}

export interface HsCodeValidationItem {
  HsCode: string;
  CountryCode?: string;
  TradeTypeImport: boolean;
  TradeTypeExport: boolean;
  ProductId: string;
  IsGlobalHsCode?: boolean;
}

export interface ProductClassification {
  countryCode: string;
  created: string;
  createdBy: string;
  hsCode: string;
  id: string;
  importProhibited: boolean;
  importRestricted: boolean;
  lastUpdated: string;
  productId: string;
  tradeTypeExport: boolean;
  tradeTypeImport: boolean;
  updatedBy: string;
  validationStatus: HsCodeValidationStatus
  validationStatusDate?: string;
}

export interface HsCodeValidationBatch {
  id: string;
  addedUtc: string;
  companyId: string;
  processedUtc?: string;
  productClassifications: ProductClassification[]
}

export interface ProductClassificationFromAiQuery {
  countryCodesToUpdate?: string;
  allCountryCodes: string[];
}