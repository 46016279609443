export interface CreateApiKey {
  lifeTime: number;
  description: string;
  apiAccess: ApiAccess[];
}

export interface ApiKey {
  id: string;
  companyId: string;
  addedUtc: string;
  expiresUtc: string;
  revokedUtc: string;
  token: string;
  description: string;
  apiAccess: ApiAccess[];
  managedBySystem: boolean;
  isInactive: boolean;
}

export enum ApiAccess {
  Collections = 'Collections',
  Calculations = 'Calculations',
  Ioss = 'Ioss',
  Classification = 'Classification',
  DigitalCustoms = 'DigitalCustoms',
  Manifests = 'Manifests',
  OrderImport = 'OrderImport',
  ClassificationBasic = 'ClassificationBasic',
}
