import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/shared/layout';
import { loadAppData, selectAppStatus, AsyncStatus } from 'store';
import { ErrorResult } from 'components/shared/result';
import { Switch, Case } from 'components/shared/switch';
import { Header } from 'components/header';
import { ApplicationPaths } from 'integrations/crossborderit/';

export const AppDataLoader: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(selectAppStatus);

  useEffect(() => {
    if (status === AsyncStatus.NotInitialized) dispatch(loadAppData());
  }, [dispatch, status]);

  return (
    <Switch on={status}>
      <Case match={[AsyncStatus.Error]}>
        <Layout style={{ height: '100%' }}>
          <Layout.Header>
            <Header />
          </Layout.Header>

          <Layout.Content withSidebar={false}>
            <div
              style={{
                height: '60%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ErrorResult
                title={t('Something went wrong')}
                subTitle={t(
                  'We could not load your workspace. Try again in a few minutes'
                )}
              />
            </div>
          </Layout.Content>
        </Layout>
      </Case>

      <Case match={[AsyncStatus.NotAuthenticated]}>
        <Layout style={{ height: '100%' }}>
          <Layout.Header>
            <Header />
          </Layout.Header>
          <Layout.Loading loadingLabel={t('Redirecting to login...')} />
          <Redirect to={ApplicationPaths.Login} />
        </Layout>
      </Case>

      <Case match={[AsyncStatus.Loading, AsyncStatus.NotInitialized]}>
        <Layout>
          <Layout.Header>
            <Header />
          </Layout.Header>
          <Layout.Loading loadingLabel={t('Starting Cbit...')} />
        </Layout>
      </Case>

      <Case match={[AsyncStatus.Success]}>{children}</Case>
    </Switch>
  );
};
