import { apiFetch, HTTPMethod } from '../fetch';
import { apiFetchFormData } from 'integrations/crossborderit/fetchFormData';
import { WebShopPluginModel, NewWebShopPlugin } from './interfaces';
import { UploadFile } from 'antd/lib/upload/interface';

export const getAllWebShopPlugins = () =>
  apiFetch<WebShopPluginModel[]>('/Admin/Plugins');

export const getLatestWebShopPlugins = () =>
  apiFetch<WebShopPluginModel[]>('/Admin/Plugins/Latest');

export const postWebShopPlugin = (newWebShopPlugin: NewWebShopPlugin, fileList: UploadFile[]): Promise<WebShopPluginModel> => {
  const formData = new FormData();
  formData.append('file', fileList[0].originFileObj as Blob);
  formData.append('webShopType', newWebShopPlugin.webShopType);
  formData.append('version', newWebShopPlugin.version);
  formData.append('description', newWebShopPlugin.description);
  return apiFetchFormData('/Admin/Plugins', formData, HTTPMethod.POST);
};

export const putWebShopPlugin = (webShopPluginModel : WebShopPluginModel
) =>
  apiFetch('/Admin/Plugins', webShopPluginModel, HTTPMethod.PUT);

export const deleteWebShopPlugin = (
  webShopPluginId: string
) =>
  apiFetch(
    `/Admin/Plugins/${webShopPluginId}`,
    undefined,
    HTTPMethod.DELETE
  );
