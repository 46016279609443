import { ComponentType, FC } from 'react';

export interface ConditionalRenderingProps {
  show?: boolean | string;
}

const withConditionalRendering = <T extends ConditionalRenderingProps>(
  Comp: ComponentType<T>
): FC<T> => ({ show = true, ...props }: T) => show ? <Comp {...(props as T)} /> : null;

export default withConditionalRendering;
