import { PhoneType } from '..';

export enum CompanyUserRole {
  CompanyAdmin = 'CompanyAdmin',
  User = 'User',
}

export interface CompanyUser {
  id?: string;
  userId?: string;
  companyId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phones?: Phone[];
  workTitle?: string;
  companyUserRoles?: CompanyUserRole[];
  inheritedUserRoles?: CompanyUserRole[];
  effectiveUserRoles?: CompanyUserRole[];
  inheritCompanyUserRole?: boolean;
  lastLoggedInUtc?: string;
  revokedAt?: string;
}

interface Phone{
  id?: string;
  companyId?: string;
  phoneType?: PhoneType;
  number?: string;
}