import { apiFetch, HTTPMethod } from '../fetch';
import {
  IossInvoiceJobInvoice,
  IossInvoiceJob,
  IossInvoiceJobStatus,
} from './interfaces';

export const startIossInvoiceJob = (endDate: string, jobType: number) =>
  apiFetch(`/iossJob/invoicing?endDate=${endDate}&iossJobType=${jobType}`, null, HTTPMethod.PUT);

export const getIossInvoiceJobStatus = (): Promise<IossInvoiceJobStatus> =>
  apiFetch('/iossJob/invoicing');

export const getIossInvoiceJobs = (): Promise<IossInvoiceJob[]> =>
  apiFetch('/iossJob/list');

export const deleteIossInvoiceJob = (jobId: string): Promise<IossInvoiceJob[]> =>
  apiFetch(`/iossJob/${jobId}`, null, HTTPMethod.DELETE);

export const getIossInvoiceJobInvoices = (
  jobId: string
): Promise<IossInvoiceJobInvoice[]> => apiFetch(`/iossJob/${jobId}/invoiceList`);

export const getIossInvoiceJobInvoiceText = (
  jobId: string,
  invoiceId: string
): Promise<string> => apiFetch(`/iossJob/${jobId}/invoicePreview/${invoiceId}`);

export const getIossInvoiceJobInvoicesText = (
  jobId: string
): Promise<string> => apiFetch(`/iossJob/${jobId}/invoicePreviewAll?compact=true`);
