import { SuccessResult } from '../../components/shared/result/SuccessResult';
import { useTranslation } from 'react-i18next';

export const MerchantOnboardingSuccess = () => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '100%' }}>
      <SuccessResult
        title={t('Your company has been successfully registered')}
        subTitle={
          <span style={{ maxWidth: '600px', display: 'inline-block' }}>
            {t(`
          You will shortly receive an email with a link for completing the onboarding.
          If you don't find the email, please check your spam mail folder.
        `)}
          </span>
        }
      />
    </div>
  );
};
