import {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { E164Number } from 'libphonenumber-js/types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import './InternationalPhoneNumber.css';
import { ValidateStatus } from 'antd/lib/form/FormItem';

interface FormProps {
  name?: (string | number)[];
  fieldKey?: (string | number)[];
  label?: string;
  required?: boolean;
  requireValidFormat?: boolean;
  allowClear?: boolean;
  noStyle?: boolean;
  placeholder?: string;
  disabled?: boolean,
  getValue?: (value: string | undefined) => void,
  isValidFormat?: (value: boolean) => void,
  hasFeedBack?: boolean,
  validationStatus?: ValidateStatus,
}

interface InputProps {
  placeholder?: string;
  disabled?: boolean,
  onChange?: (value: string | undefined) => void;
  onBlur?:() => void;
  value?: string;
}

export const InternationalPhoneNumber: FunctionComponent<FormProps> = ({
  name = ['phone'],
  fieldKey = name,
  required = true,
  requireValidFormat = true,
  noStyle,
  label,
  disabled,
  placeholder,
  getValue = () => {},
  isValidFormat = () => {},
  hasFeedBack = false,
  validationStatus = '',
}) => {
  const { t } = useTranslation();
  const [validateTriggerType, setValidateTriggerType] = useState<string>('onBlur');

  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
      fieldKey={fieldKey}
      label={label}
      validateTrigger={validateTriggerType}
      rules={[
        {
          required: required,
          message: t('Please enter phone number with country calling code'),
        },
        {
          validator: (_, value) => {
            isValidFormat(requireValidFormat && value && isValidPhoneNumber(value));
            getValue(value);
            return (requireValidFormat && value && !isValidPhoneNumber(value))
              ? Promise.reject(
                new Error(
                  t('Phone number seems to be incorrect. Please enter complete number, including the country code.')
                )
              )
              : Promise.resolve();
          },
        },
      ]}
      hasFeedback={hasFeedBack}
      validateStatus={validationStatus}
    >
      <PhoneInputComponent onBlur={() => setValidateTriggerType('onChange')} disabled={disabled} placeholder={placeholder} onChange={(value) => { getValue(value); isValidFormat(isValidPhoneNumber(value || '')); }}/>
    </Form.Item>
  );
};

const PhoneInputComponent: FunctionComponent<InputProps> = ({
  disabled = false,
  placeholder,
  onChange,
  onBlur,
  value,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<E164Number | undefined>();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (initialLoad && value) {
      if (!isValidPhoneNumber(value) && !parsePhoneNumber(value.toString())?.countryCallingCode) {
        // To make sure that already entered invalid phone numbers works in the field.
        setInputValue(`+0${value}`);
      } else if (isValidPhoneNumber(value)) {
        // To make sure that the form.item gets the parsed value from the field on load.
        onChange!(parsePhoneNumber(value.toString())?.number.toString());
      }
      setInitialLoad(false);
    }
  }, [value, initialLoad, onChange]);

  const onChangePhoneNumber = (value: E164Number | undefined) => {
    setInputValue(value);
    onChange!(value && parsePhoneNumber(value.toString())?.number.toString());
  };

  return (
    <PhoneInput
      disabled={disabled}
      international
      value={inputValue ?? value}
      focusInputOnCountrySelection={true}
      countryCallingCodeEditable={true}
      placeholder={placeholder && t(placeholder)}
      onChange={onChangePhoneNumber}
      onBlur={onBlur}
    />
  );
};

