import {
  Company,
  Currency,
  User,
  AppVersion,
  CompanyUser,
  PageOptions,
  Invitation,
  authService,
  getCompaniesUserCanAccess,
  getCurrencies,
  getAppVersion,
  getPageOptions,
  getCompanyInvitations,
  getCompany,
  getCompanyUsers,
  CompanyContact,
} from 'integrations/crossborderit';
import { BreadcrumbItem } from 'components/header';
import { createAction, AppThunk } from './createAction';
import { AsyncStatus } from '../interfaces';
import { getContacts } from 'integrations/crossborderit/contacts';
import { getNumberOfCorrectionsForCompany } from 'integrations/crossborderit/digitalCustoms/api';

export * from './agreements';
export * from './setAsyncResource';

export const loadAppData = createAction(
  'LOAD_APP_DATA',
  (): AppThunk => (dispatch) => {
    dispatch(setAppData(AsyncStatus.Loading));
    return Promise.all([
      authService.getUser(),
      getCompaniesUserCanAccess(),
      getCurrencies(),
      getAppVersion(),
      getPageOptions(),
    ])
      .then((appData) => dispatch(setAppData(AsyncStatus.Success, appData)))
      .catch((error) => {
        error.status === AsyncStatus.NotAuthenticated
          ? dispatch(setAppData(AsyncStatus.NotAuthenticated))
          : dispatch(setAppData(AsyncStatus.Error));
      });
  }
);

type AppData = [
  Company[],
  User,
  Currency[],
  AppVersion,
  PageOptions,
];

export const setAppData = createAction(
  'SET_APP_DATA',
  (
    status: AsyncStatus,
    [
      user,
      companiesUserCanAccess,
      currencies,
      appVersion,
      pageOptions,
    ]: AppData = [] as unknown as AppData
  ) => ({
    status,
    companiesUserCanAccess:{status:AsyncStatus.Success, data:companiesUserCanAccess},
    user,
    currencies,
    appVersion,
    pageOptions,
  })
);

export const setPageOptions = createAction(
  'SET_PAGE_OPTIONS',
  (status: AsyncStatus, pageOptions: PageOptions) => ({
    status,
    pageOptions,
  })
);

export const saveIsSidebarCollapsed = createAction(
  'SAVE_IS_SIDEBAR_COLLAPSED',
  (isCollapsed: boolean): AppThunk =>
    (dispatch) => {
      dispatch(savedIsSidebarCollapsed(isCollapsed));
      localStorage.setItem('isSidebarCollapsed', JSON.stringify(isCollapsed));
    }
);

export const savedIsSidebarCollapsed = createAction(
  'SAVED_IS_SIDEBAR_COLLAPSED',
  (isCollapsed: boolean) => ({ isCollapsed })
);

export const setBreadcrumbs = createAction(
  'SET_BREADCRUMBS',
  (breadcrumbs: BreadcrumbItem[]) => ({ breadcrumbs })
);

export const loadAdminData = createAction(
  'LOAD_ADMIN_DATA',
  (): AppThunk => (dispatch) => {
    dispatch(setAdminData(AsyncStatus.Loading));
    return getCompanyInvitations()
      .then((invitations) => {
        dispatch(setAdminData(AsyncStatus.Success, invitations));
      })
      .catch((error) => {
        dispatch(setAdminData(AsyncStatus.Error));
        return Promise.reject(error);
      });
  }
);

export const setAdminData = createAction(
  'SET_ADMIN_DATA',
  (status: AsyncStatus, companyInvitations: Invitation[]) => ({
    status,
    companyInvitations,
  })
);

export const loadCompany = createAction(
  'LOAD_COMPANY',
  (companyId: string): AppThunk =>
    (dispatch) => {
      dispatch(setCompany(companyId, AsyncStatus.Loading));
      dispatch(setCompanyUsers(companyId, AsyncStatus.Loading));
      dispatch(setCompanyContacts(companyId, AsyncStatus.Loading));
      dispatch(setNumberOfCompanyTestCorrections(companyId, AsyncStatus.Loading));
      dispatch(setNumberOfCompanyCorrections(companyId, AsyncStatus.Loading));
      return Promise.all([getCompany(companyId), getCompanyUsers(companyId), getContacts(companyId), getNumberOfCorrectionsForCompany(companyId, true), getNumberOfCorrectionsForCompany(companyId)])
        .then(([company, users, contacts, numberOfTestConsolidations, numberOfConsolidations]) => {
          dispatch(setCompany(companyId, AsyncStatus.Success, company));
          dispatch(setCompanyUsers(companyId, AsyncStatus.Success, users));
          dispatch(setCompanyContacts(companyId, AsyncStatus.Success, contacts));
          dispatch(setNumberOfCompanyTestCorrections(companyId, AsyncStatus.Success, numberOfTestConsolidations));
          dispatch(setNumberOfCompanyCorrections(companyId, AsyncStatus.Success, numberOfConsolidations));
        })
        .catch((error) => {
          dispatch(setCompany(companyId, AsyncStatus.Error));
          dispatch(setCompanyUsers(companyId, AsyncStatus.Error));
          dispatch(setCompanyContacts(companyId, AsyncStatus.Error));
          dispatch(setNumberOfCompanyTestCorrections(companyId, AsyncStatus.Error));
          dispatch(setNumberOfCompanyCorrections(companyId, AsyncStatus.Error));
          return Promise.reject(error);
        });
    }
);

export const setCompany = createAction(
  'SET_COMPANY',
  (companyId: string, status: AsyncStatus, company: Company) => ({
    status,
    companyId,
    company,
  })
);

export const loadCompanyUsers = createAction(
  'LOAD_COMPANY_USERS',
  (companyId: string): AppThunk =>
    (dispatch) => {
      dispatch(setCompanyUsers(companyId, AsyncStatus.Loading));
      return getCompanyUsers(companyId)
        .then((users) =>
          dispatch(setCompanyUsers(companyId, AsyncStatus.Success, users))
        )
        .catch((error) => {
          dispatch(setCompanyUsers(companyId, AsyncStatus.Error));
          return Promise.reject(error);
        });
    }
);

export const setCompanyUsers = createAction(
  'SET_COMPANY_USERS',
  (companyId: string, status: AsyncStatus, users: CompanyUser[]) => ({
    status,
    companyId,
    users,
  })
);

export const addCompanyUser = createAction(
  'ADD_COMPANY_USER',
  (companyId: string, user: CompanyUser) => ({ companyId, user })
);

export const replaceCompanyUser = createAction(
  'REPLACE_COMPANY_USER',
  (companyId: string, user: CompanyUser) => ({ companyId, user })
);

export const deleteCompanyUser = createAction(
  'DELETE_COMPANY_USER',
  (companyId: string, userId: string) => ({ companyId, userId })
);

export const loadCompanyContacts = createAction(
  'LOAD_COMPANY_CONTACTS',
  (companyId: string): AppThunk =>
    (dispatch) => {
      dispatch(setCompanyContacts(companyId, AsyncStatus.Loading));
      return getContacts(companyId)
        .then((contacts) => {
          dispatch(setCompanyContacts(companyId, AsyncStatus.Success, contacts));
        }
        )
        .catch((error) => {
          dispatch(setCompanyContacts(companyId, AsyncStatus.Error));
          return Promise.reject(error);
        });
    }
);

export const setCompanyContacts = createAction(
  'SET_COMPANY_CONTACTS',
  (companyId: string, status: AsyncStatus, contacts: CompanyContact[]) => ({
    status,
    companyId,
    contacts,
  })
);

export const addCompanyContact = createAction(
  'ADD_COMPANY_CONTACT',
  (companyId: string, contact: CompanyContact) => ({ companyId, contact })
);

export const replaceCompanyContact = createAction(
  'REPLACE_COMPANY_CONTACT',
  (companyId: string, contact: CompanyContact) => ({ companyId, contact })
);

export const deleteCompanyContact = createAction(
  'DELETE_COMPANY_CONTACT',
  (companyId: string, contactId: string) => ({ companyId, contactId })
);

export const decreaseNumberOfCompanyTestCorrections = createAction(
  'DECREASE_NUMBER_OF_COMPANY_TEST_CONSOLIDATIONS_TO_DO', (companyId: string, amount) => ({companyId, amount})
);

export const decreaseNumberOfCompanyCorrections = createAction(
  'DECREASE_NUMBER_OF_COMPANY_CONSOLIDATIONS_TO_DO', (companyId: string, amount) => ({companyId, amount})
);

export const setNumberOfCompanyTestCorrections = createAction(
  'SET_NUMBER_OF_COMPANY_TEST_CONSOLIDATIONS_TO_DO',
  (companyId: string, status: AsyncStatus, consolidationCount: number) => ({
    status,
    companyId,
    consolidationCount,
  })
);

export const setNumberOfCompanyCorrections = createAction(
  'SET_NUMBER_OF_COMPANY_CONSOLIDATIONS_TO_DO',
  (companyId: string, status: AsyncStatus, consolidationCount: number) => ({
    status,
    companyId,
    consolidationCount,
  })
);