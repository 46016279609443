import { FunctionComponent, useEffect, useState } from 'react';
import { AsyncStatus } from 'store';
import { Switch, Case } from 'components/shared/switch';
import { useCountries, Country as CountryType } from 'integrations/crossborderit';

interface Props {
  mode?: 'name' | 'alpha2Code' | 'both';
  countryCode?: string;
}

export const Country: FunctionComponent<Props> = ({
  mode = 'name',
  countryCode,
}) => {

  const { selectCountryBy, status: countryStatus } = useCountries();
  const [country, setCountry] = useState<CountryType>();

  useEffect(() => {
    if (countryStatus === AsyncStatus.Success) {
      setCountry(selectCountryBy('alpha2Code', countryCode || ''));
    }
  }, [countryStatus, countryCode, selectCountryBy]);

  return !countryCode || !country ? (
    <></>
  ) : (
    <Switch on={mode}>
      <Case match={['name']} component={<>{country?.name}</>} />
      <Case match={['alpha2Code']} component={<>{country?.alpha2Code}</>} />
      <Case
        match={['both']}
        component={
          <>
            {country?.name} ({country?.alpha2Code})
          </>
        }
      />
    </Switch>
  );
};
