export interface ProductExportOptions {
  separator: Separator;
  numberFormattingOption: NumberFormat;
}

export interface ProductExportPreview {
  numberOfProducts: number;
  // List which countries that at least one product has been classified for
  classificationCountries: string[];
  /// Number of products with classification information for a country
  /// This list has the same order as the Countries list above
  numberOfProductsClassifiedForCountry: number[];
  // Oldest updated timestamp that will be exported
  oldestDate: string;
  // Newest updated timestamp that will be exported
  newestDate: string;
}

export enum Separator {
  Comma = ',',
  Semicolon = ';',
}

export enum NumberFormat {
  DecimalPoint = 0,
  DecimalComma = 1,
}
