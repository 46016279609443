export interface PayoutReport {
  id: string;
  periodReference: string;
  sentUtc?: string;
  sentBy: string;
  createdUtc: string;
  createdBy: string;
  numberOfPayouts: number;
  totalPayoutAmount: number;
  payouts: Payout[];
}

export interface Payout {
  id: string;
  payoutStatus: PayoutStatus;
  skvReference: string;
  quoteId: string;
  collectionInvoiceId: string;
  internalPaymentReference: string;
  addedUtc: string;
  addedBy: string;
  sentUtc?: string;
  completedUtc?: string;
  payoutAmount: number;
  IossNumber: string;
  companyName: string;
  errors: Errors[];
}

interface Errors {
  message: string;
  createdUtc: string;
}

export enum PayoutStatus {
  Added = 0,
  Sent = 1,
  Failed = 2,
  Completed = 3,
}