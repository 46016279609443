import { FunctionComponent, CSSProperties } from 'react';

export interface LayoutSidebarProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: CSSProperties;
}

export const LayoutSidebar: FunctionComponent<LayoutSidebarProps> = ({
  as: Element = 'div',
  style = {},
  ...props
}) => <Element style={{ ...style, gridArea: 'sidebar', overflowY:'auto' }} {...props} />;
