export interface IossInvoiceJobStatus {
  message: string | null;
  totalNumberOfEntries: number;
  totalNumberOfEntriesWithProblems: number;
  currentEntry: number;
  state: number;
  stateName: IossInvoiceJobState;
}

export enum IossInvoiceJobState {
  Idle,
  Processing,
  CompletedSuccessfully,
  CompletedWithErrors,
}
export enum IossJobType {
  None = 0,
  Fee = 1,
  Vat = 2,
  //Obsolete
  VatAndFee = 3,
}

export interface IossInvoiceJob {
  id: string;
  coveredFilesStartUtc: string;
  coveredFilesEndUtc: string;
  coveredEntriesStartUtc: string;
  coveredEntriesEndUtc: string;
  numberOfFilesProcessed: number;
  numberOfEntriesProcessed: number;
  numberOfDuplicateEntriesFiltered: number;
  numberOfIossNumbersProcessed: number;
  numberOfInvoicesProduced: number;
  numberOfVatNotificationsProduced: number;
  totalSalesValueOfEntries: number;
  totalVatValueOfEntries: number;
  totalFeesValueCalculated: number;
  jobStartedBy: string;
  jobStartedByName: string;
  jobStartedUtc: string;
  jobEndedUtc: string;
  endDateForJobRunUtc: string;
  jobType: IossJobType;
}

export interface IossInvoiceJobInvoice {
  id: string;
  companyId: string;
  companyName: string;
  customerNumber: string;
  collectionInvoiceNumber?: string;
  invoiceType: IossInvoiceJobInvoiceType;
  invoiceNumber: string;
  isPrepaid: boolean;
  sendTimestampUtc: string;
  recipient: string;
  createdUtc: string;
  updatedUtc: string;
  totalAmount: number;
  paidDate: string;
  invoiceRows: IossInvoiceJobInvoiceRow[];
}

export interface IossInvoiceJobInvoiceRow {
  rowNumber: number;
  numberOfSourceItems: number;
  numberOfItems: number;
  articleNumber: string;
  text: string;
}

export enum IossInvoiceJobInvoiceType {
  None,
  IossFee,
  IossVat,
}
