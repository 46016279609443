import * as Api from 'integrations/crossborderit';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus, selectPageOptionsData, setPageOptions } from 'store';

export const usePageOptions = () => {
  const dispatch = useDispatch();
  const { pageOptions } = useSelector(selectPageOptionsData);

  const loadPageOptionsData = useCallback(() => {
    Api.getPageOptions()

      .then((pageOptions) => {
        dispatch(setPageOptions(AsyncStatus.Success, pageOptions));
      })
      .catch(() => dispatch(setPageOptions(AsyncStatus.Error, [])));
  }, [dispatch]);

  useEffect(() => {
    if (pageOptions.status !== AsyncStatus.NotInitialized) return;
    loadPageOptionsData();
  }, [loadPageOptionsData, pageOptions.status]);

  return { pageOptions };
};
