import { isEmpty } from 'ramda';

export const isObjKeysEmpty = (obj: Object): boolean => {
  if (typeof obj !== 'object') return true;
  const keys = Object.values(obj);
  const nonEmptyValues = keys.filter((key) => {
    if (key === null) return false;
    if (key === undefined) return false;
    if (key === 0) return true;
    return !isEmpty(key);
  });

  return nonEmptyValues.length === 0;
};
