import { Agreement } from '../agreements';

export interface Subscription {
  id: string;
  isActive: Boolean;
  name: string;
  description: string;
  informationUrl?: string;
  subscriptionInfoPoints?: SubscriptionInfoPoint[];
  modulesInSubscription: SubscriptionModule[];
  createdByUserName?: string;
  updatedUtc?: string;
  createdUtc?: string;
  endUtc?: string;
  startUtc?: string;
  subscriptionLengthsAndFees: SubscriptionLengthsAndFee[];
  subscriptionAgreements: SubscriptionAgreements[];
  subscriptionInitialFees: SubscriptionInitialFee[];
  onboardingSteps: OnboardingStep[];
  subscriptionModuleAccess: SubscriptionModuleAccess[];
  subscriptionGroupId?: string;
  orderInGroup: number;
  isSubscribable: Boolean;
}

export interface SubscriptionLengthsAndFee {
  id?: string;
  subscriptionLength: SubscriptionLength;
  fee: number;
  updatedUtc?: string;
  isActive: Boolean;
  updatedById?: string;
  subscriptionId?: string;
};

export interface SubscriptionInitialFee {
  id: string;
  name: string;
  description: string;
  type: SubscriptionInitialFeeType;
  initialFee: number;
  articleNumber: string;
  isActive: boolean;
  createdUtc: string;
  updatedUtc?: string;
};

export enum SubscriptionInitialFeeType {
  NotSelected = 0,
  IossFee = 1,
  CompanySpecificFee = 2,
  GeneralFee = 3,
}

export interface SubscriptionInfoPoint {
  id: string;
  text: string;
}

export interface SubscriptionAgreements{
  id: string;
  name: string;
}
export interface options {
  id: string;
  text: string;
};

export enum SubscriptionModule {
  None = 'None',
  Classification = 'Classification',
  Calculation = 'Calculation',
  Collection = 'Collection',
  Customs = 'Customs',
}

export enum SubscriptionLength {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
}

export interface CompanySubscription {
  id: string,
  fee: number,
  subscriptionId: string,
  companySubscriptionState: CompanySubscriptionState,
  subscriptionLengthAndFeeId: string,
  subscriptionLength: SubscriptionLength,
  name: string,
  nextDebitDate?: string,
  modulesInSubscription: SubscriptionModule[],
  endsUtc?: string,
  unsignedAgreements: Agreement[],
  subscriptionModuleAccess: SubscriptionModuleAccess[],
  initialInvoicePaid?: boolean,
  createdUtc: string,
  initialFees: SubscriptionInitialFee[],
}

export enum CompanySubscriptionState {
  None = 'None',
  Ended = 'Ended',
  WaitingForAgreementsToBeSigned = 'WaitingForAgreementsToBeSigned',
  Onboarding = 'Onboarding',
  AwaitingPayment = 'AwaitingPayment',
  Active = 'Active',
};

export enum OnboardingStep {
  None = 'None',
  Company = 'Company',
  Contact = 'Contact',
  Billing = 'Billing',
  Merchant = 'Merchant',
  Addresses = 'Addresses',
  AML = 'AML',
}

export enum SubscriptionModuleAccess {
  NotSelected = 'NotSelected',
  ClassitSmall = 'ClassitSmall',
  ClassitMedium = 'ClassitMedium',
  ClassitLarge = 'ClassitLarge',
  ClassitDirect = 'ClassitDirect',
  Ioss = 'Ioss',
  Ddp = 'Ddp',
  Ddu = 'Ddu',
}

export const ClassitSubscriptionModuleAccessOrder = {
  [SubscriptionModuleAccess.ClassitSmall]: 0,
  [SubscriptionModuleAccess.ClassitMedium]: 1,
  [SubscriptionModuleAccess.ClassitLarge]: 2,
};

export interface SubscriptionGroup {
  id: string;
  name: string;
  description: string;
  subscriptionsInGroup: SubscriptionGroupItem[];
}

export interface SubscriptionGroupItem {
  id: string;
  subscriptionId: string;
  name: string;
  order: number;
}