import { FunctionComponent } from 'react';
import { Form, Col, Row, Input, Radio, InputNumber, message } from 'antd';
import { InternationalPhoneNumber } from './InternationalPhoneNumber';
import { CompanyContact, EmailStatus } from 'integrations/crossborderit';
import { Status, StatusColor } from '../status';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { resendContactEmailVerification } from 'integrations/crossborderit/contacts';
import { Button } from '../button';

interface Props {
  name?: (string | number)[];
  gutter?: number;
  beneficialOwners: CompanyContact[];
  selectedContact?: CompanyContact;
}

export const AmlKybContactPerson: FunctionComponent<Props> = ({
  name = [],
  gutter = 16,
  beneficialOwners,
  selectedContact,
  ...props
}) => {
  const {t} = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();

  const resendEmailVerification = () => {
    resendContactEmailVerification(companyId, selectedContact?.id!)
      .then(() => message.success(t('Email verification link has been sent')))
      .catch(() => message.error(t('Email verification link could not been sent')));
  };

  return (
    <>
      <Row gutter={gutter}>
        <Col span={24}>
          <Form.Item
            {...props}
            name={[...name, 'id']}
            hidden
          >
            <Input disabled autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'firstName']}
            label="First name"
            rules={[
              {
                required: true,
                message: 'Please enter a first name',
              },
            ]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'lastName']}
            label="Last name"
            rules={[{ required: true, message: 'Please enter a last name' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={12}>
          <Form.Item
            name={[...name, 'email']}
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please enter an email address',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input type="email"/>
          </Form.Item>
        </Col>
        {(selectedContact && selectedContact?.emailStatus !== EmailStatus.NoVerificationNeeded)
          ? <Col>
            <Form.Item
              label={t('Email Status')}
            >
              <Status text={selectedContact?.emailStatus === EmailStatus.Verified ? t('Email Verified') : t('Email Not Verified')} color={selectedContact?.emailStatus === EmailStatus.Verified ? StatusColor.Success : StatusColor.Error} />
            </Form.Item>
          </Col>
          : <></>
        }
      </Row>
      {selectedContact?.emailStatus === EmailStatus.NotVerified
        && <Row gutter={gutter}>
          <Col span={12}>
            <Form.Item>
              <Button
                type="primary"
                onClick={resendEmailVerification}
              >
                {t('Resend verification link')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      }
      <Row gutter={gutter}>
        <Col span={24}>
          <Form.Item
            rules={[{ required: true, message: 'Please enter a phone number' }]}
          >
            <InternationalPhoneNumber label="Phone Number" name={[...name, 'phone']} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={[...name, 'personalId']}
            label="Personal ID Number"
            rules={[{ required: true, message: 'Please enter a personal ID number' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[...name, 'nationality']}
            label="Nationality"
            rules={[{ required: true, message: 'Please enter a nationality' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Contact Title"
            name={[...name, 'contactTitle']}
            rules={[{ required: true, message: 'Please choose a title of the contact' }]}
            initialValue={'beneficialOwner'}
          >
            <Radio.Group>
              {beneficialOwners.length !== 0 && <Radio value="director">Director</Radio>}

              <Radio value="beneficialOwner">Beneficial Owner</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[...name, 'ownerPercentage']}
            label="Ownership Percentage"
          >
            <InputNumber min={0} max={100}/>
          </Form.Item>
        </Col>
      </Row>
    </>
  ); };