export interface Document {
  id: string
  companyId?: string
  addedUtc?: string
  addedByFullName?: string,
  name: string
  description: string
  upload?: any
  documentUrl?: string
  fileName?: string
  updatedUtc?: string
}

export enum DocumentType {
  Other = 'Other',
  SalesDocument = 'SalesDocument',
}