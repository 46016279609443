import { CompanyCategory } from '../companies';

export enum OnboardingOptions {
  AddIoss = 'Addioss',
}

export interface Onboarding {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  token?: string;
  tokenExpires?: string;
  completedTimestamp?: string;
  onboardingCompleted?: boolean;
  companyCategories: CompanyCategory[];
  onboardingOptions?: OnboardingOptions[];
  referrer: string | null;
}