import { useState, useEffect } from 'react';
import { authService } from 'integrations/crossborderit';

export enum Authentication {
  Authenticating = 'Authenticating',
  Authenticated = 'Authenticated',
  NotAuthenticated = 'NotAuthenticated',
}

export const useAuthenticationStatus = () => {
  const [status, setStatus] = useState(Authentication.Authenticating);

  useEffect(() => {

    const subscription = authService.subscribe(() => {
      setStatus(Authentication.Authenticating);

      authService.isAuthenticated().then(isAuthenticated => {
        setStatus(isAuthenticated
          ? Authentication.Authenticated
          : Authentication.NotAuthenticated
        );
      });

    });

    authService.isAuthenticated().then(isAuthenticated => {
      setStatus(isAuthenticated
        ? Authentication.Authenticated
        : Authentication.NotAuthenticated
      );
    });

    return () => authService.unsubscribe(subscription);
  }, []);

  return status;
};
