export interface IossReimportStatusModel {
  message: string;
  totalNumberOfEntries: number;
  currentEntry: number;
  stateName: string;
  state: IossReimportJobState;
}

export enum IossReimportJobState {
  Idle,
  Processing,
  CompletedSuccessfully,
  CompletedWithErrors,
}
