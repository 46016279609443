import { useEffect, useState, useCallback, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { AppState, saveIsSidebarCollapsed } from 'store';

export interface SidebarItem {
  icon?: ReactNode;
  text: string;
  href: string;
  isVisible?: () => boolean;
  key?: string;
  className?: string;
}

export const useSidebar = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const isSidebarCollapsed = useSelector(
    (state: AppState) => !!state?.isSidebarCollapsed
  );

  const setIsSidebarCollapsed = (collapsed: boolean) => {
    dispatch(saveIsSidebarCollapsed(collapsed));
  };

  const toggleIsSidebarCollapsed = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  useEffect(() => {
    setSelectedKeys(
      location.pathname
        .replace(`${match.url}`, '')
        .split('/')
        .filter((x) => !!x)
    );
  }, [match, location]);

  const getItemKey = useCallback((item: SidebarItem) =>
    item.href.replace(`${match.url}`, '').replace('/', ''), [match]);

  const isSidebarItemVisible = useCallback((item: SidebarItem) => {
    if (!item) return false;
    const predicate = item?.isVisible || (() => true);
    return predicate();
  }, []);

  return {
    isSidebarCollapsed,
    isSidebarItemVisible,
    setIsSidebarCollapsed,
    toggleIsSidebarCollapsed,
    selectedKeys,
    getItemKey,
    openedSidebarWidth: '249px',
    closedSidebarWidth: '80px',
  };
};
