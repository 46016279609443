import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { UserPreferences } from './interfaces';
import { getUserPreferencesAsync, updateUserPreferencesAsync } from './api';
import { User } from '..';

const storePath = ['userPreferences'];
const selectUserMenuProps = (state: AppState): { user?: User; } => ({
  user: state.user,
});

export const useUserPreferences = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(selectUserMenuProps);

  const {
    status = AsyncStatus.NotInitialized,
    data: preferences = { maskCompanyData: false },
  } = useSelector(selectAsyncResource<UserPreferences>({ maskCompanyData: false }, storePath));

  const updateUserPreferences = useCallback((userPreferencesToUpdate: UserPreferences) => {
    if (user) {
      return updateUserPreferencesAsync({ ...userPreferencesToUpdate, userId: user.sub })
        .then(() => {
          dispatch(setAsyncResource(
            storePath,
            AsyncStatus.Success,
            userPreferencesToUpdate
          ));
        });
    }
  }, [dispatch, user]);

  const loadUserPreferences = useCallback(() => {
    if (user && user.sub) getUserPreferencesAsync(user.sub)
      .then(prefrences => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        prefrences
      )))
      .catch(() => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Error,
        []
      )));
  }, [dispatch, user]);

  const formatCompanyName = useCallback((name: string) =>
    (preferences.maskCompanyData && !!name) ? '*****' : name, [preferences]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized || !user) return;
    loadUserPreferences();
  }, [status, loadUserPreferences, user]);

  return {
    status,
    preferences,
    loadUserPreferences,
    updateUserPreferences,
    formatCompanyName,
  };
};
