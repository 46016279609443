import { HTTPMethod, apiFetch } from '../fetch';
import { UserPreferences } from './interfaces';

export const getUserPreferencesAsync = (userId: string): Promise<UserPreferences> =>
  apiFetch<UserPreferences>(`/userPreferences/${userId}`);

export const createUserPreferencesAsync = (userPreferences: UserPreferences): Promise<UserPreferences> =>
  apiFetch<UserPreferences>('/userPreferences/', userPreferences, HTTPMethod.POST);

export const updateUserPreferencesAsync = (userPreferences: UserPreferences): Promise<UserPreferences> =>
  apiFetch<UserPreferences>('/userPreferences/', userPreferences, HTTPMethod.PUT);