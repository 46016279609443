import { omit } from 'ramda';
import { isObjKeysEmpty } from 'utility';
import {
  CompanyBackendModel,
  Company,
  Address,
  AddressType,
} from './interfaces';

export const companyToCompanyBackendModel = (
  company: Company
): CompanyBackendModel => {
  const addresses: Address[] = [];

  !isObjKeysEmpty(company?.postalAddress || {})
    && addresses.push({
      ...company.postalAddress!,
      addressTypes: [AddressType.Postal],
    });

  !isObjKeysEmpty(company?.invoiceBillingAddress || {})
    && addresses.push({
      ...company.invoiceBillingAddress!,
      addressTypes: [AddressType.InvoiceBilling],
    });

  !isObjKeysEmpty(company?.visitAddress || {})
    && addresses.push({
      ...company.visitAddress!,
      addressTypes: [AddressType.Visit],
    });

  return {
    ...omit(
      ['postalAddress', 'invoiceBillingAddress', 'visitAddress'],
      company
    ),
    addresses: addresses,
  };
};

export const companyBackendModelToCompany = (
  companyBackendModel: CompanyBackendModel
): Company => ({
  ...omit(['addresses'], companyBackendModel),
  postalAddress: findAddress(AddressType.Postal, companyBackendModel) || ({} as Address),
  invoiceBillingAddress: findAddress(AddressType.InvoiceBilling, companyBackendModel) || ({} as Address),
  visitAddress: findAddress(AddressType.Visit, companyBackendModel) || ({} as Address),
});

const findAddress = (
  addressType: AddressType,
  companyBackendModel: CompanyBackendModel
) => companyBackendModel.addresses
  .find((address) => (address.addressTypes || []).includes(addressType));
