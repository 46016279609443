import { FunctionComponent } from 'react';

interface Props {
  data?: string;
  match?: (string | number | undefined)[];
  component?: JSX.Element;
}

export const Case: FunctionComponent<Props> = ({
  data,
  match = [],
  children,
  component,
}) => <>{match.includes(data) && (component || children)}</>;
