import {
  EntityNote, EntityNoteGroup,
} from './interfaces';
import { apiFetch, HTTPMethod } from '../fetch';

export const getEntityNotes = (entityId: string, companyId?: string): Promise<EntityNote[]> =>
  apiFetch<EntityNote[]>(`/EntityNotes/${entityId}${!!companyId ? `?companyId=${companyId}` : ''
  }`);

export const createEntityNote = (entityId: string, entityNote: EntityNote): Promise<EntityNote> =>
  apiFetch(`/EntityNotes/${entityId}`, entityNote, HTTPMethod.POST);

export const editEntityNote = (entityId: string, entityNote: EntityNote): Promise<EntityNote> =>
  apiFetch<EntityNote>(`/EntityNotes/${entityId}/${entityNote.id}`, entityNote, HTTPMethod.PUT);

export const revokeEntityNote = (entityId: string, noteId: string) =>
  apiFetch(`/EntityNotes/${entityId}/${noteId}/revoke`, undefined, HTTPMethod.PUT);

export const GetEntityNotesTaggedWithUserId = (userId: string | null = '', limit: number = 10) =>
  apiFetch<EntityNoteGroup[]>(`/EntityNotes/userTagged?userId=${userId}&limit=${limit}`, undefined, HTTPMethod.GET);