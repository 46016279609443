import { FunctionComponent, useEffect, useState } from 'react';
import { Form, Col, Row, Input, message } from 'antd';
import { InternationalPhoneNumber } from '.';
import { useTranslation } from 'react-i18next';
import {
  CompanyContact, getVerifiedCompanyPhoneNumbers,
  sendPhoneNumberVerification, setCompanyPhoneNumberAsVerified, UserRole, getVerifiedCompanyEmailAddresses, sendCompanyEmailAddressVerification, setCompanyEmailAddressAsVerified,
} from 'integrations/crossborderit';
import { Button } from '../button';
import { useParams } from 'react-router';
import { VerifyPhoneNumberModal } from 'components/verification';
import { useSelector } from 'react-redux';
import { selectIsUserInRole } from 'store';
import { Stack } from '@fluentui/react';

export enum Overlay {
  None,
  VerifyPhoneNumber,
  VerifyEmailAddress,
}

interface Props {
  name?: (string | number)[];
  fieldKey?: (string | number)[];
  gutter?: number;
  disabled?: boolean;
  firstNameRequired?: boolean;
  lastNameRequired?: boolean;
  emailRequired?: boolean;
  mainContact?: CompanyContact;
  phoneRequired?: boolean;
  verifyNumber?: boolean;
  verifyEmailAddress?: boolean;
  pendingPhoneVerification?: (state: boolean) => void;
  pendingEmailVerification?: (state: boolean) => void;
  formRemoteSubmit?: (submit: boolean) => void;
}

export const ContactPerson: FunctionComponent<Props> = ({
  name = [],
  fieldKey = [],
  gutter = 16,
  disabled = false,
  firstNameRequired = true,
  lastNameRequired = true,
  emailRequired = true,
  phoneRequired = true,
  mainContact,
  verifyNumber,
  verifyEmailAddress,
  pendingPhoneVerification = () => { },
  pendingEmailVerification = () => { },
  formRemoteSubmit = () => { },
  ...props
}) => {
  const { t } = useTranslation();
  const [phoneNumberNeedsVerification, setPhoneNumberNeedsVerification] = useState<boolean>(false);
  const [emailAddressNeedsVerification, setEmailAddressNeedsVerification] = useState<boolean>(false);
  const { companyId } = useParams<{ companyId: string }>();
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string | undefined>(mainContact?.email);
  const [verifiedCompanyPhoneNumbers, setVerifiedCompanyPhoneNumbers] = useState<string[]>([]);
  const [verifiedCompanyEmailAddresses, setVerifiedCompanyEmailAddresses] = useState<string[]>([]);
  const [verifiedCompanyPhoneNumbersLoaded, setVerifiedCompanyPhoneNumbersLoaded] = useState<boolean>(false);
  const [verifiedCompanyEmailAddressesLoaded, setVerifiedCompanyEmailAddressesLoaded] = useState<boolean>(false);
  const [overlay, setOverlay] = useState<Overlay>(Overlay.None);
  const isUserSuperAdmin = useSelector(selectIsUserInRole(UserRole.SuperAdmin));
  const [isValidPhoneNumberFormat, setIsValidPhoneNumberFormat] = useState<boolean>();

  const handleSendPhoneVerification = () => {
    sendPhoneNumberVerification(newPhoneNumber!, companyId).then(() => {
      setOverlay(Overlay.VerifyPhoneNumber);
    }).catch(() => {
      message.error(t('Phone number could not be verified'));
    });
  };

  const onChangePhoneNumberVerificationStatus = (status: boolean) => {
    setPhoneNumberNeedsVerification(status);
    pendingPhoneVerification(status);
  };

  const onChangeEmailVerificationStatus = (status: boolean) => {
    setEmailAddressNeedsVerification(status);
    pendingEmailVerification(status);
  };

  const setPhoneNumberAsVerified = () => {
    setCompanyPhoneNumberAsVerified(companyId, newPhoneNumber!)
      .then((verifiedPhoneNumber) => {
        setVerifiedCompanyPhoneNumbers([...verifiedCompanyPhoneNumbers, verifiedPhoneNumber]);
        onChangePhoneNumberVerificationStatus(false);
        formRemoteSubmit(true);
      }).catch(() => {
        message.error(t('Could not set phone number as verified and update contact information'));
      });
  };

  const setEmailAddressAsVerified = () => {
    setCompanyEmailAddressAsVerified(companyId, emailAddress!)
      .then((verifiedEmailAddress) => {
        setVerifiedCompanyEmailAddresses([...verifiedCompanyEmailAddresses, verifiedEmailAddress]);
        onChangeEmailVerificationStatus(false);
        formRemoteSubmit(true);
      }).catch(() => {
        message.error(t('Could not set email address as verified and update contact information'));
      });
  };

  const handleSendEmailVerification = () => {
    sendCompanyEmailAddressVerification(emailAddress!, companyId).then(() => {
      setOverlay(Overlay.VerifyEmailAddress);
    }).catch((err) => {
      message.error(t('Email Address could not be verified'));
    });
  };

  useEffect(() => {
    (verifyNumber && companyId) && getVerifiedCompanyPhoneNumbers(companyId).then((res) => {
      setVerifiedCompanyPhoneNumbers(res);
      setVerifiedCompanyPhoneNumbersLoaded(true);
    }).catch(() => {
      message.error(t('Could not get verified company phone numbers'));
    });

    (verifyEmailAddress && companyId) && getVerifiedCompanyEmailAddresses(companyId).then((res) => {
      setVerifiedCompanyEmailAddresses(res);
      setVerifiedCompanyEmailAddressesLoaded(true);
    }).catch(() => {
      message.error(t('Could not get verified company email addresses'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verifyEmailAddress && verifiedCompanyEmailAddressesLoaded && !verifiedCompanyEmailAddresses.some(email => email === emailAddress)) {
      onChangeEmailVerificationStatus(true);
    } else {
      onChangeEmailVerificationStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyEmailAddress, emailAddress, verifiedCompanyEmailAddresses, verifiedCompanyEmailAddressesLoaded]);

  useEffect(() => {
    if (verifyNumber && verifiedCompanyPhoneNumbersLoaded && !verifiedCompanyPhoneNumbers.some(phone => phone === newPhoneNumber)) {
      onChangePhoneNumberVerificationStatus(true);
    } else {
      onChangePhoneNumberVerificationStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyNumber, newPhoneNumber, verifiedCompanyPhoneNumbers, verifiedCompanyPhoneNumbersLoaded]);

  return (
    <>
      <Row gutter={gutter}>
        <Col span={24}>
          <Form.Item
            {...props}
            name={[...name, 'id']}
            hidden
            fieldKey={[...fieldKey, 'id']}
          >
            <Input disabled autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'firstName']}
            fieldKey={[...fieldKey, 'firstName']}
            label="First name"
            rules={[
              {
                required: firstNameRequired,
                message: 'Please enter a first name',
              },
            ]}
          >
            <Input disabled={disabled} autoComplete="given-name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'lastName']}
            fieldKey={[...fieldKey, 'lastName']}
            label="Last name"
            rules={[{ required: lastNameRequired, message: 'Please enter a last name' }]}
          >
            <Input disabled={disabled} autoComplete="family-name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={gutter}>
        <Col span={24}>
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
          >
            <Stack.Item style={{ width: '100%' }}>
              <Form.Item
                {...props}
                name={[...name, 'email']}
                fieldKey={[...fieldKey, 'email']}
                label="Email"
                rules={[
                  {
                    required: emailRequired,
                    message: 'Please enter an email address',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ]}
                hasFeedback={(verifyEmailAddress && !emailAddressNeedsVerification)}
                validateStatus="success"
              >
                <Input type="email" autoComplete="email" disabled={disabled} onChange={(e) => e.target.value && setEmailAddress(e.target.value)} />
              </Form.Item>
            </Stack.Item>
            {(verifyEmailAddress && emailAddressNeedsVerification)
              && <Stack.Item style={{ marginTop: '32px' }}>
                <Button
                  onClick={handleSendEmailVerification}
                  type="primary"
                  className="btn-cbit--success verify-btn"
                >
                  {t('Verify Email Address')}
                </Button>
              </Stack.Item>
            }
          </Stack>
        </Col>
      </Row>

      <Row gutter={gutter}>
        <Col span={24}>
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
          >
            <Stack.Item style={{ width: '100%' }}>
              <InternationalPhoneNumber name={[...name, 'phone']} label="Phone" required={phoneRequired}
                isValidFormat={(value) => { setIsValidPhoneNumberFormat(value); }}
                getValue={(value) => { value && setNewPhoneNumber(value); }}
                hasFeedBack={(verifyNumber && !phoneNumberNeedsVerification)}
                validationStatus="success"
              />
            </Stack.Item>
            {(verifyNumber && phoneNumberNeedsVerification)
              && <Stack.Item style={{ marginTop: '30px' }}>
                <Button
                  disabled={!isValidPhoneNumberFormat}
                  onClick={handleSendPhoneVerification}
                  type="primary"
                  className="btn-cbit--success verify-btn"
                >
                  {t('Verify Phone Number')}
                </Button>
              </Stack.Item>
            }
          </Stack>
        </Col>
      </Row>

      {isUserSuperAdmin
        && <Row>
          <Col span={24}>
            <Stack
              tokens={{ childrenGap: 16 }}
              horizontal
            >
              {(phoneNumberNeedsVerification && verifyNumber && isValidPhoneNumberFormat)
                && <Button
                  onClick={setPhoneNumberAsVerified}
                  type="primary"
                  className="btn-cbit--success"
                >
                  {t('Set Phone Number As Verified')}
                </Button>
              }
              {(emailAddressNeedsVerification && verifyEmailAddress)
                && <Button
                  onClick={setEmailAddressAsVerified}
                  className="btn-cbit--success"
                  type="primary"
                >
                  {t('Set Email Address As Verified')}
                </Button>
              }
            </Stack>
          </Col>
        </Row>
      }

      {
        ((phoneNumberNeedsVerification || emailAddressNeedsVerification) && (verifyNumber || verifyEmailAddress))
        && <VerifyPhoneNumberModal
          onFinish={(res, isPhoneVerification) => {
            if (isPhoneVerification) {
              setVerifiedCompanyPhoneNumbers([...verifiedCompanyPhoneNumbers, res]);
              setOverlay(Overlay.None);
              onChangePhoneNumberVerificationStatus(false);
              formRemoteSubmit(true);
            } else {
              setVerifiedCompanyEmailAddresses([...verifiedCompanyEmailAddresses, res]);
              setOverlay(Overlay.None);
              onChangeEmailVerificationStatus(false);
              formRemoteSubmit(true);
            }
          }}
          onCancel={() => setOverlay(Overlay.None)}
          visible={overlay === Overlay.VerifyPhoneNumber || overlay === Overlay.VerifyEmailAddress}
          phoneNumberToVerify={newPhoneNumber}
          emailAddressToVerify={emailAddress}
          companyId={companyId}
          overlayType={overlay}
          contactId={mainContact?.id}
        />
      }
    </>
  );
};
