import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Layout } from 'antd';
import { ErrorResult } from 'components/shared/result';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout style={{ height: '100%' }}>
      <ErrorResult
        title="404"
        subTitle={t('The page you are looking for does not exist.')}
        extra={
          <Link component={Button} type="primary" to="/">
            {t('Back Home')}
          </Link>
        }
      />
    </Layout>
  );
};
