import {
  CmsContent,
  CmsContentType,
} from './interfaces';
import { apiFetch, HTTPMethod } from '../fetch';

export const getCmsContentForAdmin = (contentId: string): Promise<CmsContent> =>
  apiFetch(`/admin/cmsContent/${contentId}`);

export const getCmsContentForCompany = (companyId: string, contentId: string): Promise<CmsContent> =>
  apiFetch(`/company/${companyId}/cmsContent/${contentId}`);

export const getCmsContentWithTypeForCompany = (companyId: string, contentType: CmsContentType): Promise<CmsContent[]> =>
  apiFetch(`/company/${companyId}/cmsContent?contentType=${contentType}`);

export const getCmsContentWithTypeForAdmin = (contentType: CmsContentType, onlyVisible: boolean = false): Promise<CmsContent[]> =>
  apiFetch(`/admin/cmsContent/?contentType=${contentType}&onlyVisible=${onlyVisible}`);

export const postCmsContent = (cmsContent: CmsContent): Promise<CmsContent[]> =>
  apiFetch('/admin/cmsContent', cmsContent, HTTPMethod.POST);

export const putCmsContent = (cmsContent: CmsContent): Promise<CmsContent[]> =>
  apiFetch('/admin/cmsContent', cmsContent, HTTPMethod.PUT);

export const revokeCmsContent = (contentId: string): Promise<CmsContent> =>
  apiFetch(`/admin/cmsContent/${contentId}/inactivate`, undefined, HTTPMethod.PUT);

