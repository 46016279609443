export interface Fee {
  companyCountryFeeId: string;
  name: string;
  feeTriggerType: FeeTriggerType;
  feeCalculationType: FeeCalculationType;
  staffedFees: StaffedFee[];
  currencyCode: string;
}

export interface StaffedFee {
  staffedFeeId: string;
  triggerLowLimit: number;
  feeValue: number;
}

export enum FeeTriggerType {
  Never = 'Never',
  Always = 'Always',
}

export enum FeeCalculationType {
  FixedAmount = 'FixedAmount',
  MultipliedAmount = 'MultipliedAmount',
}

export interface CreateCompanyCountryRole {
  countryCode: string;
  customsBrokerCountryRoles: CustomsBrokerCountryRoles[];
}

export interface CompanyCountryRole extends CreateCompanyCountryRole {
  id: string;
}

export enum CustomsBrokerCountryRoles {
  Broker = 'Broker',
  PostalHandler = 'PostalHandler',
  ProductClassifier = 'ProductClassifier',
}

export interface CarrierCountryBroker {
  countryCode: string;
  brokerCompanyId: string;
  brokerCompanyName: string;
  countryName: string;
}
