import { FunctionComponent, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import { selectCurrencies } from 'store';
import { Switch, Case } from 'components/shared/switch';

interface Props {
  name?: (string | number)[];
  fieldKey?: (string | number)[];
  mode?: 'name' | 'alpha3Code' | 'both';
  label?: string;
  required?: boolean;
  style?: CSSProperties;
  noStyle?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export const CurrencyInput: FunctionComponent<Props> = ({
  mode = 'both',
  name = ['currencyCode'],
  fieldKey = ['currencyCode'],
  required = false,
  disabled = false,
  label,
  style,
  noStyle,
  placeholder,
  ...props
}) => {
  const currencies = useSelector(selectCurrencies);

  return (
    <Form.Item
      {...props}
      noStyle={noStyle}
      name={name}
      fieldKey={fieldKey}
      label={label}
      rules={[{ required, message: 'Please select a currency' }]}
    >
      <Select
        showSearch
        optionFilterProp="label"
        placeholder={placeholder}
        filterOption={true}
        disabled={disabled}
        style={style}
      >
        {currencies.map((currency) => (
          <Select.Option
            key={currency.alpha3Code}
            value={currency.alpha3Code}
            label={`${currency.name} (${currency.alpha3Code})`}
          >
            <Switch on={mode}>
              <Case match={['alpha3Code']} component={<>{currency?.alpha3Code}</>} />
              <Case match={['name']} component={<>{currency?.name}</>} />
              <Case
                match={['both']}
                component={
                  <>
                    {currency?.alpha3Code} ({currency?.name})
                  </>
                }
              />
            </Switch>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
