import { FunctionComponent, useState, CSSProperties } from 'react';
import { Divider, Dropdown } from 'antd';
import {
  DefaultEffects,
  Pivot,
  PivotItem,
  Stack,
  IconButton,
} from '@fluentui/react';
import { Link } from 'react-router-dom';
import { removeById } from 'utility';
import { putMarkMessageAsRead } from '../../integrations/crossborderit/userMessage/api';
import { useTranslation } from 'react-i18next';
import {
  Message,
  MessageType,
} from 'integrations/crossborderit/userMessage/interfaces';
import { TimeAgo } from 'components/shared/display';
import { Text } from '@fluentui/react/lib/Text';

interface NotificationsProps {
  iconStyle?: CSSProperties;
  iconClassName?: string;
}

export const Notifications: FunctionComponent<NotificationsProps> = ({
  iconClassName = '',
  iconStyle,
}) => {
  const { t } = useTranslation();
  const [numberOfUnreadMessages, setNumberOfUnreadMessages]
    = useState<number>();
  const [unreadMessages, setUnreadMessages] = useState<Message[]>([]);
  const [readMessages, setReadMessages] = useState<Message[]>([]);
  const [dropdownVisible, setDropDownVisible] = useState<boolean>(false);

  const messages = () => (
    <div
      style={{
        minWidth: '300px',
        boxShadow: DefaultEffects.elevation16,
        backgroundColor: 'hsl(0, 0%, 100%)',
      }}
    >
      <Pivot
        style={{
          maxHeight: '30vh',
          overflow: 'auto',
        }}
      >
        {unreadMessages.length === 0 ? (
          <PivotItem
            onClick={handleMenuClick}
            key="1"
            headerText={t('Unread messages')}
          >
            <div style={{ padding: '16px' }}>
              {t('You have no unread messages')}
            </div>
          </PivotItem>
        ) : (
          <PivotItem
            onClick={handleMenuClick}
            key="1"
            headerText={t('Unread messages')}
            itemCount={numberOfUnreadMessages}
          >
            {unreadMessages.map((message) => (
              <div key={message.id}>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  style={{
                    padding: '16px',
                    backgroundColor: 'hsl(199, 100%, 95%)',
                  }}
                >
                  {message.messageType
                    === MessageType.ClassificationJobFinished ? (
                      <Link
                        to={`/workspace/${message.payload?.companyId}/customers/${message.payload?.customerId}/external-classification-jobs/${message.payload?.submitId}`}
                        onClick={() => openMessage(message)}
                      >
                        {message.header}{' '}
                      </Link>
                    ) : (
                      <Text>{message.header}</Text>
                    )}
                  <TimeAgo
                    style={{ fontSize: '11px', paddingLeft: '0.5em' }}
                    timestamp={message.received}
                  ></TimeAgo>
                </Stack>
                <Divider style={{ margin: '0' }} />
              </div>
            ))}
          </PivotItem>
        )}

        <PivotItem
          key="2"
          headerText={t('Read messages')}
          style={{ maxHeight: '100%', overflow: 'auto' }}
          onClick={(e) => e.preventDefault()}
        >
          {readMessages.length === 0 ? (
            <div style={{ padding: '16px' }}>
              {t('You have no read messages')}
            </div>
          ) : (
            readMessages.map((message) => (
              <div
                key={message.id}
                style={{ maxHeight: '100%', overflow: 'auto' }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  style={{ padding: '16px' }}
                >
                  {message.messageType === 1 ? (
                    <Link
                      to={`/workspace/${message.payload?.companyId}/customers/${message.payload?.customerId}/external-classification-jobs/${message.payload?.submitId}`}
                      onClick={() => openMessage(message)}
                    >
                      {message.header}{' '}
                    </Link>
                  ) : (
                    <Text>{message.header}</Text>
                  )}

                  <TimeAgo
                    style={{ fontSize: '11px', paddingLeft: '0.5em' }}
                    timestamp={message.received}
                  ></TimeAgo>
                </Stack>

                <Divider style={{ margin: '0' }} />
              </div>
            ))
          )}
        </PivotItem>
      </Pivot>
    </div>
  );

  const openMessage = (message: Message) => {
    if (!message.isRead) {
      markAsRead(message);
      setNumberOfUnreadMessages((prev) => prev && prev - 1);
    }
    setDropDownVisible(false);
  };

  const handleMenuClick = (e: any) => {
    if (e.key === '1' || e.key === '2') {
      setDropDownVisible(true);
    }
  };

  const markAsRead = (message: Message) => {
    putMarkMessageAsRead(message.id).then(() => {
      setUnreadMessages(removeById(message.id, unreadMessages));
      setReadMessages((prev) => [...prev, message]);
    });
  };

  // Disabling notifications for now, there are some issues we need to look into
  // Related bugs: #1028
  // useEffect(() => {
  //   if (!isInactiveUser) {
  //     getAllMessagesForUser().then((messages) => {
  //       setUnreadMessages(
  //         messages.filter((message) => message.isRead === false)
  //       );
  //       setReadMessages(messages.filter((message) => message.isRead === true));
  //     });
  //
  //     getNumberOfUnreadMessages().then(setNumberOfUnreadMessages);
  //   } else {
  //     setUnreadMessages([]);
  //     setReadMessages([]);
  //   }
  // }, [isInactiveUser]);

  return (
    <Dropdown
      overlay={messages}
      onVisibleChange={setDropDownVisible}
      visible={dropdownVisible}
      trigger={['click']}
    >
      <IconButton
        className={iconClassName}
        style={iconStyle}
        iconProps={{
          iconName: unreadMessages.length === 0 ? 'Mail' : 'MailReminder',
        }}
      />
    </Dropdown>
  );
};
