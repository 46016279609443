import { useCallback, useEffect, useMemo } from 'react';
import { useCrossborderitApi } from '../useCrossborderitApi';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, setAsyncResource, selectAsyncResource } from 'store';
import { Company, CreateCompany, CompanyBackendModel } from './interfaces';
import { HTTPMethod } from 'integrations/crossborderit/fetch';
import { replaceBy } from 'utility';
import { PartialCompany, getCompaniesUserCanAccess } from 'integrations/crossborderit';
import { companyBackendModelToCompany } from './companyTransformer';
import { equals } from 'ramda';
import { useUserPreferences } from '../userPreferences/useUserPreferences';

export const partialCompaniesStorePath = ['companiesUserCanAccess'];

export const useCompanies = () => {
  const { api } = useCrossborderitApi();
  const dispatch = useDispatch();
  const { loadUserPreferences, formatCompanyName, status: userPreferencesStatus } = useUserPreferences();

  const {
    data: companies = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<PartialCompany[]>([], partialCompaniesStorePath), equals);

  const inactiveCompanies = useMemo(
    () => companies.filter(company => company.revokedUtc).map((company) => ({ ...company, name: formatCompanyName(company?.name ?? '') })),
    [companies, formatCompanyName]
  );

  const activeCompanies = useMemo(
    () => companies.filter(company => !company.revokedUtc).map((company) => ({ ...company, name: formatCompanyName(company?.name ?? '') })),
    [companies, formatCompanyName]
  );

  const loadCompanies = useCallback(() =>
    getCompaniesUserCanAccess()
      .then(companies => dispatch(setAsyncResource(
        partialCompaniesStorePath,
        AsyncStatus.Success,
        companies
      )))
      .catch(() => dispatch(setAsyncResource(
        partialCompaniesStorePath,
        AsyncStatus.Error,
        []
      )))
  , [dispatch]);

  const createCompany = useCallback((company: CreateCompany) =>
    api<Company>('/companies/', company, HTTPMethod.POST)
      .then(company => dispatch(setAsyncResource(
        partialCompaniesStorePath,
        AsyncStatus.Success,
        [...companies, company]
      ))), [api, companies, dispatch]);

  const inactivateCompany = useCallback((companyId: string) =>
    api<CompanyBackendModel>(`/companies/${companyId}/inactivate`, undefined, HTTPMethod.PUT)
      .then(companyBackendModelToCompany)
      .then(company => dispatch(setAsyncResource(
        partialCompaniesStorePath,
        AsyncStatus.Success,
        replaceBy('id', company, companies)
      )))
  , [api, companies, dispatch]);

  const reactivateCompany = useCallback((companyId: string) =>
    api<CompanyBackendModel>(`/companies/${companyId}/reactivate`, undefined, HTTPMethod.PUT)
      .then(companyBackendModelToCompany)
      .then(company => dispatch(setAsyncResource(
        partialCompaniesStorePath,
        AsyncStatus.Success,
        replaceBy('id', company, companies)
      )))
  , [api, companies, dispatch]);

  const updateCompaniesState = useCallback((updatedCompany: Company) =>
    dispatch(setAsyncResource(
      partialCompaniesStorePath,
      AsyncStatus.Success,
      replaceBy('id', updatedCompany, companies)
    ))
  , [companies, dispatch]);

  const formattedCompanyNames = useMemo(() => companies.map((company) => ({ ...company, name: formatCompanyName(company?.name ?? '') })), [companies, formatCompanyName]);

  useEffect(() => {
    let didCancel = false;
    if (didCancel) return;
    if (userPreferencesStatus === AsyncStatus.Success && status !== AsyncStatus.NotInitialized) {
      loadCompanies();
    } else if (userPreferencesStatus === AsyncStatus.NotInitialized && !didCancel) {
      loadUserPreferences();
    }
    return () => {
      didCancel = true;
    };
  }, [status, loadCompanies, userPreferencesStatus, loadUserPreferences]);

  return {
    companies: formattedCompanyNames,
    inactiveCompanies,
    activeCompanies,
    inactivateCompany,
    reactivateCompany,
    status,
    createCompany,
    loadCompanies,
    updateCompaniesState,
  };
};
