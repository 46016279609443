export interface CreateDynamicsResponse {
  invoiceCreated: boolean;
  message?: string;
  statusCode: number;
}

export interface CreateDynamicsCustomerResponse extends CreateDynamicsResponse {
  customerCreated: boolean;
}

export interface CreateDynamicsError {
  statusCode: number;
  message: string;
}

export enum DynamicsInvoiceType{
  FeeInvoice = 1,
  ReminderFeeInvoice = 2,
  InterestInvoice = 3,
  InitialSubscriptionInvoice = 4,
  SubscriptionInvoice = 5,
  EconomicEntriesInvoice = 6,
}