import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../rootReducer';

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
AppState,
unknown,
Action<string>
>;

export interface ActionCreator {
  (...ars: any[]): any;
  type: string;
}

export const createAction = (
  type: string,
  fn: (...ars: any[]) => any
): ActionCreator => {
  const action = (...args: any[]) => Object.assign(fn(...args), { type });
  return Object.assign(action, { type });
};
