import { IChartProps, IVSChartDataPoint } from '@fluentui/react-charting';
import { CustomsStatus } from '../digitalCustoms/interfaces';

export interface SalesByPeriod {
  transactionDate: string;
  vatValue: number;
  count: number;
  country: string;
}
export interface SalesByPeriodAndDestination {
  country: string;
  transactionDate: string;
  vatValue: number;
  count: number;
}

export interface IossRegistrationsByPeriod {
  count: number;
  transactionDate: string;
}

export interface IossRegistrationsByPeriodAndCountry {
  count: number;
  country: string;
  iossStartDate: string;
}

export interface EarningsByCompanyAndDate {
  companyId: string;
  companyName: string;
  count: number;
  revenue: number;
  transactionDate: string
}

export interface VatByCompanyAndDate {
  companyId: string;
  companyName: string;
  vatAmount: number;
  transactionDate: string
}

export interface SalesByPeriodAdmin {
  transactionDate: string;
  country: string;
  count: number;
  vatValue: number;
}
export interface DateRange {
  from: string;
  to: string;
}

export interface IossRegistrationsByPeriodAndCountry {
  iossStartDate: string;
  country: string;
  count: number;
}

export interface DestinationCountriesForEachCompany{
  [country: string]: number;
}
export interface AllDestinationCountries{
  [country: string]: number;
}

export enum ChartState {
  Idle = 'Idle',
  Loading = 'Loading',
  Error = 'Error',
  NoData = 'NoData',
}

export enum ChartType {
  LineChart = 'LineChart',
  VerticalBarChart = 'VerticalBarChart',
  VerticalStackedBarChart = 'VerticalStackedBarChart',
  GroupedVerticalBarChart = 'GroupedVerticalBarChart',
  HorizontalBarChart = 'HorizontalBarChart',
}

export interface EarningsAndVatByCompanyAndDate {
  earnings: EarningsByCompanyAndDate[];
  vat: VatByCompanyAndDate[];
}

export interface IVSChartDataPointWithCompanyId extends IVSChartDataPoint {
  companyId: string;
}

export interface IChartPropsWithCompanyId extends IChartProps {
  companyId: string;
}

export enum AdminChart {
  ByFeeType = 'ByFeeType',
  AllFeeTypes = 'AllFeeTypes',
  UnpaidInvoices = 'UnpaidInvoices',
  NewIossRegistrations = 'NewIossRegistrations',
  NewIossRegistrationsByCountry = 'NewIossRegistrationsByCountry',
  EarningsByCompany = 'EarningsByCompany',
  AllFeeTypesBySourceCountry = 'AllFeeTypesBySourceCountry',
  OrdersOfTop10DestinationCountries = 'OrdersOfTop10DestinationCountries',
  Devations = 'Deviations',
}

export enum PlatformType {
  All = 0,
  ShopifyApp = 1,
  MagentoApp = 2,
  WooCommerceApp = 3,
  PublicApi = 4,
}

export interface DigitalCustomsAnalyticsData{
  data: DigitalCustomsOrdersByPeriod [],
  deliveryTypes: string [],
}

export interface DigitalCustomsOrdersByPeriod {
  count: number;
  timePeriod: string;
}

export interface AnalyticsQuery {
  fromDate: string;
  toDate: string;
  companyId?: string;
  groupedPeriod?: ChartGroupedPeriod;
}

export enum ChartGroupedPeriod {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface ClassitProductCountByCompany {
  fromDate: string;
  toDate: string;
  id: string;
  name: string;
  productCount: number;
}

export interface ClassitProductWithoutDescriptionByCompany {
  fromDate: string;
  toDate: string;
  id: string;
  name: string;
  missingDescriptionCount: number;
}

export interface ClassitProductWithoutTrade6ByCompany {
  fromDate: string;
  toDate: string;
  id: string;
  name: string;
  missingTrade6Count: number;
}

export interface ClassitProductWithInvalidStatusByCompany {
  fromDate: string;
  toDate: string;
  id: string;
  name: string;
  invalidStatusCount: number;
}

export interface ClassitUploadedProductsByTypeAndCompany {
  id: string;
  name: string;
  productCount: number;
  uploadType: UploadType;
}

export enum UploadType {
  MANUAL = 'MANUAL',
  API = 'API',
}

export enum ClassificationHelpMethods {
  FEDEX = 'FEDEX',
  AI = 'AI',
}

export interface ClassitClassifiedProductsByTypeAndCompany {
  classifiedBy: ClassificationHelpMethods;
  id: string;
  name: string;
  productCount: number;
  errored?: number;
}

export interface ClassitClassifyingByTypeAndCompany {
  id: string;
  name: string;
  classifiedBy: ClassificationHelpMethods;
  productCount: number;
}

export interface ClassitCompletedClassificationJobs {
  id: string;
  name: string;
  classifiedBy: ClassificationHelpMethods;
  productCount: number;
}

export interface DigitalCustomsOrdersAnalytics {
  articles: number;
  companyId: string;
  declarations: number;
  orders: number;
  companyName: string;
}

export interface DigitalCustomsOrdersByCustomsStatusAnalytics {
  articles: number;
  companyId: string;
  customsStatus: CustomsStatus;
  declarations: number;
  orders: number;
  companyName: string;
  fromDate: string;
  toDate: string;
}

export interface DigitalCustomsOrdersWithCorrections {
  companyId: string;
  orders: number;
}

export interface DigitalCustomsDayWiseReportOrders {
  orders: number;
  companyId: string;
  companyName: string;
  date: string;
}