import { User } from 'integrations/crossborderit';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppState, AsyncStatus } from 'store';

interface UserProps {
  user?: User;
}

const selectProps = (state: AppState): UserProps => ({
  user: state?.user,
});

export const ImportWoony = () => {
  const { user } = useSelector(selectProps);
  const companyUsers = useSelector(
    (state: AppState) => state?.companyUsers || []
  );
  // Getting the companyUser in order to get detailed user info
  const companyUser = Object.values(companyUsers).flatMap((company => company.data)).find((companyUser) => companyUser?.userId === user?.sub);
  const location = useLocation();

  const woonyScript = `(function (d, w, f) {
    if (!d.getElementById("woony-widget")) {
      var m = d.getElementsByTagName("script")[0];
      var s = d.createElement("script");
      var loaded = false;
      s.id = "woony-widget";
      s.async = "async";
      s.type = "module";
  s.src = "https://optimobile.se/downloads/woony_widgets/opti/woony-0.2.9.modern.js?init=attribute"       
      s.setAttribute("data-licensekey", "ea88d9c6-ca63-4b22-99d5-962185c51580");
      s.setAttribute("data-debug", "true");
      if (f) {
        s.onreadystatechange = s.onload = function () {
          if (!loaded && (!s.readyState ||
            "loaded" === s.readyState ||
            "complete" === s.readyState)) {
            loaded = true;
            f();
          }
        };
      }
      m.parentNode.insertBefore(s, m);
    }
  })(document, window, function () {
    new Woony().init(function () {
      return { customer_id: "", customer_email: "${user?.email}", customer_name: "${user?.given_name} ${user?.family_name}", customer_avatar: "", customer_phone: "${companyUser?.phones?.map((phone) => phone.number)}", customer_level: "" };
    })
  }) `;

  const woonyWidget = document.getElementsByClassName('woony-script')[0];

  useEffect(() => {
    if (woonyWidget) {
      return;
    } else if (
      user && companyUsers && Object.keys(companyUsers).length && Object.keys(user).length && !location.pathname.includes('admin') && Object.values(companyUsers).every((user => user.status === AsyncStatus.Success)))
    {
      const script = document.createElement('script');
      script.innerHTML = woonyScript;
      document.body.appendChild(script);
      script.className = 'woony-script';
    }
  }, [user, location.pathname, woonyWidget, woonyScript, companyUsers]);

};
