import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { Country, useCountries} from 'integrations/crossborderit';
import { AsyncStatus } from 'store';
import { Switch, Case } from 'components/shared/switch';
import './CountrySelect.css';

interface CountrySelectProps
  extends Omit<SelectProps<Country | Country[]>, 'onSelect' | 'onDeselect'> {
  valueDisplayType?: keyof Country | 'alpha2AndName';
  optionLabelType?: keyof Country;
  disabledOptions?: (string | number | undefined)[];
  onChange?: (value: Country | Country[] | undefined) => void;
  value?: Country | Country[] | undefined;
  filteredCountries?: Country[];
}

enum Status {
  Error = 'Error',
  Loading = 'Loading',
  Idle = 'Idle',
}

export const CountrySelect = ({
  valueDisplayType = 'alpha2AndName',
  optionLabelType = 'name',
  disabledOptions = [],
  onChange: _onChange = () => {},
  value: _value = undefined,
  defaultValue = undefined,
  filteredCountries = undefined,
  ...props
}: CountrySelectProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | string[] | undefined>(
    Array.isArray(_value)
      ? _value.map((value) => value.alpha2Code || '')
      : _value?.alpha2Code
  );
  const [status, setStatus] = useState<Status>(Status.Loading);
  const { countries, status: countriesStatus } = useCountries();
  const [selectableCountries, setSelectableCountries] = useState<Country[]>([]);

  useEffect(() => {
    let didCancel = false;
    if (didCancel) return;
    setSelectableCountries(filteredCountries || countries);

    return () => {
      didCancel = true;
      setSelectableCountries([]);
    };
  }, [countries, filteredCountries]);

  useEffect(() => {

    if (countriesStatus === AsyncStatus.Loading) {
      setStatus(Status.Loading);
    } else if (countriesStatus === AsyncStatus.Error) {
      setStatus(Status.Error);
    } else if (countriesStatus === AsyncStatus.Success) {
      setStatus(Status.Idle);
      if (!!value){
        const country = Array.isArray(value)
          ? selectableCountries.filter((country) => value.includes(country.alpha2Code || ''))
          : selectableCountries.find((country) => country.alpha2Code === value);
        _onChange(country); }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectableCountries, countriesStatus]);

  return (
    <Switch on={status}>
      <Case match={[Status.Error]}>
        <Select
          disabled
          placeholder={t('Could not load countries.')}
        />
      </Case>
      <Case match={[Status.Idle, Status.Loading]}>
        <Select<string | string[]>
          loading={status === Status.Loading}
          defaultValue={undefined as unknown as string}
          value={value}
          showSearch
          optionLabelProp="label"
          optionFilterProp="data-search"
          filterOption={true}
          onChange={setValue}
          {...props}
        >
          {selectableCountries.map((country) => (
            <Select.Option
              key={country.alpha2Code}
              value={country.alpha2Code || ''}
              label={`(${country.alpha2Code}) ${country.name}`}
              data-search={`${country.alpha2Code} ${country.alpha3Code} ${country.name}`}
              disabled={disabledOptions.includes(country.alpha2Code)}
              title={country.alpha2Code}
            >
              {valueDisplayType === 'alpha2AndName'
                ? `(${country.alpha2Code}) ${country.name}`
                : country[optionLabelType]}
            </Select.Option>
          ))}
        </Select>
      </Case>
    </Switch>
  );
};
