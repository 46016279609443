import { apiFetch, HTTPMethod } from '../fetch';
import { CreateDynamicsResponse, CreateDynamicsCustomerResponse, CreateDynamicsError} from './interface';

export const createDynamicsCustomer = (companyId: string): Promise<CreateDynamicsCustomerResponse> =>
  apiFetch<CreateDynamicsCustomerResponse>(`/dynamics/customers/${companyId}`, null, HTTPMethod.POST)
    .then(result => {
      if (!result.customerCreated) {
        return Promise.reject({ statusCode: result.statusCode, message: result.message });
      }
      return result;
    }).catch((e: CreateDynamicsError) => {
      if (e.statusCode && e.message) {
        return Promise.reject({message:`${e.statusCode}: ${e.message}`});
      } else if (e.message) {
        return Promise.reject({message:e.message});
      } else {
        return Promise.reject(e);
      }
    });

export const createInitialInvoice = (companyId: string): Promise<CreateDynamicsResponse> =>
  apiFetch<CreateDynamicsResponse>(`/dynamics/customers/${companyId}/applicationFeeInvoice`, null, HTTPMethod.POST)
    .then(result => {
      if (!result.invoiceCreated) {
        return Promise.reject({ statusCode: result.statusCode, message: result.message });
      }
      return result;
    }).catch((e: CreateDynamicsError) => {
      if (e.statusCode && e.message) {
        return Promise.reject(`${e.statusCode}: ${e.message}`);
      } else if (e.message) {
        return Promise.reject(e.message);
      } else {
        return Promise.reject(e);
      }
    });