import { apiFetch, HTTPMethod } from '../fetch';
import { CompanyContact } from '../companies/interfaces';

export const getContacts = (companyId: string): Promise<CompanyContact []> =>
  apiFetch(`/companies/${companyId}/contacts/`);

export const deleteContact = (companyId: string, contactId: string): Promise<CompanyContact> =>
  apiFetch(`/companies/${companyId}/contacts/${contactId}`, undefined, HTTPMethod.DELETE);

export const postContact = (companyId: string, contact: CompanyContact): Promise<CompanyContact> =>
  apiFetch(`/companies/${companyId}/contacts`, contact, HTTPMethod.POST);

export const putContact = (companyId: string, contact: CompanyContact): Promise<CompanyContact> =>
  apiFetch(`/companies/${companyId}/contacts/${contact.id}`, contact, HTTPMethod.PUT);

export const resendContactEmailVerification = (companyId: string, companyContactId: string): Promise<Response> =>
  apiFetch(`/companies/${companyId}/contacts/${companyContactId}/resendEmailVerification`, undefined, HTTPMethod.PUT);