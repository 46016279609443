import {
  Agreement,
  getPendingCompanyAgreements,
  getSignedCompanyAgreements,
} from 'integrations/crossborderit';
import { AsyncStatus } from '../interfaces';
import { createAction, AppThunk } from './createAction';

export const loadPendingCompanyAgreements = createAction(
  'LOAD_PENDING_COMPANY_AGREEMENTS',
  (companyId: string): AppThunk => (dispatch) => {
    dispatch(setPendingCompanyAgreements(companyId, AsyncStatus.Loading));
    return getPendingCompanyAgreements(companyId)
      .then((agreements) => {
        dispatch(setPendingCompanyAgreements(companyId, AsyncStatus.Success, agreements));
      })
      .catch((error) => {
        dispatch(setPendingCompanyAgreements(companyId, AsyncStatus.Error));
        return Promise.reject(error);
      });
  });

export const loadSignedCompanyAgreements = createAction(
  'LOAD_SIGNED_COMPANY_AGREEMENTS',
  (companyId: string): AppThunk => (dispatch) => {
    dispatch(setSignedCompanyAgreements(companyId, AsyncStatus.Loading));
    return getSignedCompanyAgreements(companyId)
      .then((agreements) => {
        dispatch(setSignedCompanyAgreements(companyId, AsyncStatus.Success, agreements));
      })
      .catch((error) => {
        dispatch(setSignedCompanyAgreements(companyId, AsyncStatus.Error));
        return Promise.reject(error);
      });
  });

export const setPendingCompanyAgreements = createAction(
  'SET_PENDING_COMPANY_AGREEMENTS',
  (companyId: string, status: AsyncStatus, agreements: Agreement[]) => ({
    status,
    companyId,
    agreements,
  })
);

export const setSignedCompanyAgreements = createAction(
  'SET_SIGNED_COMPANY_AGREEMENTS',
  (companyId: string, status: AsyncStatus, agreements: Agreement[]) => ({
    status,
    companyId,
    agreements,
  })
);
