import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Company,
  putCompany,
} from 'integrations/crossborderit';
import {
  AsyncStatus,
  selectCompany,
  setCompany,
  loadCompany,
} from 'store';
import { useUserPreferences } from '../userPreferences/useUserPreferences';

export const useCompany = (companyId: string) => {
  const dispatch = useDispatch();
  const { status, company } = useSelector(selectCompany(companyId));
  const { formatCompanyName, status: userPreferencesStatus } = useUserPreferences();

  const formattedCompany = useMemo(() => ({ ...company, name: formatCompanyName(company?.name || '') })
    , [company, formatCompanyName]);

  const updateCompany = (updatedCompanyFields: Partial<Company>) =>
    putCompany({ ...company, ...updatedCompanyFields})
      .then(updatedCompany => {
        dispatch(setCompany(updatedCompany.id, AsyncStatus.Success, updatedCompany));
        return updatedCompany;
      }
      );

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized && userPreferencesStatus !== AsyncStatus.NotInitialized) return;
    companyId
      ? dispatch(loadCompany(companyId))
      : dispatch(setCompany(companyId, AsyncStatus.Error));
  }, [dispatch, companyId, status, userPreferencesStatus]);

  return {
    status,
    company : formattedCompany,
    updateCompany,
  };
};
