export interface AppVersion {
  environment: string;
  version: string;
}

export enum Environment {
  Production = 'Production',
  Local = 'Local',
  Stage = 'Stage/Test',
  Development = 'Development',
}