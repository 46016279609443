import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from 'translations/en.json';

const resources = {
  en: { translation: enTranslation },
};

export const setupInternationalization = () =>
  i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
