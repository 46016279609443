import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { CbitBlogPost, getCbitBlog } from '.';

const storePath = ['cbitBlog'];

export const useCbitBlog = () => {
  const dispatch = useDispatch();

  const {
    status = AsyncStatus.NotInitialized,
    data: blogPosts = [],
  } = useSelector(selectAsyncResource<CbitBlogPost[]>([], storePath));

  const loadCbitBlog = useCallback(() => {
    getCbitBlog()
      .then(blogPosts => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        blogPosts
      )))
      .catch(() => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Error,
        []
      )));
  }, [dispatch]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized) return;
    loadCbitBlog();
  }, [status, loadCbitBlog]);

  return {
    status,
    blogPosts,
    loadCbitBlog,
  };
};
