import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Logo } from 'components/logo';
import { Breadcrumb } from './Breadcrumb';
import {
  ApplicationPaths,
  UserRole,
} from 'integrations/crossborderit';
import { Authentication, useAuthenticationStatus } from 'components/apiAuthorization';
import { UserMenu } from './UserMenu';
import { Notifications } from 'components/notifications';
import { Stack, IconButton, TooltipHost } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { selectIsUserInRole } from 'store';
import { Help } from './Help';
import './Header.css';
import { ScreenSize, useScreenSize } from 'components/shared/layout/useScreenSize';
import { Case, Switch } from 'components/shared/switch';

const menuIconsStyle = {
  color: 'hsl(0, 0%, 100%)',
  fontSize: '18px',
  height: '100%',
};

export enum HeaderTheme {
  Dark = 'dark',
  Light = 'light',
}
interface Props {
  showRightSideMenu?: boolean;
  headerTheme?: HeaderTheme;
  className?: string;
  logoNavLink?: string;
  openCompanyNotes?: () => void;
  toggleMobileSidebar?: () => void;
}

export const Header: FunctionComponent<Props> = ({
  showRightSideMenu = true,
  logoNavLink = undefined,
  headerTheme = HeaderTheme.Dark,
  className = '',
  openCompanyNotes,
  toggleMobileSidebar = () => { },
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authenticationStatus = useAuthenticationStatus();
  const isCbitAdmin = useSelector(selectIsUserInRole(UserRole.Employee));
  const location = useLocation();
  const isCompanyPage = location.pathname.startsWith('/workspace/');
  const [path, setPath] = useState<string>();
  const {screenSize} = useScreenSize();

  useEffect(() => {
    if (path) {
      toggleMobileSidebar();
    }
    setPath(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <header
      className={`
        ${className}
        cbit-header
        cbit-header--${headerTheme}
      `}
    >
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ width: '100%' }} >
        <Switch on={screenSize}>
          <Case match={[ScreenSize.Mobile]}>
            <Stack>
              <IconButton
                id="cbit-hamburger-icon"
                className="cbit-header-menu__button"
                iconProps={{ iconName: 'CollapseMenu' }}
                onClick={toggleMobileSidebar}
              />
            </Stack>
            <Stack>
              <Link
                to={logoNavLink || '/'}
                style={{ marginBottom: '-8px', display: 'block' }}
              >
                <Logo />
              </Link>
            </Stack>
            {authenticationStatus === Authentication.Authenticated ? (
              <Stack
                horizontal
                horizontalAlign="center"
                verticalAlign="center"
                tokens={{ childrenGap: 16 }}
              >
                <UserMenu />
              </Stack>
            )
              : (
                <Link style={{ color: 'white' }} to={ApplicationPaths.Login}>
                  Login
                </Link>)}
          </Case>

          <Case match={[ScreenSize.Desktop, ScreenSize.DesktopSmall]}>
            <Stack style={{ marginRight: '32px' }}>
              <Link
                to={logoNavLink || '/'}
                style={{ marginBottom: '-8px', display: 'block' }}
              >
                <Logo />
              </Link>
            </Stack>

            <Breadcrumb style={{ marginLeft: '16px' }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                flex: '1 1 auto',
              }}
            >
              {showRightSideMenu && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {authenticationStatus === Authentication.Authenticated ? (
                    <Stack
                      horizontal
                      horizontalAlign="center"
                      verticalAlign="center"
                      tokens={{ childrenGap: 16 }}
                    >
                      <div className="cbit-header-menu">
                        {isCbitAdmin && (
                          <TooltipHost
                            id="adminHeaderMenu"
                            content={t('Admin workspace')}
                            styles={{ root: { height: '100%' } }}
                          >
                            <IconButton
                              iconProps={{ iconName: 'Admin' }}
                              style={menuIconsStyle}
                              onClick={() => history.push('/admin')}
                              className="cbit-header-menu__button"
                            />
                          </TooltipHost>
                        )}
                        <TooltipHost
                          id="infoHeaderMenu"
                          content={t('Help')}
                          styles={{ root: { height: '100%' } }}
                        >
                          <Help
                            iconName="Help"
                            iconClassName="cbit-header-menu__button"
                            iconStyle={{
                              ...menuIconsStyle,
                              fontSize: '16px',
                            }}
                          />
                        </TooltipHost>
                        <TooltipHost
                          id="notificationsHeaderMenu"
                          content={t('Notifications')}
                          styles={{ root: { height: '100%' } }}
                        >
                          <Notifications
                            iconStyle={menuIconsStyle}
                            iconClassName="cbit-header-menu__button"
                          />
                        </TooltipHost>
                        {isCbitAdmin && isCompanyPage
                          && <TooltipHost
                            id="adminCompanyNotes"
                            content={t('Company notes')}
                            styles={{ root: { height: '100%' } }}
                          >
                            <IconButton
                              className="cbit-header-menu__button"
                              style={menuIconsStyle}
                              iconProps={{ iconName: 'DietPlanNotebook' }}
                              onClick={openCompanyNotes}
                            />
                          </TooltipHost>
                        }
                      </div>
                      <UserMenu />
                    </Stack>
                  ) : (
                    <Link style={{ color: 'white' }} to={ApplicationPaths.Login}>
                      Login
                    </Link>
                  )}
                </div>
              )}
            </div>
          </Case>
        </Switch>
      </Stack>
    </header>
  );
};