import { ReactElement, Children, cloneElement } from 'react';

interface Props {
  on: string | number;
  children: ReactElement[];
}

export const Switch = ({ on, children }: Props) => (
  <>
    {Children.map(children, (child) =>
      cloneElement(child, { data: on })
    )}
  </>
);
