export interface ProductsSubmittedForExternalClassificationRequest {
  productIds: string[];
  countries: string[];
}

export interface ClassificationExternalRequestStatus {
  submitFailed: boolean;
  submitFailedMessage: string;
  submitId: string;
  numberOfSubmittedCountries: number;
  numberOfSubmittedProducts: number;
  isCompleted: boolean;
  numberOfProductsProcessing: number;
  numberOfProductsFailed: number;
  numberOfProductsSucceeded: number;
}

export interface ClassifiationExternalJobsQuery {
  onlyMyJobs?: boolean;
  offset?: number;
  count?: number;
}

export interface ClassificationExternalSubmitOverview {
  submitId: string;
  numberOfProductsSubmitted: number;
  numberOfCompletedProducts: number;
  numberOfFailedProducts: number;
  countries: string[];
  isCompleted: boolean;
  submittedUtc: string;
  lastUpdatedUtc: string;
  numberOfProductsWithAdditionalInfoRequired: number;
}

export interface ExternalClassificationResponse {
  response: string;
  productId: string;
}

export enum ClassificationExternalStatus {
  None = 'None',
  Submitted = 'Submitted',
  Classifying = 'Classifying',
  ClassifiedSuccessfully = 'ClassifiedSuccessfully',
  FailedToClassify = 'FailedToClassify',
  SubmissionRejected = 'SubmissionRejected',
  SubmissionFailed = 'SubmissionFailed',
  AdditionalInformationRequired = 'AdditionalInformationRequired',
}

export interface ClassificationExternalResultSingleProduct {
  classificationStatus: ClassificationExternalStatus;
  countries: string[];
  lastUpdatedUtc: string;
  message: string;
  productId: string;
  submittedUtc: string;
  productArticleNumber: string;
  productName: string;
}

// ClassificationProduct is the same as ClassificationResultSingleProduct
// but with additional properties that make it easier for frontend to handle.
// The extra properties are all computed from ClassificationResultSingleProduct.
export interface ClassificationExternalProduct extends ClassificationExternalResultSingleProduct {
  isResubmitable: boolean;
}