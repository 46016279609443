import { getInvoicesByCompany } from './api';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { CollectionInvoice, downloadCollectionInvoicePdf, getCollectionInvoice } from '../collectionInvoices';
import { replaceBy } from 'utility';

const companyInvoiceStorePath = ['companyInvoices'];

export const useBillings = (companyId: string) => {

  const {
    data: invoices = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(
    selectAsyncResource<CollectionInvoice[]>([], companyInvoiceStorePath)
  );
  const dispatch = useDispatch();

  const loadInvoicesForCompanyId = useCallback((companyId:string) => {
    dispatch(setAsyncResource(companyInvoiceStorePath, AsyncStatus.Loading, []));
    getInvoicesByCompany(companyId)
      .then(invoices => {
        dispatch(setAsyncResource(companyInvoiceStorePath, AsyncStatus.Success, invoices));
      }).catch(() => {
        dispatch(setAsyncResource(companyInvoiceStorePath, AsyncStatus.Error, []));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companyId]);

  const refreshCollectionInvoice = useCallback((collectionInvoiceId: string) => {
    getCollectionInvoice(companyId, collectionInvoiceId).then(ci => {
      const updatedInvoices = replaceBy('id', ci, invoices!);
      dispatch(setAsyncResource(companyInvoiceStorePath, AsyncStatus.Success, updatedInvoices));
    });
  }, [companyId, invoices, dispatch]);

  const refreshInvoices = () => {
    if (invoices && invoices.length) {
      const updatedInvoices = invoices.concat([]);
      dispatch(setAsyncResource(companyInvoiceStorePath, AsyncStatus.Success, updatedInvoices));
    }
  };

  useEffect(() => {
    if (status === AsyncStatus.NotInitialized) {
      loadInvoicesForCompanyId(companyId);
    }
  }, [companyId, status, loadInvoicesForCompanyId]);

  return {
    invoices,
    status,
    loadInvoicesForCompanyId,
    refreshCollectionInvoice,
    downloadCollectionInvoicePdf,
    refreshInvoices,
  };
};