import { useEffect, useState, FunctionComponent } from 'react';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Switch, Case } from 'components/shared/switch';
import { Guide } from 'components/shared/guide';
import { supportedBrowsers } from './supportedBrowsers';

enum GuardStatus {
  BrowserSupported,
  BrowserNotSupported,
}

export const SupportedBrowsersGuard: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const [guardStatus, setGuardStatus] = useState(GuardStatus.BrowserSupported);

  useEffect(() => {
    setGuardStatus(GuardStatus.BrowserSupported);

    if (!supportedBrowsers.test(navigator.userAgent)) {
      setGuardStatus(GuardStatus.BrowserNotSupported);
    }
  }, []);

  return (
    <Switch on={guardStatus}>

      <Case match={[GuardStatus.BrowserNotSupported]}>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{ marginTop: '48px' }}
        >
          <Guide
            title={t('Browser not supported')}
            text={t(`
              CBIT do not support your browser or browser version.
              Update or change browser to access CBIT and it's features
            `)}
          />
        </Stack>
      </Case>

      <Case match={[GuardStatus.BrowserSupported]}>
        {children}
      </Case>

    </Switch>
  );
};
