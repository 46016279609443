import { apiFetch, HTTPMethod } from '../fetch';
import { Invitation } from './interfaces';

export const getCompanyInvitations = () =>
  apiFetch<Invitation[]>('/onboardings');

export const postInvitation = (onboarding: Invitation) =>
  apiFetch<Invitation>('/onboardings', onboarding, HTTPMethod.POST);

export const putInvitation = (onboarding: Invitation) =>
  apiFetch<Invitation>(`/onboardings/${onboarding.id}`, onboarding, HTTPMethod.PUT);

export const deleteInvitation = (id : string) =>
  apiFetch<Invitation>(`/onboardings/${id}`, undefined, HTTPMethod.DELETE);

export const resendInvitation = (id : string) =>
  apiFetch<Invitation>(`/onboardings/${id}/resend`, undefined, HTTPMethod.PUT);

export const reactivateInvitation = (id : string) =>
  apiFetch<Invitation>(`/onboardings/${id}/reactivate`, undefined, HTTPMethod.PUT);

export const inactivateInvitation = (id : string) =>
  apiFetch<Invitation>(`/onboardings/${id}/inactivate`, undefined, HTTPMethod.PUT);
