export interface Message {
  from: string;
  header: string;
  id: string;
  isImportant: boolean;
  isRead: boolean;
  messageText: string;
  messageType: MessageType;
  payload?: ClassificationJobFinishedPayload;
  received: string;
}

export enum MessageType {
  ClassificationJobFinished = 1,
}

export interface ClassificationJobFinishedPayload {
  customerId?: string;
  companyId?: string;
  numberOfCompletedProducts?: number;
  numberOfFailedProducts?: number;
  numberOfProductsSubmitted?: number;
  submitId?: string;
  submittedCountries?: string[];
}
