import { useCallback, useEffect, useMemo } from 'react';
import { useCrossborderitApi } from '../useCrossborderitApi';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { CreateApiKey, ApiKey } from './interfaces';
import { HTTPMethod } from 'integrations/crossborderit/fetch';
import { replaceBy } from 'utility';

export const useApiKeys = (companyId: string) => {
  const storePath = useMemo(() => ['apiKeys', companyId], [companyId]);
  const { api } = useCrossborderitApi();
  const dispatch = useDispatch();

  const {
    data: apiKeys = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<ApiKey[]>([], storePath));

  const loadApiKeys = useCallback(() => {
    if (!companyId) return;

    dispatch(setAsyncResource(
      storePath,
      AsyncStatus.Loading,
      []
    ));

    api<ApiKey[]>(`/companies/${companyId}/apikeys`)
      .then(apiKeys => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        apiKeys
      )))
      .catch(() => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Error,
        []
      )));
  }, [api, dispatch, storePath, companyId]);

  const createApiKey = useCallback((apiKey: CreateApiKey) =>
    api<ApiKey>(`/companies/${companyId}/apikeys`, apiKey, HTTPMethod.POST)
      .then(newApiKey => {

        const { token, ...apiKey } = newApiKey;

        dispatch(setAsyncResource(
          storePath,
          AsyncStatus.Success,
          [apiKey, ...apiKeys]
        ));

        return token;
      })
  , [api, dispatch, storePath, companyId, apiKeys]);

  const updateKeyDescription = useCallback((apiKeyId: string, description: string) =>
    api<ApiKey>(`/companies/${companyId}/apikeys/${apiKeyId}`, description, HTTPMethod.PUT)
      .then(updatedKey => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        replaceBy('id', updatedKey, apiKeys)
      )))
  , [api, dispatch, storePath, companyId, apiKeys]);

  const revokeApiKey = useCallback((apiKeyId: string) =>
    api<ApiKey>(`/companies/${companyId}/apikeys/${apiKeyId}/revoke`, undefined, HTTPMethod.PUT)
      .then((revokedKey) => dispatch(setAsyncResource(
        storePath,
        AsyncStatus.Success,
        replaceBy('id', revokedKey, apiKeys)
      )))
  , [api, dispatch, storePath, companyId, apiKeys]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized) return;
    loadApiKeys();
  }, [status, loadApiKeys]);

  return {
    status,
    apiKeys,
    loadApiKeys,
    updateKeyDescription,
    createApiKey,
    revokeApiKey,
  };

};
