import { FunctionComponent } from 'react';

interface Props {
  timestamp: string;
  time?: boolean;
}

export const toDisplayDate = (timestamp: string, time: boolean = false) => {
  const date = new Date(timestamp);
  return time
    ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`.trim()
    : `${date.toLocaleDateString()}`;
};

export const DisplayDate: FunctionComponent<Props> = ({
  timestamp,
  time = false,
}) => <>{!!timestamp && toDisplayDate(timestamp, time)}</>;
