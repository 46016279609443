import { CompanyUser } from './interfaces';
import { apiFetch, HTTPMethod, HTTPStatus } from '../fetch';
import { GeneralTimePeriodType } from '../shared';

export const getCompaniesUsersByLastLoggedIn = (generalTimePeriodType: GeneralTimePeriodType) =>
  apiFetch<CompanyUser[]>(`/users/companiesUsersByLastLogin?generalTimePeriodType=${generalTimePeriodType}`);

export const getCompanyUsers = (companyId: string) =>
  apiFetch<CompanyUser[]>(`/companies/${companyId}/users`);

export const getCompanyUser = (companyId: string, companyUserId: string) =>
  apiFetch<CompanyUser>(`/companies/${companyId}/users/${companyUserId}`);

export const postCompanyUser = (companyUser: CompanyUser) =>
  apiFetch<CompanyUser>(`/companies/${companyUser.companyId}/users`, companyUser, HTTPMethod.POST);

export const putCompanyUser = (companyUser: CompanyUser): Promise<CompanyUser> =>
  apiFetch<CompanyUser | { status: number }>(`/companies/${companyUser.companyId}/users`, companyUser, HTTPMethod.PUT).then((response) => {
    if ('status' in response && response.status === HTTPStatus.NoContent) {
      return companyUser;
    } else {
      return response as CompanyUser;
    }
  });

export const deleteCompanyUser = (companyId: string, companyUserId: string) =>
  apiFetch<CompanyUser>(`/companies/${companyId}/users/${companyUserId}`, undefined, HTTPMethod.DELETE);

export const sendPasswordResetEmail = (companyId: string, companyUserId: string): Promise<boolean> =>
  apiFetch<boolean>(`/companies/${companyId}/users/${companyUserId}/SendPasswordReset`, undefined, HTTPMethod.PUT);

export const reactivateCompanyUser = (companyId: string, companyUserId: string): Promise<boolean> =>
  apiFetch<boolean>(`/companies/${companyId}/users/${companyUserId}/Reactivate`, undefined, HTTPMethod.PUT);
