import { FunctionComponent, ReactNode } from 'react';
import './SidebarSecondary.css';
import { mergeDeepRight } from 'ramda';

import {
  Nav,
  INavStyles,
  INavLink,
  Text,
  INavProps,
  INavLinkGroup,
} from '@fluentui/react';

export interface SidebarSecondaryProps extends INavProps {
  groups: SidebarSecondaryLinkGroup[];
}

export interface SidebarSecondaryLink extends INavLink {
  extra?: ReactNode;
}

export interface SidebarSecondaryLinkGroup extends INavLinkGroup {
  headerExtra?: ReactNode;
}

const defaultStyles: Partial<INavStyles> = {
  root: {
    width: 300,
    height: '100%',
    border: '1px solid hsl(0, 0%, 93%)',
    backgroundColor: 'hsl(0, 0%, 95%)',
  },
  navItems: {
    height: '100%',
    overflowY: 'auto',
  },
};

const renderlink = (selectedKey?: string) => (data: any) => {
  const isActive = data?.link?.key === selectedKey;
  const onClick = data?.link?.onClick || (() => {});
  return (
    <div
      onClick={onClick}
      className={`cbit-sidebar-secondary__link ${
        isActive ? 'cbit-sidebar-secondary__link--active' : ''
      }`}
    >
      {data?.children || (
        <Text variant="medium" style={{ margin: 0, lineHeight: 1 }}>
          {data?.link?.name}
        </Text>
      )}
      <div className="cbit-sidebar-secondary__link-extra">
        {data?.link?.extra}
      </div>
    </div>
  );
};

const renderGroupHeader = (group?: SidebarSecondaryLinkGroup) => (
  <div className="cbit-sidebar-secondary__group-header">
    <Text as="h2" variant="large" style={{ margin: 0, lineHeight: 1 }}>
      {group?.name}
    </Text>
    {group?.headerExtra}
  </div>
);

export const SidebarSecondary: FunctionComponent<SidebarSecondaryProps> = ({
  styles = {},
  ...props
}) => {
  const navStyles = mergeDeepRight(defaultStyles, styles);

  return (
    <Nav
      styles={navStyles}
      onRenderGroupHeader={renderGroupHeader}
      linkAs={renderlink(props?.selectedKey)}
      {...props}
    />
  );
};
