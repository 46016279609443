import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import {
  Country,
  getCountries,
} from 'integrations/crossborderit';
import { equals, memoizeWith, identity } from 'ramda';

export const countriesStorePath = ['countries'];

export const useCountries = () => {
  const dispatch = useDispatch();

  const {
    data: countries = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(selectAsyncResource<Country[]>([], countriesStorePath), equals);

  const loadCountries = useCallback(() => {
    dispatch(setAsyncResource(
      countriesStorePath,
      AsyncStatus.Loading,
      countries
    ));

    return getCountries()
      .then(countries => dispatch(setAsyncResource(
        countriesStorePath,
        AsyncStatus.Success,
        countries
      )))
      .catch(() => dispatch(setAsyncResource(
        countriesStorePath,
        AsyncStatus.Error,
        []
      )));

  }, [countries, dispatch]);

  const selectCountryBy = useCallback((prop: keyof Country, value: string) => {
    const findCountry = memoizeWith(identity, (value: string):
    | Country
    | undefined => countries.find((country) => country[prop] === value));
    return findCountry(value);
  }, [countries]);

  useEffect(() => {
    if (status !== AsyncStatus.NotInitialized) return;
    loadCountries();
  }, [status, loadCountries]);

  return {
    countries,
    status,
    loadCountries,
    selectCountryBy,
  };
};