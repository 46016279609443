import { apiFetch, HTTPMethod } from '../fetch';
import { WebshopIntegration } from './interfaces';

export const getAllWebShopIntegrationsForAdmin = () =>
  apiFetch<WebshopIntegration[]>('/Admin/Integrations/WebShop');

export const getWebShopIntegrations = (companyId: string) =>
  apiFetch<WebshopIntegration[]>(`/Company/${companyId}/Integrations/WebShop`);

export const postWebShopIntegrations = (
  companyId: string,
  integrationKey: string,
  webShop: string
) =>
  apiFetch<WebshopIntegration>(
    `/Company/${companyId}/Integrations/WebShop`,
    { IntegrationKey: integrationKey, webShopType: webShop },
    HTTPMethod.POST
  );

export const putWebShopIntegrations = (
  companyId: string,
  webShopIntegrationId: string,
  emailOnErrors: string
) =>
  apiFetch<WebshopIntegration>(
    `/Company/${companyId}/Integrations/WebShop/${webShopIntegrationId}`,
    {
      emailOnErrors: emailOnErrors,
    },
    HTTPMethod.PUT
  );

export const deleteWebShopIntegrations = (
  companyId: string,
  webShopIntegrationId: string
) =>
  apiFetch(
    `/Company/${companyId}/Integrations/WebShop/${webShopIntegrationId}`,
    undefined,
    HTTPMethod.DELETE
  );
