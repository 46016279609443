import { Stack } from '@fluentui/react';
import { Form, Input, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Button } from 'components/shared/button';
import { Overlay } from 'components/shared/input/ContactPerson';
import { verifyCompanyEmailAddress, verifyCompanyPhoneNumber } from 'integrations/crossborderit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: boolean,
  phoneNumberToVerify?: string,
  emailAddressToVerify?: string,
  onFinish: (phoneNumber: string, isPhoneVerififcation: boolean) => void,
  onCancel: () => void,
  overlayType: Overlay;
  companyId: string
  contactId?: string;
};

export const VerifyPhoneNumberModal: FC<Props> = ({
  visible,
  phoneNumberToVerify,
  emailAddressToVerify,
  companyId,
  contactId,
  overlayType,
  onFinish = () => {},
  onCancel = () => {},
}) => {
  const {t} = useTranslation();
  const isPhoneVerification = overlayType === Overlay.VerifyPhoneNumber;
  const [verificationForm] = useForm<string>();

  const handlePhoneVerification = (formData: any) => {
    verifyCompanyPhoneNumber(phoneNumberToVerify!, formData.verificationCode, companyId).then((verifiedPhoneNumber) => {
      onFinish(verifiedPhoneNumber, isPhoneVerification);
    }).catch(() => {
      message.error(t('Phone number could not be verified'));
      onCancel();
    }).finally(() => {
      verificationForm.resetFields();
    });
  };

  const handleEmailVerification = (formData: any) => {
    verifyCompanyEmailAddress(emailAddressToVerify!, formData.verificationCode, companyId, contactId).then((verifiedEmailAddress) => {
      onFinish(verifiedEmailAddress, isPhoneVerification);
    }).catch(() => {
      message.error(t('Email address could not be verified'));
      onCancel();
    }).finally(() => {
      verificationForm.resetFields();
    });
  };

  return (
    <Modal
      visible={visible}
      title={isPhoneVerification ? t('Verify Phone Number') : t('Verify Email Address')}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={verificationForm}
        layout="vertical"
        onFinish={isPhoneVerification ? handlePhoneVerification : handleEmailVerification}
      >
        <Form.Item
          name="verificationCode"
          label={isPhoneVerification ? t('Verification code sent by SMS') : t('Verification code sent by email')}
          rules={[
            {
              required: true,
              message: t('Please enter a verification code'),
            },
          ]}
        >
          <Input placeholder={window.location.hostname.startsWith('cbit-stage.') ? 'Any code works in Cbit Stage Mode' : ''}/>
        </Form.Item>

        <Stack
          horizontal
          horizontalAlign="center"
        >
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('Verify')}
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};