import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, Input, Select } from 'antd';
import { Country, Address } from 'integrations/crossborderit';

interface AddressProps {
  name?: (string | number)[];
  fieldKey?: (string | number)[];
  gutter?: number;
  countries: Country[];
  required?: boolean;
}

const addressFieldsTheUserCanChange = [
  'addressLine1',
  'addressLine2',
  'postalCode',
  'city',
  'state',
  'countryCode',
];

const isAnotherFieldFilled = (fieldName: string, formValues: Address) =>
  Object.entries(formValues)
    .filter(([key]) => addressFieldsTheUserCanChange.includes(key))
    .filter(([key]) => key !== fieldName)
    .some(([_, value]) => !!value);

export const AddressInput: FunctionComponent<AddressProps> = ({
  name = [],
  fieldKey = [],
  gutter = 16,
  countries = [],
  required = false,
  ...props
}) => {
  const { t } = useTranslation();

  const requiredFieldsDependencies = addressFieldsTheUserCanChange.map(
    (fieldName) => [...name, fieldName]
  );

  return (
    <>
      <Row gutter={gutter}>
        <Col span={24}>
          <Form.Item
            {...props}
            name={[...name, 'addressId']}
            fieldKey={[...fieldKey, 'addressId']}
            hidden
          >
            <Input disabled autoComplete="off"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            {...props}
            name={[...name, 'addressLine1']}
            fieldKey={[...fieldKey, 'addressLine1']}
            label="Address Line 1"
            dependencies={requiredFieldsDependencies}
            rules={[
              { required, message: t('Please enter an address') },
              ({ getFieldValue }) => ({
                validator: (_, value: string) => {
                  if (!!value || required) return Promise.resolve();
                  return isAnotherFieldFilled(
                    'addressLine1',
                    getFieldValue(name)
                  )
                    ? Promise.reject(new Error(t('Please enter an address')))
                    : Promise.resolve();
                },
              }),
            ]}
          >
            <Input autoComplete="address-line1"/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={24}>
          <Form.Item
            {...props}
            name={[...name, 'addressLine2']}
            fieldKey={[...fieldKey, 'addressLine2']}
            label="Address Line 2"
          >
            <Input autoComplete="address-line2"/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'postalCode']}
            fieldKey={[...fieldKey, 'postalCode']}
            label="Zip Code"
            dependencies={requiredFieldsDependencies}
            rules={[
              { required, message: t('Please enter a zip code') },
              ({ getFieldValue }) => ({
                validator: (_, value: string) => {
                  if (!!value || required) return Promise.resolve();
                  return isAnotherFieldFilled('postalCode', getFieldValue(name))
                    ? Promise.reject(new Error(t('Please enter a zip code')))
                    : Promise.resolve();
                },
              }),
            ]}
          >
            <Input autoComplete="postal-code"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'city']}
            fieldKey={[...fieldKey, 'city']}
            label="City"
            dependencies={requiredFieldsDependencies}
            rules={[
              { required, message: t('Please enter a city') },
              ({ getFieldValue }) => ({
                validator: (_, value: string) => {
                  if (!!value || required) return Promise.resolve();
                  return isAnotherFieldFilled('city', getFieldValue(name))
                    ? Promise.reject(new Error(t('Please enter a city')))
                    : Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'state']}
            fieldKey={[...fieldKey, 'state']}
            label="State"
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...props}
            name={[...name, 'countryCode']}
            fieldKey={[...fieldKey, 'countryCode']}
            label="Country"
            dependencies={requiredFieldsDependencies}
            rules={[
              { required, message: t('Please enter a Country') },
              ({ getFieldValue }) => ({
                validator: (_, value: string) => {
                  if (!!value || required) return Promise.resolve();
                  return isAnotherFieldFilled('countryCode', getFieldValue(name))
                    ? Promise.reject(new Error(t('Please enter a Country')))
                    : Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              showSearch
              optionFilterProp="data-search"
              filterOption={true}
              allowClear
            >
              {countries.map((country) => (
                <Select.Option
                  key={country.alpha2Code}
                  value={country.alpha2Code}
                  label={country.name}
                  data-search={`${country.alpha2Code} ${country.alpha3Code} ${country.name}`}
                  autoComplete="country-name"
                >
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
