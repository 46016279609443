import './index.css';

export const Logo = ({ className = '', symbol = false, ...props }) => (
  <svg
    id="Lager_1"
    className={`logo ${className}`}
    data-name="Lager 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={symbol ? '0 0 93 93' : '0 0 864 93'}
    {...props}
  >
    <g>
      <g>
        <path
          id="fullLogo"
          d="M212.1713,61.9779c-3.13,14.4231-15.5422,30.7546-38.6847,30.7546-24.6671,0-41.8094-17.7589-41.8094-46.4905C131.6772,17.7577,148.82,0,173.4866,0c20.7044,0,33.02,13.4282,37.0684,24.6671l-13.32,2.9354a24.6142,24.6142,0,0,0-23.7481-16.1151c-17.094,0-27.8839,12.8776-27.8839,34.7546,0,22.1269,10.79,35.0031,27.8839,35.0031,14.8069,0,22.3593-10.33,25.0025-21.3425Z"
        />
        <path
          id="fullLogo-2"
          data-name="fullLogo"
          d="M230.181,35.074h.486c3.2439-6.3473,10.1472-11.439,19.5045-11.439a28.1313,28.1313,0,0,1,5.8166.6157v12.217a45.9331,45.9331,0,0,0-6.4331-.491c-10.7528,0-18.765,5.2432-18.765,19.9261V90.7542H218.0866V25.1269H230.18Z"
        />
        <path
          id="fullLogo-3"
          data-name="fullLogo"
          d="M320.805,57.9407c0,20.65-12.5468,34.3054-31.7492,34.3054-19.3208,0-31.8675-13.6557-31.8675-34.3054S269.735,23.635,289.0558,23.635C308.2582,23.635,320.805,37.29,320.805,57.9407Zm-12.9534,0c0-14.8983-7.2923-24.0295-18.7958-24.0295-11.623,0-18.9156,9.1312-18.9156,24.03S277.4328,81.97,289.0558,81.97C300.5593,81.97,307.8516,72.84,307.8516,57.9407Z"
        />
        <path
          id="fullLogo-4"
          data-name="fullLogo"
          d="M367.7018,44.7123c-1.8551-7.7136-8.3641-11.3952-15.408-11.3952-7.67,0-12.8229,3.41-12.8229,9.5462,0,5.7906,5.7742,7.1844,12.7584,8.4278l6.5039,1.0058c11.3083,1.9832,21.964,5.4546,21.964,19.4611,0,13.53-12.0233,20.4881-27.7592,20.4881-16.7046,0-27.1712-8.5579-29.3818-20.5043l11.6117-2.4494c2.0863,9.3475,8.7569,13.3911,18.0013,13.3911,8.5309,0,14.5749-3.8981,14.5749-10.5524,0-5.9469-5.7794-7.817-13.7853-9.196l-6.611-1.1239c-10.163-1.7407-20.83-5.2121-20.83-18.83,0-11.1841,10.6558-19.3467,25.8944-19.3467,14.37,0,25.16,8.7955,26.8953,18.6343Z"
        />
        <path
          id="fullLogo-5"
          data-name="fullLogo"
          d="M428.2514,44.7123c-1.855-7.7136-8.3641-11.3952-15.4079-11.3952-7.67,0-12.8229,3.41-12.8229,9.5462,0,5.7906,5.7742,7.1844,12.7584,8.4278l6.5039,1.0058c11.3082,1.9832,21.9639,5.4546,21.9639,19.4611,0,13.53-12.0232,20.4881-27.7592,20.4881-16.7046,0-27.1711-8.5579-29.3818-20.5043l11.6118-2.4494c2.0862,9.3475,8.7569,13.3911,18.0013,13.3911,8.5308,0,14.5749-3.8981,14.5749-10.5524,0-5.9469-5.7794-7.817-13.7853-9.196l-6.611-1.1239c-10.1631-1.7407-20.83-5.2121-20.83-18.83,0-11.1841,10.6557-19.3467,25.8943-19.3467,14.37,0,25.16,8.7955,26.8953,18.6343Z"
        />
        <path
          id="fullLogo-6"
          data-name="fullLogo"
          d="M511.1852,57.9419c0,20.65-12.4175,34.3057-29.8084,34.3057-9.3524,0-16.8984-3.9143-20.375-9.9745h-.486v8.4811h-12.093V.7346h12.7034v32.03h.4873c3.4814-5.4646,10.6606-9.13,19.7633-9.13C498.7677,23.635,511.1852,37.291,511.1852,57.9419Zm-12.9534,0c0-14.898-7.4055-24.0293-19.05-24.0293-11.4874,0-18.91,9.1313-18.91,24.0293s7.423,24.0292,18.91,24.0292C490.8263,81.9711,498.2318,72.8413,498.2318,57.9419Z"
        />
        <path
          id="fullLogo-7"
          data-name="fullLogo"
          d="M579.7381,57.9407c0,20.65-12.5468,34.3054-31.7492,34.3054-19.3208,0-31.8675-13.6557-31.8675-34.3054S528.6681,23.635,547.9889,23.635C567.1913,23.635,579.7381,37.29,579.7381,57.9407Zm-12.9534,0c0-14.8983-7.2923-24.0295-18.7958-24.0295-11.623,0-18.9156,9.1312-18.9156,24.03S536.3659,81.97,547.9889,81.97C559.4924,81.97,566.7847,72.84,566.7847,57.9407Z"
        />
        <path
          id="fullLogo-8"
          data-name="fullLogo"
          d="M599.2339,35.074h.4861c3.2439-6.3473,10.1472-11.439,19.5045-11.439a28.13,28.13,0,0,1,5.8165.6157v12.217a45.9315,45.9315,0,0,0-6.433-.491c-10.7528,0-18.765,5.2432-18.765,19.9261V90.7542H587.14V25.1269h12.0929Z"
        />
        <path
          id="fullLogo-9"
          data-name="fullLogo"
          d="M675.8875,32.7637h.4864V.7334h12.7046V90.7527h-12.093V82.272h-.4875c-3.4751,6.06-11.0223,9.9741-20.3736,9.9741-17.3911,0-29.8086-13.6557-29.8086-34.3054S638.7333,23.635,656.1244,23.635C665.2283,23.635,672.4061,27.299,675.8875,32.7637Zm1.34,25.1756c0-14.898-7.4228-24.0293-18.91-24.0293-11.6442,0-19.05,9.1313-19.05,24.0293s7.4055,24.0292,19.05,24.0292C669.8052,81.9685,677.228,72.8384,677.228,57.9393Z"
        />
        <path
          id="fullLogo-10"
          data-name="fullLogo"
          d="M757.3734,71.656c-2.9951,9.4546-10.9627,20.5916-30.257,20.5916-18.4615,0-30.8789-13.7753-30.8789-34.1926,0-20.6459,11.9423-34.42,30.7606-34.42,19.0558,0,32.4518,13.8933,30.7594,37.3607H709.071c.3732,11.9622,7.1634,20.9742,18.0451,20.9742,11.3581,0,15.7858-7.2166,17.43-11.9089Zm-48.06-20.3375h35.6038c-.9886-9.1091-6.6818-17.4073-17.9207-17.4073C715.9956,33.9112,710.3035,42.2094,709.3138,51.3185Z"
        />
        <path
          id="fullLogo-11"
          data-name="fullLogo"
          d="M777.2738,35.074h.4861c3.2439-6.3473,10.1472-11.439,19.5045-11.439a28.13,28.13,0,0,1,5.8165.6157v12.217a45.9318,45.9318,0,0,0-6.4331-.491c-10.7527,0-18.765,5.2432-18.765,19.9261V90.7542H765.1794V25.1269h12.093Z"
        />
        <path
          id="fullLogo-12"
          data-name="fullLogo"
          d="M821.0419.7346V14.4764H808.306V.7346Zm-.0164,24.3923V90.7542H808.3221V25.1269Z"
        />
        <path
          id="fullLogo-13"
          data-name="fullLogo"
          d="M863.27,79.8724V90.7542c-1.3949.1244-6.1408.2488-9.3685.2488-11.5732,0-16.9745-2.7567-16.9745-14.4231V34.6734H826.1V25.1258H836.927V6.5785h12.7046V25.1258H864v9.5476H849.6316V73.1793c0,6.1359,2.33,6.8221,7.3845,6.8221C858.4323,80.0014,861.4262,79.9968,863.27,79.8724Z"
        />
      </g>
      <g id="type">
        <polygon
          id="type_PathItem_"
          data-name="type &lt;PathItem&gt;"
          points="53.026 26.565 79.538 0.052 92.794 13.309 66.282 39.814 53.026 39.814 53.026 26.565"
          style={{ fill: '#183cef' }}
        />
        <polygon
          id="type_PathItem_2"
          data-name="type &lt;PathItem&gt;"
          points="66.282 53.078 92.794 79.59 79.538 92.847 53.032 66.334 53.032 53.078 66.282 53.078"
          style={{ fill: '#ee1313' }}
        />
        <polygon
          id="type_PathItem_3"
          data-name="type &lt;PathItem&gt;"
          points="39.769 26.565 13.256 0.052 0 13.309 26.512 39.814 39.769 39.814 39.769 26.565"
          style={{ fill: '#009b3a' }}
        />
        <polygon
          id="type_PathItem_4"
          data-name="type &lt;PathItem&gt;"
          points="26.513 53.078 0 79.59 13.256 92.847 39.762 66.334 39.762 53.078 26.513 53.078"
          style={{ fill: '#fccc00' }}
        />
      </g>
    </g>
  </svg>
);
