import { apiFetch, HTTPMethod } from 'integrations/crossborderit/fetch';

export const getPayPalClientToken = (companyId: string) =>
  apiFetch<{ clientToken: string }>(`/companies/${companyId}/payments/GetClientToken/PayPal`)
    .then((data) => data?.clientToken);

export const putPayPalPaymentNonce = (companyId: string, nonce: string) =>
  apiFetch(
    `/companies/${companyId}/payments/PayPal?accessToken=${nonce}`,
    undefined,
    HTTPMethod.PUT
  );

export const createPayPalPayment = (companyId: string, paymentMethodNonce: string, invoiceId: string) =>
  apiFetch(
    `/companies/${companyId}/payments/PayPalPayment?invoiceId=${invoiceId}&paymentMethodNonce=${paymentMethodNonce}`,
    undefined,
    HTTPMethod.PUT
  );
