import { Country, CountryData } from './interfaces';
import { apiFetch, HTTPMethod } from '../fetch';

export const getCountries = () => apiFetch<Country[]>('/PageOptions/Countries');

export const getCountryCustomsLink = (countryCode: string) => apiFetch<CountryData[]>(`/countries/${countryCode}/data/customshscodeurls`);

export const postCountryData = (countryCode: string, countryData: CountryData) => apiFetch<CountryData>(`/countries/${countryCode}/data`, countryData, HTTPMethod.POST);

export const getAllDataForCountry = (countryCode: string) => apiFetch<CountryData[]>(`/countries/${countryCode}/data`);

export const putCountryData = (countryCode: string, countryData: CountryData, countryDataId: string) =>
  apiFetch<CountryData>(`/countries/${countryCode}/data/${countryDataId}`, countryData, HTTPMethod.PUT);

export const deleteCountryData = (countryCode: string, countryDataId: string) => apiFetch<CountryData>(`/countries/${countryCode}/data/${countryDataId}`, undefined, HTTPMethod.DELETE);
