import { CompanySubscription, CompanySubscriptionState, SubscriptionModule, SubscriptionModuleAccess } from '../integrations/crossborderit';
import { intersection } from 'ramda';

export const hasActiveSubscription = (
  companySubscriptions: CompanySubscription[],
  subscriptionModules: SubscriptionModule[],
  subscriptionModuleAccess: SubscriptionModuleAccess[] = []
) => companySubscriptions.some(sub =>
  !!intersection(subscriptionModules, sub.modulesInSubscription || []).length
  && (!!subscriptionModuleAccess.length ? !!intersection(subscriptionModuleAccess, sub.subscriptionModuleAccess || []).length : true)
  && sub.companySubscriptionState === CompanySubscriptionState.Active
);

export const hasSubscription = (
  companySubscriptions: CompanySubscription[],
  subscriptionModules: SubscriptionModule[],
  subscriptionModuleAccess: SubscriptionModuleAccess[] = []
) => companySubscriptions.some(sub =>
  !!intersection(subscriptionModules, sub.modulesInSubscription || []).length
  && (!!subscriptionModuleAccess.length ? !!intersection(subscriptionModuleAccess, sub.subscriptionModuleAccess || []).length : true)
);