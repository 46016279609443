import { update, findIndex, propEq, curry } from 'ramda';

export const replaceBy = curry(<T>(prop: string, value: T, list: T[]) => {
  if (prop === undefined || prop === null || typeof value !== 'object') {
    return list;
  }

  const index = findIndex(propEq(prop, value[prop as keyof T]), list);

  return index === -1 ? list : update(index, value, list);
});
