import download from 'downloadjs';
import { apiFetch, HTTPMethod } from '../fetch';
import {
  IossImportDataEntryModel, IossOrderRefundDetails,
} from './interfaces';

export const downloadIossOrderInformation = (companyId: string, fileId: string) => apiFetch<Response>(`/download/${fileId}/${companyId}`, undefined, HTTPMethod.PUT)
  .then((response) => (response.ok ? response : Promise.reject()))
  .then((response) => response
    .blob()
    .then((blob) => {
      download(blob, 'exportedOrderInformation.csv', blob.type);
    }));

export const getIossOrderInformationFileEntries = (
  companyId: string,
  fileId: string
) => apiFetch<IossImportDataEntryModel[]>(
  `/companies/${companyId}/iossimport/filecontent?fileId=${fileId}`
);

export const getAllIossOrderInformationFileEntries = (
  companyId: string
) => apiFetch<IossImportDataEntryModel[]>(
  `/companies/${companyId}/iossimport/allEntries`
);
export const getIossOrderRefundDetails = (
  iossRefundId: string,
  companyId: string
) => apiFetch<IossOrderRefundDetails>(
  `/companies/${companyId}/iossimport/iossOrderRefund/${iossRefundId}`
);