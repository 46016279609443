import { apiFetch, HTTPMethod } from '../fetch';
import { apiFetchFormData } from 'integrations/crossborderit/fetchFormData';
import { Agreement } from './interfaces';

export const getAgreements = (): Promise<Agreement[]> => apiFetch('/agreements');

export const getAllAgreements = (): Promise<Agreement[]> => apiFetch('/agreements/all');

export const postAgreement = (agreement: Agreement): Promise<Agreement> => {
  const formData = new FormData();
  formData.append('file', agreement.upload.file.originFileObj);
  formData.append('agreementType', agreement.agreementType);
  formData.append('companyCategories', JSON.stringify(agreement.companyCategories));
  formData.append('name', agreement.name);
  formData.append('description', agreement.description);
  formData.append('updateSummary', agreement.updateSummary || '');
  return apiFetchFormData('/agreements', formData, HTTPMethod.POST);
};

export const putAgreement = (agreement: Agreement): Promise<Agreement> =>
  apiFetch('/agreements', agreement, HTTPMethod.PUT);

export const deleteAgreement = (agreementId: string): Promise<Agreement> =>
  apiFetch(`/agreements/${agreementId}`, undefined, HTTPMethod.DELETE);

export const getPendingCompanyAgreements = (companyId: string) =>
  apiFetch<Agreement[]>(`/companies/${companyId}/agreements/pending`);

export const getSignedCompanyAgreements = (companyId: string) =>
  apiFetch<Agreement[]>(`/companies/${companyId}/agreements/signed`);

export const signCompanyAgreement = (companyId: string, agreementId: string) =>
  apiFetch(`/companies/${companyId}/agreements/sign/${agreementId}`);
