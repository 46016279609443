import { CompanyCategory } from '../companies';

export interface Agreement {
  id?: string
  createdUtc?: string
  agreementType: AgreementType
  companyCategories: CompanyCategory[]
  name: string
  description: string
  upload?: any
  documentUrl?: string
  fileBase64?: string
  fileName?: string
  fileSize?: number
  updatedUtc?: string
  signedBy?: string; //Name not id
  signedUtc?: string;
  decompressedFile?: string;
  updateSummary?: string;
}

export enum AgreementType {
  TermsAndConditions = 'TermsAndConditions',
  PowerOfAttorney = 'PowerOfAttorney',
  BillingTerms = 'BillingTerms',
  Other = 'Other',
}
