import { Divider, Dropdown } from 'antd';
import { FunctionComponent, useState, CSSProperties } from 'react';
import { DefaultEffects, IconButton } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { selectCompanyId } from 'store';
import { useSelector } from 'react-redux';
import { UserRole } from 'integrations/crossborderit';

interface HelpProps {
  iconClassName: string;
  iconStyle: CSSProperties;
  iconName: string;
}

export const Help: FunctionComponent<HelpProps> = ({
  iconClassName,
  iconStyle,
  iconName,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  /*
  A superadmin is not connected to any company id,
  so we generate this companyId below to make the feedback function visible
  when a superadmin is active on a company in the UI
  */
  const { companyId } = useSelector(selectCompanyId);

  const helpDropdown = () => (
    <div
      style={{
        minWidth: '300px',
        boxShadow: DefaultEffects.elevation16,
        backgroundColor: 'hsl(0, 0%, 100%)',
      }}
    >
      <div style={{ padding: '16px' }}>
        <Link
          to={'/workspace/faq'}
          target="_blank"
        >FAQ</Link>
      </div>

      {(companyId || !UserRole.SuperAdmin) && <><Divider style={{ margin: '0' }} /><div style={{ padding: '16px' }}>
        <Link to={'/workspace/feedback'}>Feedback</Link>
      </div></>}

    </div>
  );

  return (
    <Dropdown
      overlay={helpDropdown}
      onVisibleChange={setDropdownVisible}
      visible={dropdownVisible}
      trigger={['click']}
    >
      <IconButton
        className={iconClassName}
        style={iconStyle}
        iconProps={{ iconName: iconName }}
      />
    </Dropdown>
  );
};
