import { apiFetch, HTTPMethod } from 'integrations/crossborderit/fetch';
import { InitiatePayoutResult } from '../collectionInvoices';
import { PayoutReport, Payout } from './interfaces';

export const getPayoutReports = ():Promise<PayoutReport[]> => apiFetch('/payouts');

export const getPayoutReport = (payoutReportId: string):Promise<PayoutReport> => apiFetch(`/payouts/${payoutReportId}`);

export const getPayoutsForReport = (payoutReportId: string):Promise<Payout[]> => apiFetch(`/payouts/${payoutReportId}/payoutList`);

export const createPayoutReport = (payoutReportModel: PayoutReport):Promise<PayoutReport> => apiFetch('/payouts', payoutReportModel, HTTPMethod.POST);

export const addStatementPayoutsToReport = (payoutReportId: string, statementsIds: string[]):Promise<InitiatePayoutResult> => apiFetch(`/payouts/${payoutReportId}/addPayouts`, statementsIds, HTTPMethod.POST);

export const removePayoutFromReport = (payoutReportId: string, payoutId: string) => apiFetch(`/payouts/${payoutReportId}/payout/${payoutId}/removePayout`, undefined, HTTPMethod.DELETE);

export const removePayoutReport = (payoutReportId: string) => apiFetch(`/payouts/${payoutReportId}/removePayoutReport`, undefined, HTTPMethod.DELETE);

export const sendPayoutReport = (payoutReportId: string):Promise<InitiatePayoutResult> => apiFetch(`/payouts/${payoutReportId}/sendPayoutReport`, undefined, HTTPMethod.PUT);