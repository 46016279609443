import { CrmContact } from '..';
import { CompanyCategory } from '../companies';
import { CompanyAccountManager } from '../companyAccountManagers/interfaces';

export interface CrmCompany {
  id: string;
  companyName?: string;
  email?: string;
  phone?: string;
  website?: string;
  linkedIn?: string;
  AdressLine1?: string;
  AdressLine2?: string;
  countryCode?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  crmCompanyType: CrmCompanyType;
  crmCompanyCategories?: CrmDigitalCustomerCategory[] | CrmAccountCategory[] ;
  companyCategories?: CompanyCategory[];
  crmCompanyStatus: CrmCompanyStatus;
  revokedUtc?:string;
  revokedBy?:string;
  addedUtc: string;
  addedBy: string;
  crmContacts?: CrmContact[];
  companyAccountManagers?: CompanyAccountManager[];
}

export enum CrmCompanyStatus {
  Prospect = 0,
  Lead = 1,
  Invited = 2,
  Negotiating = 3,
  Company = 4,
}

export enum CrmCompanyType {
  NotSelected = 0,
  DigitalCustomer = 1,
  DirectAccount = 2,
  Partner = 3,
}

export enum CrmDigitalCustomerCategory {
  Shopify = 'Shopify',
  Magento = 'Magento',
  WooCommerce = 'WooCommerce',
  PrestaShop = 'PrestaShop',
  API = 'Api',
  VentureCapital = 'VentureCapital',
  Partner = 'Partner',
  Investor = 'Investor',
  Other = 'Other',
}

export enum CrmAccountCategory {
  Reseller = 'Reseller',
  Platform = 'Platform',
  WebShop = 'WebShop',
  Broker = 'Broker',
  Carrier = 'Carrier',
  Postal = 'Postal',
}
