import { FunctionComponent, CSSProperties, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useCountries } from 'integrations/crossborderit';
import { AsyncStatus } from 'store';
import { Switch, Case } from 'components/shared/switch';
import { useTranslation } from 'react-i18next';

interface Props {
  name?: (string | number)[];
  fieldKey?: (string | number)[];
  label?: string;
  required?: boolean;
  allowClear?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  noStyle?: boolean;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  valueType?: string;
}

enum ComponentStatus {
  Error = 'Error',
  Loading = 'Loading',
  Idle = 'Idle',
}

export const CountryInput: FunctionComponent<Props> = ({
  name = ['countryCode'],
  fieldKey = ['countryCode'],
  valueType = 'alpha2Code',
  required = true,
  allowClear = false,
  disabled = false,
  style = {},
  placeholder,
  noStyle,
  label,
  mode,
  ...props
}) => {
  const { t } = useTranslation();
  const { countries, status: countriesStatus } = useCountries();
  const [componentStatus, setComponentStatus] = useState<ComponentStatus>(ComponentStatus.Idle);

  useEffect(() => {
    if (countriesStatus === AsyncStatus.Loading) {
      setComponentStatus(ComponentStatus.Loading);
    } else if (countriesStatus === AsyncStatus.Error) {
      setComponentStatus(ComponentStatus.Error);
    } else if (countriesStatus === AsyncStatus.Success) {
      setComponentStatus(ComponentStatus.Idle);
    }
  }, [countriesStatus]);

  return (
    <Switch on={componentStatus}>
      <Case match={[ComponentStatus.Error]}>
        <Form.Item
          {...props}
          noStyle={noStyle}
          name={name}
          fieldKey={fieldKey}
          label={label}
          rules={[{ required, message: 'Countries could not be loaded please try again in a moment.' }]}
        >
          <Select
            disabled
            placeholder={t('Could not load countries.')}
          />
        </Form.Item>
      </Case>
      <Case match={[ComponentStatus.Idle, ComponentStatus.Loading]}>
        <Form.Item
          {...props}
          noStyle={noStyle}
          name={name}
          fieldKey={fieldKey}
          label={label}
          rules={[{ required, message: 'Please enter a Country' }]}
        >
          <Select
            mode={mode}
            loading={componentStatus === ComponentStatus.Loading}
            showSearch
            allowClear
            disabled={disabled}
            optionFilterProp="label"
            filterOption={true}
            placeholder={placeholder}
            style={style}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.alpha2Code}
                value={country.alpha2Code}
                label={country.name}
              >
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Case>
    </Switch>
  );
};
