import { FunctionComponent, CSSProperties } from 'react';

export interface LayoutHeaderProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: CSSProperties;
}

export const LayoutHeader: FunctionComponent<LayoutHeaderProps> = ({
  as: Element = 'div',
  style = {},
  ...props
}) => <Element style={{ ...style, gridArea: 'header' }} {...props} />;
