import { FunctionComponent, ReactNode } from 'react';
import { SharedColors } from '@fluentui/react';

export interface StatusProps {
  text?: ReactNode;
  color: string;
}

export const StatusColor: { [name: string]: string } = {
  NoStatus: 'hsl(0, 0%, 80%)',
  Processing: SharedColors.cyanBlue10,
  Warning: SharedColors.orange10,
  Success: SharedColors.greenCyan10,
  Error: SharedColors.red10,
  Restricted: '#555555',
};

export const Status: FunctionComponent<StatusProps> = ({ color, text }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: !text ? 'center' : 'initial', paddingTop: !text ? '6px' : 'none' }}>
    <div
      style={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: StatusColor[color] || color,
        marginRight: '8px',
        marginBottom: '-2px',
      }}
    />
    {text}
  </div>
);
